import { useEffect, useRef, useState, useMemo } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { t } from "i18next"
import { priceLineID } from "../config"
import { PriceFormat, TranslateJSON, ShowLoader, HideLoader, storingCartData } from "../functions"
import { getRequestOptions, cartHeaderInclude } from "../helpers/apiHelper"
import { prepareProductToCart } from "../helpers/functionsRedux"
import { setCartHeaders, clearCartContent } from '../reduxToolkit/cartSlice'
import { setIsMore5CartsAlert, setCartId } from '../reduxToolkit/universalSlice'
import { columnCartDefs } from '../components/shoppingCart/columnCartDefs'
import { Breadcrumbs } from "../breadcrumbs"
import { TablePreloader } from "../components/TablePreloader"
import { ProdHistoryPopup } from "../components/ProdHistoryPopup"
import { ModalDeleteCart } from "../components/ModalDeleteCart"
import { PopupWithInput } from "../components/PopupWithInput"

import { ReactComponent as MoreVertical } from './../assets/svg/morevertical.svg'

import "./../assets/css/cart.css"

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

import { AG_GRID_LOCALE } from "../aggridtranslations/locale.full"

import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey("CompanyName=SmartDigit, Lda,LicensedApplication=NIKI24,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-030570,SupportServicesEnd=27_August_2023_[v2]_MTY5MzA5MDgwMDAwMA==197101bde71f7a31a7dac0b3d3f0a6c8")

export const ShoppingCart = () => {
    const { id } = useParams()
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const cartId = useSelector(state => state.universalContent.cartId)

    const gridRef = useRef(null)
    const moreMenuEl = useRef(null)
    const moreMenuOverlayEl = useRef(null)
    const cartRenameWrapperEl = useRef(null)
    const cartPopupEl = useRef(null)
    const refEnterCartName = useRef(null)

    const uuid = id
    const [rowData, setRowData] = useState([])
    const [orderIDReal, setOrderIDReal] = useState('')
    const [createdAt, setCreatedAt] = useState('')
    const [dataLoaded, setDataLoaded] = useState(false)
    const [cartID, setCartID] = useState('')
    const [cartInfo, setCartInfo] = useState({cartSumm: 0, cartSummVat: 0, cartSummOld: 0, cartNumProducts: 0})
    const [cartName, setCartName] = useState('')
    const [newCartName, setNewCartName] = useState('')

    // const groupRowRendererParams = useMemo(() => {
    //     return {
    //       innerRenderer: CustomGroupRow,
    //       suppressCount: true,
    //     };
    //   }, []);

    /* HistoryProduct */
    const [openHistoryProduct, setOpenHistoryProduct] = useState(false)
    const [idHistoryProduct, setIdHistoryProduct] = useState('')
    const [productData, setProductData] = useState({})

    useEffect(() => {
        if (openHistoryProduct) {
            const PopupSelector = `.product_history__wrapper_${idHistoryProduct}`
            document.querySelector(PopupSelector).classList.add('active')
        }      
    }, [openHistoryProduct, idHistoryProduct])

    const showProdHistory = e => {
        setOpenHistoryProduct(true)
        setIdHistoryProduct(e.target.dataset.id)
        setProductData(JSON.parse(e.target.dataset.data))
    }
    /* HistoryProduct */

    useEffect(()=>{
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-header/${uuid}?include=${cartHeaderInclude.join(',')}&realKey=1&priceline_external_id=${priceLineID}&warehouse_external_id=${localStorage.getItem('warehouse.id')}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data.id_real && setOrderIDReal(json.data.id_real)
            json.data.readable_created_at && setCreatedAt(json.data.readable_created_at)
            json.data.id && setCartID(json.data.id)
            json.data.matchcode && setCartName(json.data.matchcode)
            if (json.data.cartDetails.data) {
                const RowData = []
                let cartNumProducts = 0
                let cartSumm = 0
                let cartSummOld = 0
                let cartSummVat = 0
                json.data.cartDetails.data.forEach(e => {  
                    const retProduct = prepareProductToCart(e, '', true)    
                    cartNumProducts++
                    cartSumm += parseFloat(retProduct.total_net)
                    cartSummOld += (retProduct.priceold > 0 ? retProduct.priceold : retProduct.price) * retProduct.qty
                    cartSummVat += retProduct.qty * retProduct.vat
                    
                    const readyProduct = {
                        ...retProduct,
                        vat: Math.round(retProduct.vat / retProduct.price * 100),
                    }
                    RowData.push(readyProduct)
                })
                setRowData(RowData)
                setCartInfo({cartSumm, cartSummVat, cartSummOld, cartNumProducts})
                setDataLoaded(true) 
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    },[uuid])

    const gridStyles = {
        color: 'var(--darkgrey)',
        fontSize: '16px'
    }
    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        }
    }, [])

    // const [gridApi, setGridApi] = useState()
    // const onGridReady = useCallback(params => {
    //     const { api, columnApi } = params
    //     setGridApi({ api, columnApi })
    // })

    const localeText = useMemo(() => {
        return TranslateJSON(AG_GRID_LOCALE)
    }, [])

    const ShowMoreMenu = () => {
        moreMenuEl.current.classList.add('active')
        moreMenuOverlayEl.current.classList.add('active')
    }
    const CloseMoreMenu = () =>{
        moreMenuEl.current.classList.remove('active')
        moreMenuOverlayEl.current.classList.remove('active')
    }
    const ShowRenamePopup = () => {
        CloseMoreMenu()
        cartRenameWrapperEl.current.classList.add('active')
    }
    const CloseRenamePopup = () => {
        cartRenameWrapperEl.current.classList.remove('active')
    }
    const dispatchCartHeaders = (cartH) => {
        dispatch(setCartHeaders({
            id: cartH.id,
            idreal: cartH.id_real,
            name: cartH.matchcode || '',
            readable_created_at: cartH.readable_created_at,
        }))
    }

    const CreateNewCart = (e) => {
        e.preventDefault()
        const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
        requestOptionsCart.body = JSON.stringify({
            company_id: localStorage.getItem('account.company.hashed'),
            customer_account_id: localStorage.getItem('account.entity.id'),
            status_id: 'J8RvMwowm7k2pNOr',
            realKey: '1',
            matchcode: newCartName,
        })

        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers`,requestOptionsCart)
        .then(response => response.json())
            .then(json => {
                if (json.message && json.errors) {
                    closePopupEnterCartName()
                    dispatch(setIsMore5CartsAlert(true))
                }
                if (json.data) {
                    dispatch(clearCartContent())
                    setCartName(newCartName)
                    setOrderIDReal(json.data.id_real)
                    setCreatedAt(json.data.readable_created_at)
                    setRowData([])
                    localStorage.setItem('create_new_cart', JSON.stringify(json.data))
                    storingCartData(json.data.uuid, json.data.id_real, json.data.id)
                    dispatchCartHeaders(json.data)
                    closePopupEnterCartName()
                    setNewCartName('')
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }

    const DeleteCart = () => cartPopupEl.current.classList.add('active')
    const ClosePopup = () => cartPopupEl?.current?.classList?.remove('active')

    const [newName,setNewName] = useState('')
    const SubmitNewName = e => {
        e.preventDefault()
        const requestOptionsPatchMatchcode = getRequestOptions('PATCH', null, 'application/json')
        requestOptionsPatchMatchcode.body = JSON.stringify({ matchcode: newName })
    
        try {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-header/${uuid}`,requestOptionsPatchMatchcode)
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    dispatchCartHeaders(json.data)
                    setCartName(json.data.matchcode)
                    CloseRenamePopup()
                }
            })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    }

    const OpenCart = () => {
        localStorage.getItem('cart.id')
        if (cartId !== localStorage.getItem('cart.id')) {
            dispatch(setCartId(localStorage.getItem('cart.id')))
        }
        navigate('/myshoppingcart/')
    }

    const openPopupEnterCartName = () => {
        refEnterCartName.current.classList.add('active')
        CloseMoreMenu()
    }
    const closePopupEnterCartName = () => refEnterCartName.current.classList.remove('active')

    return (
        <>
            <Breadcrumbs itemlist={[
                {'url':'/account/','title':t('niki.b2b.menu.myaccount')},
                {'url':'/shoppingcarts/','title':t('niki.b2b.cart.shoppingcarts')},
                {'url':`/shoppingcart/${uuid}/`,'title':`${t('niki.b2b.cart.shoppingcart')} ${orderIDReal}`}
            ]} />
            <div className="wrapper">
                <h1>
                    {cartName ? `${orderIDReal} — ${cartName}` : `${t('niki.b2b.cart.shoppingcart')} ${orderIDReal}`}
                    <span className="more_button">
                        <button onClick={ShowMoreMenu}><MoreVertical /></button>
                        <div className="more_menu" ref={moreMenuEl}>
                            <button onClick={openPopupEnterCartName}>{t('niki.b2b.cartflow.createnewcart')}</button>
                            <button onClick={ShowRenamePopup}>{t('niki.b2b.cartflow.rename')}</button>
                            <button onClick={DeleteCart}>{t('niki.b2b.shoppingcart.deletecart')}</button>
                        </div>
                    </span>
                </h1>
                <div className="myshoppingcart__created">{t('niki.b2b.cart.created')}: <span>{createdAt}</span></div>

                <div className="cart_columns">          
                    <div className="cart_content">
                        {openHistoryProduct &&
                            <ProdHistoryPopup
                                matchcode={productData.name}
                                unit_code={productData.unitcode}
                                qty_in_box={productData.qtyinbox} 
                                item_id={productData.itemid}
                                imgsrc={productData.image}
                                setOpenHistoryProduct={setOpenHistoryProduct}
                                product_id={productData.productid}
                            />
                        }

                        {dataLoaded
                            ? <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnCartDefs(dispatch, t, showProdHistory)}
                                    defaultColDef={defaultColDef}
                                    // onGridReady={onGridReady}
                                    rowHeight="76"
                                    rowStyle={gridStyles}
                                    rowGroup={'true'}
                                    animateRows={true}
                                    singleClickEdit={true}
                                    groupDisplayType={'groupRows'}
                                    groupRowRenderer={'agGroupCellRenderer'}
                                    rowGroupPanelShow={'always'}
                                    localeText={localeText}
                                    suppressDragLeaveHidesColumns={true}
                                    // groupRowRendererParams={groupRowRendererParams}
                                >
                                </AgGridReact>
                            </div>
                            : <TablePreloader />
                        }
                    </div>
                    
                    <div className="cart_aside">
                        <div className="cart_aside__inner">
                            <div className="cart_aside__header">{t('niki.b2b.shoppingcart.summary')}</div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalskus')}</div>
                                <div>{cartInfo.cartNumProducts}</div>
                            </div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalnet')}</div>
                                <div>{PriceFormat(cartInfo.cartSumm)}</div>
                            </div>
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalvat')}</div>
                                <div>{PriceFormat(cartInfo.cartSummVat)}</div>
                            </div>
                            {cartInfo.cartSumm !== cartInfo.cartSummOld
                            &&
                            <>
                                <hr />
                                <div className="cart_aside__table red">
                                    <div>{t('niki.b2b.shoppingcart.totalsavings')}</div>
                                    <div>{PriceFormat(cartInfo.cartSummOld-cartInfo.cartSumm)}</div>
                                </div>
                            </>
                            }
                            <hr />
                            <div className="cart_aside__table">
                                <div>{t('niki.b2b.orders.totalgros')}</div>
                                <div className="cart_aside__table__big_price">{PriceFormat(cartInfo.cartSumm+cartInfo.cartSummVat)}</div>
                            </div>
                            <button className="btn btn-block btn-primary checkout_now" onClick={OpenCart}>{t('niki.b2b.edit_this_cart')}</button>
                        </div>
                    </div>
                
                </div>
            </div>
            <div className="more_menu__overlay" onClick={CloseMoreMenu} ref={moreMenuOverlayEl}></div>
            <PopupWithInput refWrapper={cartRenameWrapperEl} value={cartName} onSubmit={SubmitNewName} setNewValue={setNewName}
                close={CloseRenamePopup} title='niki.b2b.cartflow.rename' titleSubmitButton='niki.b2b.cartflow.renamecart'
            />
            <PopupWithInput refWrapper={refEnterCartName} onSubmit={CreateNewCart} setNewValue={setNewCartName}
                close={closePopupEnterCartName} title='niki.b2b.cart.enter_cart_name' titleSubmitButton='niki.b2b.cart.create'
            />
            <ModalDeleteCart refEl={cartPopupEl} ClosePopup={ClosePopup} cartIDReal={orderIDReal} skus={rowData.length} gros={cartInfo.cartSumm+cartInfo.cartSummVat}
                cartID={cartID}
            />
        </>
    )
}