import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetGlobalBannerData, HideLoader, ShowLoader, onBannerView } from './functions'
import { setBrandName } from "./reduxToolkit/allproduct"

import { t } from "i18next"

import './assets/css/banner.css'

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

const now = new Date()
const todayIs = `${String(now.getDate()).padStart(2, '0')}.${String(now.getMonth() + 1).padStart(2, '0')}.${now.getFullYear()}`

let showBanner = true

if (localStorage.getItem('banner200.closed') && localStorage.getItem('banner200.closed') === 'true') {
    showBanner = false
} else {
    localStorage.setItem('banner200.closed','false')
}

if (localStorage.getItem('banner200.closed.when')) {
    if (localStorage.getItem('banner200.closed.when') === todayIs) {
        showBanner = false
        localStorage.setItem('banner200.closed','true')
    } else {
        localStorage.setItem('banner200.closed','false')
        showBanner = true
    }
}

export const Banner200 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const bannersData = useSelector(state => state.universalContent.bannersData)

    const [topBanners, setTopBanners] = useState([])
   
    useEffect(() => {
        ShowLoader()
        const data = bannersData || JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            // e.matchcode === 'B2B - All products top banner'
            e.content_subtype_id === "k9peXdnvO7DQ0m5y" && e.content_type_id === "k9peXdnvO7DQ0m5y" && setTopBanners(GetGlobalBannerData(e))
        })
        HideLoader()
    }, [bannersData])
    
    const onBannerClick = (banner) => {
        dispatch(setBrandName(banner?.link?.slice(banner?.link?.indexOf('=') + 1)?.replace('%20', ' ')))
        onBannerView(banner, navigate)
    }

    const CloseBanner200 = () => {
        localStorage.setItem('banner200.closed','true')
        localStorage.setItem('banner200.closed.when',todayIs)
        document.querySelector('.banner200_wrapper').style.display = 'none'
        document.body.classList.add('banner200closed')
    }
 
    if (showBanner) {
        return (
            <div className="banner200_root">
                <div className="banner200_wrapper">
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        swipeable={true}
                    >
                        {topBanners.length > 0 && topBanners.map((e, i) => <div key={`topbanner200${i}`} onClick={() => onBannerClick(e)} className="banner200_slide">
                            <img src={e.image} alt={e.title} />
                        </div>)
                        }
                    </Carousel>
                    <button className="banner200__close_add" onClick={CloseBanner200}> {t('niki.b2b.banner.closead')}</button>
                </div>
            </div>
        )
    } else {
        return null
    }
}