import { useEffect, useState } from "react"
import { NavLink, useNavigate } from 'react-router-dom'
import { t } from "i18next"
import { useSelector } from "react-redux"

import { GetGlobalBannerData, ShowLoader, HideLoader, GetSimpleBannerData, onBannerView } from "../functions"
import LoadableImage from "../components/LoadableImage/LoadableImage"

import "./../assets/css/staticpages.css"

import { ReactComponent as MarketingVideos } from './../assets/svg/marketingvideos.svg'
import { ReactComponent as MarketingProductCatalogs } from './../assets/svg/marketingproductcatalogs.svg'
import { ReactComponent as MarketingOurBrands } from './../assets/svg/marketingourbrands.svg'
import { ReactComponent as MarketingPromotionalBooklets } from './../assets/svg/marketingpromotionalbooklets.svg'

export const Marketing = () => {
    
    const navigate = useNavigate()
    const bannersData = useSelector(state => state.universalContent.bannersData)

    const [pageImage, setPageImage] = useState({ simple: '', mime: '', webp: '' })
    const [bannersMiddle, setBannersMiddle] = useState([])

    useEffect(() => {
        ShowLoader()
        const data = bannersData || JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            // e.matchcode === 'B2B - Marketing - Image'
            if ((e.content_subtype_id === 'J8RvMwowm7k2pNOr' || e.content_subtype_id === null) && e.content_type_id === "21P0praYMaJGEYLx" && e?.attachments?.data[0]?.links) {
                e.attachments.data[0].links.forEach(el => {
                    if (el.template === 'original') {
                        setPageImage(GetSimpleBannerData(el))
                    }
                })
            }
            // e.matchcode === 'B2B - Marketing - Banners'
            e.content_subtype_id === 'gOkj31nzQol9e5EQ' && e.content_type_id === "21P0praYMaJGEYLx" && setBannersMiddle(GetGlobalBannerData(e))
        })
        HideLoader()
    }, [bannersData])

    return (
        <div className="wrapper">
            <h1>{t('niki.b2b.menu.marketing')}</h1>
            <div className="marketing_two_columns">
                <div>
                    {/* <picture className="marketing_intro_image">
                        <source type="image/webp" srcSet={pageImage.webp} />
                        <source type={pageImage.mime} srcSet={pageImage.simple} />
                        <img src={pageImage.simple} alt={t('niki.b2b.menu.marketing')} />
                    </picture> */}
                    <LoadableImage src={pageImage.webp ? pageImage.webp : pageImage.simple} alt={t('niki.b2b.menu.marketing')} height={'410px'}/>
                </div>                
                <div className="marketing__buttons">
                    <NavLink to="/promotional-booklets/" className="marketing__button_booklets"><MarketingPromotionalBooklets />{t('niki.b2b.marketing.booklets')}</NavLink>
                    <NavLink to="/product-catalogs/" className="marketing__button"><MarketingProductCatalogs />{t('niki.b2b.marketing.catalogs')}</NavLink>
                    <NavLink to="/brands/" className="marketing__button"><MarketingOurBrands />{t('niki.b2b.marketing.brands')}</NavLink>
                    <NavLink to="/videos/" className="marketing__button"><MarketingVideos />{t('niki.b2b.marketing.videos')}</NavLink>
                </div>
            </div>
            <h2 className="center marketing__discover_whats_hot">{t('niki.b2b.marketing.discover')}</h2>
            <div className="marketing_four_banners">
                {bannersMiddle.map((e, i) => <div key={`middlebanner${i}`} onClick={() => onBannerView(e, navigate)}>
                    {/* <img src={e.image} alt={e.title} /> */}
                    <LoadableImage src={e.image} alt={e.title} height={'310px'}/>
                </div>)}
            </div>
        </div>
    )
}