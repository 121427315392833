import { useEffect, useState, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'

import { setIsShowAllShoppingCartsModal, setIsMore5CartsAlert, setIsAccountBlockedModal, setIsOpenAccountPopup, setIsOpenProductNotFoundAlert, setIsOpenNoCartAlert, setIsOpenCreateNewCartModal, setIsNotImpossiblePrint, setIsInfoModalOpen, setIsChangeAccount } from '../../reduxToolkit/universalSlice'

import { QuickOrder } from '../quickOrder'
import { ChangeEntityAccount } from '../ChangeEntityAccount'
import { AlertPopup } from '../AlertPopup'
import { CartPreviewModal } from './CartPreviewModal'
import { ShowAllShoppingCartsModal } from './ShowAllShoppingCartsModal'
import { AccountPopup } from './AccountPopup'
import { CreateNewCartModal } from "../CreateNewCartModal"
import { ModalProductPage } from '../ModalProductPage'

import '../../assets/css/header.css'

export const HeaderModals = memo(({ isGetCart, isOpenCartPreviewModal, setIsOpenCartPreviewModal }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isQuickOrder, setIsQuickOrder] = useState(false)

    const isShowAllShoppingCartsModal = useSelector(state => state.universalContent.isShowAllShoppingCartsModal)
    const isMore5CartsAlert = useSelector(state => state.universalContent.isMore5CartsAlert)
    const isAccountBlockedModal = useSelector(state => state.universalContent.isAccountBlockedModal)
    const isOpenAccountPopup = useSelector(state => state.universalContent.isOpenAccountPopup)
    const isOpenProductNotFoundAlert = useSelector(state => state.universalContent.isOpenProductNotFoundAlert)
    const isOpenNoCartAlert = useSelector(state => state.universalContent.isOpenNoCartAlert)
    const isOpenCreateNewCartModal = useSelector(state => state.universalContent.isOpenCreateNewCartModal)
    const isNotImpossiblePrint = useSelector(state => state.universalContent.isNotImpossiblePrint)
    const isInfoModalOpen = useSelector(state => state.universalContent.isInfoModalOpen)
    const contentInfoModal = useSelector(state => state.universalContent.contentInfoModal)
    const isChangeAccount = useSelector(state => state.universalContent.isChangeAccount)
    const detailsProductId = useSelector(state => state.universalContent.detailsProductId)

    const ShowAllShoppingCarts = () => dispatch(setIsShowAllShoppingCartsModal(true))
    const ShowQuickOrder = () => setIsQuickOrder(true)

    useEffect(() => {
        let timer;

        if (isQuickOrder) {
            clearTimeout(timer);
            timer = setTimeout(() => document.querySelector('.quick_order__wrapper').classList.add('active'), 300);
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isQuickOrder]);

    // const cartProducts = useSelector(state => state.cartContent.cartContent) || []

    const goToContacts = () => {
        navigate('/contact/')
        dispatch(setIsAccountBlockedModal(false))
    }

    const onSelectCart = () => {
        dispatch(setIsShowAllShoppingCartsModal(true))
        dispatch(setIsOpenNoCartAlert(false))
    }

    const onCreateNewCart = () => {
        dispatch(setIsOpenNoCartAlert(false))
        dispatch(setIsOpenCreateNewCartModal(true))
    }

    return (
        <>
            {isChangeAccount && <ChangeEntityAccount closeModal={() => {dispatch(setIsChangeAccount(false))}} />}
            {isQuickOrder && <QuickOrder setQuickOrder={setIsQuickOrder} />}
            {isShowAllShoppingCartsModal && <ShowAllShoppingCartsModal />}
            {isOpenAccountPopup && <AccountPopup closePopup={() => dispatch(setIsOpenAccountPopup(false))} />}
            {isGetCart && isOpenCartPreviewModal &&
                <CartPreviewModal
                    hideCartPopup={() => setIsOpenCartPreviewModal(false)}
                    showQuickOrder={ShowQuickOrder}
                    showAllShoppingCarts={ShowAllShoppingCarts}
                />
            }
            {isOpenProductNotFoundAlert && <AlertPopup text={t('niki.b2b.error_adding_product_cart')} closeAlert={() => dispatch(setIsOpenProductNotFoundAlert(false))} />}
            {isMore5CartsAlert && <AlertPopup text={t('niki.b2b.number_max_of_carts_error')} closeAlert={() => dispatch(setIsMore5CartsAlert(false))} />}
            {isAccountBlockedModal &&
                <AlertPopup text={t('niki.b2b.entity_account_blocked')} towButtons={true} closeAlert={() => dispatch(setIsAccountBlockedModal(false))}
                    titleFirstButton={'niki.b2b.menu.contact'} onFirstButtonClick={goToContacts}
                />
            }
            {isOpenCreateNewCartModal && <CreateNewCartModal close={() => dispatch(setIsOpenCreateNewCartModal(false))} />}
            {isOpenNoCartAlert &&
                <AlertPopup text={t('niki.b2b.cart_inactive')} titleFirstButton={'niki.b2b.select_cart_from_exist'} titleSecondButton={'niki.b2b.cartflow.createnewcart'}
                    closeAlert={() => dispatch(setIsOpenNoCartAlert(false))} onFirstButtonClick={onSelectCart} onSecondButtonClick={onCreateNewCart} towButtons={true}
                />
            }
            {isNotImpossiblePrint && <AlertPopup text={t('niki.b2b.debts.error_print')} closeAlert={() => dispatch(setIsNotImpossiblePrint(false))} />}
            {isInfoModalOpen && <AlertPopup text={contentInfoModal} closeAlert={() => dispatch(setIsInfoModalOpen(false))} />}
            {detailsProductId && <ModalProductPage productID={detailsProductId}/>}
        </>
    )
})