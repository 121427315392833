import { useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'

import { priceLineID } from '../../config'
import { getRequestOptions, returnErrorDuringAddProductToCart, paramsForCartDetails } from "../../helpers/apiHelper"
import { prepareProductToCart } from '../../helpers/functionsRedux'
import { deleteCartProduct, updateCartProduct, setDeleteProductID } from '../../reduxToolkit/cartSlice'
import { updateProduct } from '../../reduxToolkit/productsSlice'
import { PriceFormat, CapacityString, LangFix, ShowLoader, HideLoader, checkValueOrNonInteger } from '../../functions'
import { setDetailsProductId } from '../../reduxToolkit/universalSlice'

import defaultImage from '../../assets/images/placeholder.jpg'
import { ReactComponent as PutToCart } from '../../assets/svg/puttocart.svg'
import { ReactComponent as Trash } from '../../assets/svg/trash.svg'
import '../../assets/css/header.css'

export const SearchResultsHit = memo(({ hit }) => {

    const dispatch = useDispatch()

    const [cartButtonStatus, setCartButtonStatus] = useState('normal')

    const cartDetailsIDs = useSelector(state => state.cartContent.cartDetailsIDs)
    const productQTYs = useSelector(state => state.cartContent.productQTYs)
    const productIDs = useSelector(state => state.cartContent.productIDs)
    const detailsProductId = useSelector(state => state.universalContent.detailsProductId)

    const selectedLanguage = LangFix(localStorage.getItem('locale'))
    const prodID = hit.id
    const imgSrc = hit.attachment || (hit.attachments?.length && hit.attachments[0])|| defaultImage
    const prodName = hit.itemDescriptions && hit.itemDescriptions[selectedLanguage]?.description ? hit.itemDescriptions[selectedLanguage]?.description : hit.matchcode
    const available = parseInt(hit.itemStocks[0]?.available) || 0
    const prodKind = (hit.kinds && hit.kinds[0] && hit.kinds[0][selectedLanguage]) || ''
    const prodQtyinBox = parseInt(hit.itemUnitBarcodeByUnitCodeBox?.coefficient) || 1
    const prodUni = hit.main_unit_code_translations ? hit.main_unit_code_translations[selectedLanguage] : hit.sale_unit_code || ''
    const saleUnitCode = hit.sale_unit_code
    const cartDetail = cartDetailsIDs[prodID]
    let addClass = 'not_in_stock'
    if (available > 0) addClass = 'low'
    if (available > 20) addClass = 'in_stock'
    let prodPriceOld = 0
    let prodPrice = (hit.itemPrices && hit.itemPrices[priceLineID] && hit.itemPrices[priceLineID].unit_price && parseFloat(hit.itemPrices[priceLineID].unit_price)) || 0
    let prodGross = (hit.itemPrices && hit.itemPrices[priceLineID] && hit.itemPrices[priceLineID].tax_included_price && parseFloat(hit.itemPrices[priceLineID].tax_included_price)) || 0
    if (hit.itemDiscounts && hit.itemDiscounts[priceLineID]) {
        prodPriceOld = prodPrice
        prodPrice = hit.itemDiscounts[priceLineID].unit_price
        prodGross = hit.itemDiscounts[priceLineID].tax_included_price
    }

    const startLoading = () => {
        setCartButtonStatus('waiting')
        ShowLoader()
    }
    const finallyRequest = () => {
        HideLoader()
        setCartButtonStatus('normal')
    }

    const RemoveCartItem = (productID, cartDetail) => {
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${cartDetail}`, getRequestOptions('DELETE'))
            .then(response => {
                if (response.ok) {
                    dispatch(setDeleteProductID(cartDetail))
                    dispatch(deleteCartProduct(cartDetail))
                    dispatch(updateProduct({ id: productID, qty: 0 }))
                }
                return 'end'
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }

    const CartButtonClick = e => {
        startLoading()
        const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
        requestOptionsCart.body = JSON.stringify({
            cart_header_uuid: localStorage.getItem('cart.uuid'),
            item_id: prodID,
            matchcode: prodName,
            variation_id: '3',
            qty_in_box: prodQtyinBox.toString(),
            qty: prodQtyinBox.toString(),
            unit_price: prodPrice.toString(),
            tax_included_price: prodGross.toString()
        })

        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details?${paramsForCartDetails}`, requestOptionsCart)
            .then(response => response.json())
            .then(json => {
                json.data && prepareProductToCart(json.data, dispatch)
                setCartButtonStatus('normal')
                setNewValue(json.data.qty)
            })
            .catch(error => returnErrorDuringAddProductToCart(error, dispatch))
            .finally(() => finallyRequest())
    }
    const UpdateCartValue = (value, cartDetail) => {
        const localValue = typeof value === "string" ? parseFloat(value) : value
        if (localValue === 0) {
            RemoveCartItem(cartDetail)
            return
        }

        startLoading()
        const requestOptions = getRequestOptions('POST', null, 'application/json')
        requestOptions.body = JSON.stringify({ qty: localValue })

        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${cartDetail}`, requestOptions)
            .then(response => {
                dispatch(updateCartProduct({ productid: prodID, cartdetail: cartDetail, qty: localValue }))
                dispatch(updateProduct({ id: prodID, qty: localValue }))
                setNewValue(localValue)
                return response.json()
            })
            .catch(error => returnErrorDuringAddProductToCart(error, dispatch))
            .finally(() => finallyRequest())
    }
    const CartMinus = () => {
        const difference = parseInt(productQTYs[prodID]) < 2 * prodQtyinBox ? prodQtyinBox : productQTYs[prodID] - prodQtyinBox
        const newValue = Number.isInteger(difference) ? difference : difference.toFixed(2)
        UpdateCartValue(newValue, cartDetail)
    }
    const CartPlus = () => {
        const resultSum = productQTYs[prodID] + prodQtyinBox
        const newValue = Number.isInteger(resultSum) ? resultSum : resultSum.toFixed(2)
        UpdateCartValue(newValue, cartDetail)
    }
    const CartRemove = () => {
        startLoading()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${cartDetail}`, getRequestOptions('DELETE'))
            .then(response => {
                response.ok && dispatch(deleteCartProduct(cartDetail))
            })
            .catch(error => returnErrorDuringAddProductToCart(error, dispatch))
            .finally(() => finallyRequest())
    }

    const [isChangedInput, setChangedInput] = useState(false)
    const [newValue, setNewValue] = useState(productQTYs[prodID])

    const CartChange = (e, saleUnitCode) => {
        const value = e.target.value
        typeof value === "string" && !value && setNewValue('')

        const inputValue = checkValueOrNonInteger(value, saleUnitCode)
        if (inputValue === '') return
        setNewValue(inputValue)
        setChangedInput(true)
    }

    const delayedUpdate = () => {
        UpdateCartValue(newValue, cartDetail)
        setChangedInput(false)
    }

    useEffect(() => {
        let timer;
        if (isChangedInput) {
            if (newValue) {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    delayedUpdate()
                }, 1150);
            }
            else {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    startLoading()
                    const res = RemoveCartItem(newValue, cartDetail)
                    res === 'end' && finallyRequest()
                }, 2650);
            }
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [newValue]);

    useEffect(() => {
        const originalStyle = document.querySelector(`.search_from_main`)        
        
        if (detailsProductId) {
            originalStyle.style.display = 'none'
        } else {
            originalStyle.style.display = 'block'
        }
    }, [detailsProductId])

    const [productImgIsValid, setProductImgIsValid] = useState(true);

    const onClickGoToProduct = () => {
        dispatch(setDetailsProductId(prodID))
    }

    return (
        <div className="search_form__results_hit" key={`product${prodID}`} {...hit}>
            <div>
                <div className="search_form__results_hit__img_wrapper pointer" onClick={onClickGoToProduct}>
                    {productImgIsValid
                        ? <img src={imgSrc} alt={prodName} onError={() => setProductImgIsValid(false)} />
                        : <img src={defaultImage} alt={prodName} />
                    }
                </div>
            </div>
            <div>
                <div className="search_form__results_hit__link pointer" onClick={onClickGoToProduct}>{prodName}</div>
                <div className="search_form__results_hit__info">
                    <div className="search_form__results_hit__info_unit">{t('niki.b2b.cart.ref')}: {hit.source_item_id}</div>
                    <div className="search_form__results_hit__info_unit">{t('niki.b2b.cart.unit')}: {prodQtyinBox} {prodUni}</div>
                    <div className="search_form__results_hit__info_unit"><span className={[`search_form__results_hit__info_unit_instock`, addClass].join(' ')}></span></div>
                    <div className="search_form__results_hit__info_unit">{CapacityString(hit.capacity, hit.capacity_unit_code, prodPrice)}</div>
                </div>
            </div>
            <div>
                <div className="search_form__results_hit__price">
                    {prodPriceOld > 0 ? <span className="search_form__results_hit__price__discount">-{Math.round((prodPriceOld - prodPrice) / prodPriceOld * 100)}%</span> : null}
                    {PriceFormat(prodPrice)}
                </div>
                <div>
                    {productIDs.includes(prodID)
                        ? cartButtonStatus === 'waiting'
                            ? <button className={[`put_to_cart`, cartButtonStatus, 'put_to_cart_big_size', 'put_to_cart_notempty'].join(' ')}>
                                <div className="spinner_ring"><div></div><div></div><div></div><div></div></div>
                            </button>
                            : <div className="div_clickedoncart">
                                {productQTYs[prodID] === prodQtyinBox
                                    ? <button className="put_to_cart__small_minus" onClick={CartRemove} type="button"><Trash className="top9" /></button>
                                    : <button className="put_to_cart__small_minus" onClick={CartMinus} type="button">-</button>
                                }
                                <input className="cart_amount_input" value={newValue} onChange={(e) => CartChange(e, saleUnitCode)} />
                                <button
                                    className="put_to_cart__small_plus"
                                    onClick={CartPlus}
                                    type="button"
                                >+</button>
                            </div>
                        : <button className={[`put_to_cart`, cartButtonStatus, 'put_to_cart_big_size'].join(' ')} type="button" onClick={CartButtonClick}>
                            <PutToCart className="cart_icon" />
                            <div className="spinner_ring"><div></div><div></div><div></div><div></div></div>
                        </button>
                    }
                </div>
            </div>
            <div className="search_form__results_hit__kind">{prodKind}</div>
        </div>
    )
})