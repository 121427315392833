import { useEffect, useState } from "react"
import { t } from "i18next"
import { catalogsTypeID } from "../../config"
import { defaultCompanyBooklet } from "../../config"
import { ShowLoader, HideLoader } from "../../functions"
import { prepareMarketingData } from "./marketingFunctions"
import { getRequestOptions } from "../../helpers/apiHelper"

import { Breadcrumbs } from "../../breadcrumbs"
import { TablePreloader } from "../TablePreloader"
import LoadableImage from "../LoadableImage/LoadableImage"

import { ReactComponent as Download } from '../../assets/svg/productcatalogdownload.svg'

export const ProductCatalogs = () => {

    const [catalogsData, setCatalogsData] = useState([])
    const [currentCatalog, setCurrentCatalog] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(()=>{
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/marketing-campaigns?include=attachments&search=typeableType.id:${catalogsTypeID}&limit=30`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            const data = json.data || []
            if (data.length) {     
                const marketingData = prepareMarketingData(data)
                setCatalogsData(marketingData)
                setCurrentCatalog(marketingData[0])
            } else {
                setCurrentCatalog(null)
            }
        })
        .catch(error => console.error(error))
        .finally(() => {
            HideLoader()
            setIsLoaded(true)
        })
    }, [])

    return (
        <>
            <Breadcrumbs itemlist={[{'url':'/marketing/','title':t('niki.b2b.menu.marketing')},{'url':'/product-catalogs/','title':t('niki.b2b.marketing.catalogs')}]} />
            <div className="wrapper">
                <h1 className="promotions__h1">{t('niki.b2b.marketing.catalogs')}</h1>
                <div className="promotions__two_columns">
                {currentCatalog 
                    ? <div className="promotions__two_columns__first">
                        <a href={currentCatalog.pdf} target="_blank" rel="noopener noreferrer nofollow">
                            {currentCatalog.thumb && <LoadableImage src={currentCatalog.thumb} alt={t('niki.b2b.marketing.catalogs')}/>}
                        </a>
                        <div className={currentCatalog.thumb ? "promotions__two_columns__first__current_booklet" : ["promotions__two_columns__first__current_booklet", "not_catalog"].join(' ')}>
                            {t('niki.b2b.productcatalogs.h1')}
                        </div>
                        <div>{currentCatalog.from} - {currentCatalog.to}</div>
                    </div>
                    : <div className="promotions__two_columns__first">
                        <img className="promotional_booklet__main_image" src={defaultCompanyBooklet} alt={'defaul banner'} />  
                    </div>
                }
                    <div className="promotions__two_columns__second">
                        <h2>{t('niki.b2b.productcatalogs.full')}</h2>
                        <div className="promotions__two_columns__text">{t('niki.b2b.productcatalogs.downloadfull')}.</div>
                        {currentCatalog && <a href={currentCatalog.pdf} target="_blank" rel="noopener noreferrer nofollow" className="btn btn-info">{t('niki.b2b.productcatalogs.downloadcurrent')}</a>}
                    </div>
                </div>
                <h3 className="all_promotional_booklets">{t('niki.b2b.productcatalogs.previous')}</h3>

                {isLoaded
                    ? <table className="table">
                        <thead>
                            <tr>
                            <th>{t('niki.b2b.productcatalogs.catalog')}</th>
                                <th>{t('niki.b2b.productcatalogs.edition')}</th>
                                <th>{t('niki.b2b.product.date')}</th>
                                <th>{t('niki.b2b.process.download')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {catalogsData.length > 0 && catalogsData.map((e,i) =>
                            <tr key={`catalogstr${i}`} className={e.thumb === currentCatalog.thumb ? 'selected_item_in_list' : ''}>
                                <td>{e.thumb && e.pdf &&
                                    <button onClick={() => setCurrentCatalog(e)}>
                                        <LoadableImage src={e.thumb} alt={e.title} styleImg={{ width: 100}}/>
                                    </button>}
                                </td>
                                <td>{e.title}</td>
                                <td>{e.from} - {e.to}</td>
                                <td>{e.pdf && <a href={e.pdf} target="_blank" rel="noopener noreferrer nofollow"><Download /></a>}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    : <TablePreloader />
                }
            </div>
        </>
    )
}