import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { debounce } from 'lodash'
import { xCompanyID, priceLineID, meilisearchUrl } from '../config'
import { TranslateJSON, ShowLoader, HideLoader, storingCartData } from '../functions'

import { Breadcrumbs } from '../breadcrumbs'
import { columnMyCartDefs } from '../components/myShoppingCart/columnMyCartDefs'
import { returnSearchProduct } from '../components/myShoppingCart/myShopCartFunctions'
import { TablePreloader } from '../components/TablePreloader'
import { InputSearchMyShopCart } from '../components/myShoppingCart/InputSearchMyShopCart'
import { AlertPopup } from '../components/AlertPopup'
import { ProdHistoryPopup } from '../components/ProdHistoryPopup'
import { ModalDeleteCart } from "../components/ModalDeleteCart"
import { PopupWithInput } from "../components/PopupWithInput"
import { CustomGroupRow } from "../components/myShoppingCart/CustomGroupRow"
import { CartAside } from "../components/myShoppingCart/CartAside"
import { getRequestOptions, guruRequestOptions, getCartsData, getRequestApi, getMeilisearchIndexName, paramsForCartDetails, segmentationInsert } from "../helpers/apiHelper"
import { prepareProductToCart } from "../helpers/functionsRedux"
import { deleteCartProduct, addCartProduct, clearCartContent, setCartHeaders, setDeleteProductID, setOpenDeleteProductAlert, setIsOpenMyShopingCart, setIsProductSearch, setShoppingCartSummaryCleare, setIdProductThatIsInCart, setIsCartContentReady, setIsClearInputMyShCart, setIsPrepareCartContent } from '../reduxToolkit/cartSlice'
import { updateProduct } from '../reduxToolkit/productsSlice'
import { setIsOpenNoCartAlert, setIsMore5CartsAlert } from '../reduxToolkit/universalSlice'

import "../assets/css/cart.css"

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

import { AG_GRID_LOCALE } from '../aggridtranslations/locale.full'

import { ReactComponent as MoreVertical } from '../assets/svg/morevertical.svg'

import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey("CompanyName=SmartDigit, Lda,LicensedApplication=NIKI24,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-030570,SupportServicesEnd=27_August_2023_[v2]_MTY5MzA5MDgwMDAwMA==197101bde71f7a31a7dac0b3d3f0a6c8")

let ordersHistory = []
let ordersHistoryIDS = []
if (localStorage.getItem('orders.history') && localStorage.getItem('orders.history') !== 'undefined') {
    ordersHistory = JSON.parse(localStorage.getItem('orders.history'))
    ordersHistory.forEach(e => ordersHistoryIDS.push(e.source_item_id))
}

export const MyShoppingCart = () => {
    return (
        <>
            <div className="breadcrumbs_on_2_colummns">
                <Breadcrumbs itemlist={[{'url':'/myshoppingcart/','title':t('niki.b2b.cart.shoppingcart')}]} />
                <ol className="cart_steps">
                    <li className="current">{t('niki.b2b.cartflow.cart')}</li>
                    <li>{t('niki.b2b.cartflow.delivery')}</li>
                    <li>{t('niki.b2b.feedback.confirmation')}</li>
                </ol>
            </div>
            <MyShoppingCartContent />
        </>
    )
}

const FetchProduct = async (codes) => { 
    const filterInsert = [`(barcode=${codes} OR source_item_id=${codes}) AND company_id=${xCompanyID} AND itemPrices.${priceLineID}.priceline_id=${priceLineID} AND itemSegmentations IN [${segmentationInsert}]`]
    const response = await fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("items")}/search?filter=${encodeURIComponent(filterInsert)}`, guruRequestOptions)
    const json = await response.json()
    return json
}

const postProductToCart = (productID, productName, qty, price, tax) => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details?include=${paramsForCartDetails},item.itemKinds,item.itemStocks,item.itemFamily`
    const body = JSON.stringify({
        cart_header_uuid: localStorage.getItem('cart.uuid'),
        item_id: productID,
        matchcode: productName,
        variation_id: '3',
        qty_in_box: qty.toString(),
        qty: qty.toString(),
        unit_price: price.toString(),
        tax_included_price: tax
    })
    const response = getRequestApi({ URL, method: 'POST', contentType: 'application/json', body })
    return response 
}

let selectProduct = 0

const MyShoppingCartContent = () => {
    const dispatch = useDispatch()

    const cartNumProducts = useSelector(state => state.cartContent.count)
    const deleteProductID = useSelector(state => state.cartContent.deleteProductID)
    const isOpenDeleteAlert = useSelector(state => state.cartContent.isOpenDeleteProductAlert)
    const cartData = useSelector(state => state.cartContent)
    const cartContent = useSelector(state => state.cartContent.cartContent)
    const isCartContentReady = useSelector(state => state.cartContent.isCartContentReady)
    const isPrepareCartContent = useSelector(state => state.cartContent.isPrepareCartContent)

    const shoppingCartExists = (localStorage.getItem('cart.uuid') && localStorage.getItem('cart.id')) ? true : false

    const [cartColumns, setCartColumns] = useState('two_columns')
    const [deleteRowIndex, setDeleteRowIndex] = useState(null)
    const [isOpenAlert, setOpenAlert] = useState(false)
    const [dataDeleteProduct, setDeleteProduct] = useState({cartDetail:'', productid:''})
    const [updateFromQuickOrder, setUpdateFromQuickOrder] = useState(false)
    const [supplierTypes, setSupplierTypes] = useState([])
    const [isOpenDropshipping, setIsOpenDropshipping] = useState(false)
    const [newCartName, setNewCartName] = useState('')
    const [isOpenProductNotFoundAlert, setIsOpenProductNotFoundAlert] = useState(false)
    const [isOpenDeleteCartAlert, setIsOpenDeleteCartAlert] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [idProductThatIsCart, setIdProductThatIsCart] = useState('')

    const deleteCartAlertRef = useRef(null)
    const gridRef = useRef()
    const searchProductEl = useRef(null)
    const searchProductSubmitEl = useRef(null)
    const moreMenuEl = useRef(null)
    const moreMenuOverlayEl = useRef(null)
    const cartRenameWrapperEl = useRef(null)
    const refEnterCartName = useRef(null)

    useEffect(() => {
        dispatch(setIsOpenMyShopingCart(true))
        return () => dispatch(setIsOpenMyShopingCart(false))
        // eslint-disable-next-line
    }, [])

    const groupRowRendererParams = useMemo(() => {
        return {
          innerRenderer: CustomGroupRow,
          suppressCount: true,
        };
    }, []);

    const localeText = useMemo(() => {
        return TranslateJSON(AG_GRID_LOCALE)
    }, [])

    const gridStyles = { color: 'var(--darkgrey)', fontSize: '16px' }
    
    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        }
    }, [])

    const RemoveCartItem = (cartDetail, productId) => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${cartDetail}`, getRequestOptions('DELETE'))
            .then(response => {
                dispatch(deleteCartProduct(cartDetail))
                dispatch(updateProduct({ id: productId, qty: 0 }))
                setRowData(prevState => prevState.filter(p => p.productid !== productId))
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }

    /* HistoryProduct */
    const [openHistoryProduct, setOpenHistoryProduct] = useState(false)
    const [idHistoryProduct, setIdHistoryProduct] = useState('')
    const [productData, setProductData] = useState({})

    useEffect(() => {
        if (openHistoryProduct) {
            const PopupSelector = `.product_history__wrapper_${idHistoryProduct}`
            document.querySelector(PopupSelector).classList.add('active')
        }      
    }, [openHistoryProduct, idHistoryProduct])
    
    const showProdHistory = e => {
        e.preventDefault()
        setOpenHistoryProduct(true)
        setIdHistoryProduct(e.target.dataset.id)
        setProductData(JSON.parse(e.target.dataset.data))
    }  
    /* HistoryProduct */

    const [rowData, setRowData] = useState([])
    const [prodActions, SetProdActions] = useState('none')
    const [selectedProducts, setSelectedProducts] = useState(0)

    const onSelectionChanged = useCallback((event) => {
        var selectedRows = gridRef.current.api.getSelectedRows()
        setSelectedProducts(selectedRows.length)
    }, [])

    const ApplyClick = useCallback(() => {
        if (prodActions === 'none') {
            gridRef.current.api.deselectAll()
        } else {
            const selectedData = gridRef.current.api.getSelectedRows()
            if (!!selectedData && selectedData?.length > 0) {
                if (selectedData?.length === 1) { 
                    const findProduct = gridRef.current.api.getRenderedNodes().filter(p => p.data.productid === selectedData[0].productid)[0]
                    setDeleteRowIndex(findProduct.rowIndex)
                    selectedData.forEach(e => RemoveCartItem(e.id, e.productid))
                } else {
                    dispatch(setIsPrepareCartContent(true))
                    selectedData.forEach(e => RemoveCartItem(e.id, e.productid))
                }
                gridRef.current.api.applyTransaction({ remove: selectedData })
                dispatch(setIsProductSearch(true))
                const res = debounce(() => dispatch(setIsPrepareCartContent(false)), 2300)
                res() 
            }
        }
        // eslint-disable-next-line
    }, [prodActions])
    
    useEffect(() => {
        const resSet = new Set()
        rowData.forEach(i => resSet.add(i.idForGruppingSupplier))
        resSet.size > 1 && setSupplierTypes([...resSet])
    }, [rowData])

    useEffect(() => {
        if (deleteProductID) {
            setRowData(prevState => prevState.filter(p => p.id !== deleteProductID))
            dispatch(setDeleteProductID(''))
        }
        // eslint-disable-next-line
    }, [deleteProductID])

    useEffect(() => {
        if (deleteRowIndex) {
            dispatch(setIsProductSearch(true))
            setDeleteRowIndex(null)
            gridRef.current.api.ensureIndexVisible(deleteRowIndex-1, 'middle')
        }
        // eslint-disable-next-line
    }, [deleteRowIndex])

    useEffect(() => {
        if (cartContent.length || isCartContentReady) {
            ShowLoader()
            const checkSupplierTypes = new Set()
            const RowData = []
            cartData.cartContent.forEach(prod => {
                prod.item_type_id && checkSupplierTypes.add(prod.item_type_id)     
                RowData.push({...prod, vat: Math.round(prod.vat / prod.price * 100), setDeleteRowIndex, setDeleteProduct})
            })
            setRowData(RowData)
            setSupplierTypes([...checkSupplierTypes])
            dispatch(setIsCartContentReady(false))
            HideLoader()
        } else if (cartContent.length === 0) {
            setRowData([])
        }
        // eslint-disable-next-line
    }, [isCartContentReady])

    useEffect(() => {
        if (rowData.length === cartNumProducts) {
            setUpdateFromQuickOrder(false)
        } else if (!updateFromQuickOrder && document.querySelector('.quick_order__wrapper') && (rowData.length !== cartNumProducts)) {
            setUpdateFromQuickOrder(true)
        } 
    }, [cartNumProducts, rowData, updateFromQuickOrder])

    const DisableSearchForm = () => {
        searchProductSubmitEl.current.disabled = true
        searchProductEl.current.disabled = true
        searchProductEl.current.classList.add('disabled')
    }
    
    const EnableSearchForm = () => {
        searchProductSubmitEl.current.disabled = false
        searchProductEl.current.disabled = false
        searchProductEl.current.classList.remove('disabled')
    }

    const returnError = ( message) => { 
        if (localStorage.getItem('cart.uuid')) { 
            getCartsData()
                .then(json => {
                    let existCart = false 
                    json.data && json.data.forEach(e => {
                        existCart = e.uuid === localStorage.getItem('cart.uuid') && true
                    })
                    existCart 
                        ? setErrorText(t('niki.b2b.error_adding_product_cart') +', ' + message)
                        : dispatch(setIsOpenNoCartAlert(true))
                })
                .catch(error => {
                    setErrorText(t('niki.b2b.error_adding_product_cart') +', ' + message)
                    console.error(error)
                })   
        } else {
            setErrorText(t('niki.b2b.error_adding_product_cart') +', ' + message)
        }
    }
    
    const searchProductForm = (e, searchProduct) => {
        e.preventDefault()
        dispatch(setShoppingCartSummaryCleare())
        DisableSearchForm()
        ShowLoader()

        FetchProduct(searchProduct)
        .then(json => {
            if (json.hits?.length > 0) { 
                const product = json.hits[0] 
                
                if (gridRef.current?.props?.rowData?.filter(p => p.productid === product.id).length === 1) {
                    setIdProductThatIsCart(product.id)
                    setOpenAlert(true)
                    EnableSearchForm()
                    if (gridRef.current) {
                        const rowNode = gridRef.current.api.getRowNode(selectProduct);
                        rowNode.selectThisNode(false);
                        const findProduct = gridRef.current.props.rowData.map((p, i) => {
                            if (p.productid === product.id) {
                                return { ...p, rowIndex: i }
                            } else { 
                                return undefined
                            }
                        }).filter(p => p !== undefined)[0]
                        gridRef.current.api.ensureIndexVisible(findProduct.rowIndex, 'middle');
                        selectProduct = findProduct.rowIndex
                        let node = gridRef.current.api.getDisplayedRowAtIndex(findProduct.rowIndex);
                        node.setSelected(true)
                    }
                } else {
                    const checkSupplierTypes = new Set()
                    supplierTypes.forEach(p => checkSupplierTypes.add(p))            
    
                    let addProduct = returnSearchProduct(product)
                   
                    if (localStorage.getItem('cart.uuid') && gridRef?.current?.props?.rowData) {
                        dispatch(setIsProductSearch(true))
                        isOpenDropshipping && dispatch(setShoppingCartSummaryCleare())

                        postProductToCart(addProduct.id, addProduct.name, addProduct.qty, addProduct.price, addProduct.tax)
                            .then(response => response.json())
                            .then(json => {
                                const data = json.data

                                if (json.message) {
                                    setRowData(prevState => (prevState.filter(p => p.id !== product.id)))
                                    json.message.includes("authenticate") ? setErrorText(t('niki.b2b.alert.productnotfound')) : returnError(json.message)
                                } else if (data) {
                                    const checkSupplierTypes = new Set()
                                    data.item?.data?.item_type_id && checkSupplierTypes.add(data.item.data.item_type_id)
                                    
                                    const retProduct = prepareProductToCart(data, '', true)        
                                    dispatch(addCartProduct(retProduct))
                                    setRowData(prev => [...prev, {...retProduct, vat: Math.round(retProduct.vat / retProduct.price * 100), setDeleteRowIndex, setDeleteProduct}])
                                    supplierTypes.forEach(id => checkSupplierTypes.add(id))
                                    setSupplierTypes([...checkSupplierTypes])

                                    const newData = gridRef?.current?.props?.rowData[gridRef?.current?.props?.rowData?.length - 1]
                                    gridRef.current.api.applyTransaction({ update: [newData] })
                                    
                                    EnableSearchForm()
                                    if (gridRef.current) {
                                        const rowNode = gridRef.current.api.getRowNode(selectProduct);
                                        rowNode && rowNode.selectThisNode(false);
                                        const lengthRow = gridRef.current?.props?.rowData?.length
                                        gridRef.current.api.ensureIndexVisible(lengthRow-1, 'middle')
                                    }
                                }
                            })
                            .catch(error => {
                                console.error(error)
                            })
                            .finally(() => {
                                HideLoader()
                                searchProductEl.current.focus()
                                setSelectedProducts(0)
                            })
                        dispatch(setIsClearInputMyShCart())
                    }
                    gridRef.current.api.forEachNode(function (node) { node.setExpanded(node.lastChild === true)})

                    if (gridRef.current) {
                        const lastRowIndex = gridRef.current.api.getDisplayedRowCount() - 1;
                        gridRef.current.api.ensureIndexVisible(lastRowIndex, 'bottom');
                    }
                }
            } else {
                EnableSearchForm()
                setIsOpenProductNotFoundAlert(true)
            }
        })
        .catch(error => console.error(error))
        .finally(() => {
            EnableSearchForm()
            HideLoader()
        })
    }

    const CreateNewCart = (e) => {
        e.preventDefault()
        const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
        requestOptionsCart.body = JSON.stringify({
            company_id: localStorage.getItem('account.company.hashed'),
            customer_account_id: localStorage.getItem('account.entity.id'),
            status_id: 'J8RvMwowm7k2pNOr',
            realKey: '1',
            matchcode: newCartName,
        })
        
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers`,requestOptionsCart)
        .then(response => response.json())
            .then(json => {
                if (json.message && json.errors) {
                    closePopupEnterCartName()
                    dispatch(setIsMore5CartsAlert(true)) 
                }
                if (json.data) {
                    setRowData([])
                    localStorage.setItem('create_new_cart', JSON.stringify(json.data))
                    storingCartData(json.data.uuid, json.data.id_real, json.data.id)
                    dispatch(setCartHeaders({
                        id: json.data.id,
                        idreal: json.data.id_real,
                        name: json.data.matchcode || '',
                        readable_created_at: json.data.readable_created_at,
                    }))
                    CloseMoreMenu()
                    setNewCartName('')
                    closePopupEnterCartName()
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        dispatch(clearCartContent())
    }

    const ShowMoreMenu = () => {
        moreMenuEl.current.classList.add('active')
        moreMenuOverlayEl.current.classList.add('active')
    }
    const CloseMoreMenu = () =>{
        moreMenuEl.current.classList.remove('active')
        moreMenuOverlayEl.current.classList.remove('active')
    }
    const ShowRenamePopup = () => {
        CloseMoreMenu()
        cartRenameWrapperEl.current.classList.add('active')
    }

    useEffect(() => {
        let timer;

        if (isOpenDeleteCartAlert) {
            clearTimeout(timer);
            timer = setTimeout(() => deleteCartAlertRef.current.classList.add('active'), 400)
        } 
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isOpenDeleteCartAlert])

    const DeleteCart = () => {
        setIsOpenDeleteCartAlert(true)
        CloseMoreMenu()
    }
    const CloseRenamePopup = () => cartRenameWrapperEl.current.classList.remove('active')
    const ClosePopup = () =>  setIsOpenDeleteCartAlert(false)

    const [newName,setNewName] = useState('')
    const SubmitNewName = e => {
        e.preventDefault()
        const requestOptionsPatchMatchcode = getRequestOptions('PATCH', null, 'application/json')
        requestOptionsPatchMatchcode.body = JSON.stringify({matchcode: newName})
        
        try {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-header/${localStorage.getItem('cart.id')}`,requestOptionsPatchMatchcode)
                .then(response => response.json())
                .then(json => {
                    if (json.data) {
                        dispatch(setCartHeaders({ id: json.data.id, idreal: json.data.id_real, name: json.data.matchcode }))
                    }
                    CloseRenamePopup()
                })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    }

    const ChangeColumnsView = () => cartColumns === 'two_columns' ? setCartColumns('one_column') : setCartColumns('two_columns')
    
    const closeAlert = () => {
        if (isOpenDeleteAlert) {
            dispatch(setOpenDeleteProductAlert(false))
        } else {
            if (isOpenProductNotFoundAlert) {
                searchProductEl.current.focus()
                setIsOpenProductNotFoundAlert(false)
            } else {
                searchProductEl.current.focus()
                setOpenAlert(false)
            }
        }      
    }

    const onUpdateProductQuantity = () => {
        dispatch(setIdProductThatIsInCart(idProductThatIsCart))
        searchProductEl.current.focus()
        setOpenAlert(false)
    }

    const onDeleteFromAlert = () => {
        dispatch(setOpenDeleteProductAlert(false))
        RemoveCartItem(dataDeleteProduct.cartDetail, dataDeleteProduct.productid)
        let indexItem = 0
        rowData.forEach((p, i) => {
            if (p.productid === dataDeleteProduct.productid) { indexItem = i }
        })
        setRowData(prevState => prevState.filter(p => p.productid !== dataDeleteProduct.productid))
        if (gridRef.current) {
            gridRef.current.api.ensureIndexVisible(indexItem)
        }
    }

    const openPopupEnterCartName = () => {
        refEnterCartName.current.classList.add('active')
        CloseMoreMenu()
    }

    const closePopupEnterCartName = () => refEnterCartName.current.classList.remove('active')

    return (
        <>
            <div className="wrapper">
                <h1>{cartData.name ? `${cartData.idreal} ${cartData.name}` : `${t('niki.b2b.cart.shoppingcart')} ${cartData.idreal}`}
                    <span className="more_button">
                        <button onClick={ShowMoreMenu}><MoreVertical /></button>
                        <div className="more_menu" ref={moreMenuEl}>
                            <button onClick={openPopupEnterCartName}>{t('niki.b2b.cartflow.createnewcart')}</button>
                            <button onClick={ShowRenamePopup}>{t('niki.b2b.cartflow.rename')}</button>
                            <button onClick={DeleteCart} data-id={localStorage.getItem('cart.id')}>{t('niki.b2b.shoppingcart.deletecart')}</button>
                        </div>
                    </span>
                </h1>
                {shoppingCartExists && <div className="myshoppingcart__created">{t('niki.b2b.cart.created')}: <span>{cartData.readable_created_at}</span></div>}
                <div className={[`cart_columns`,cartColumns].join(' ')}>
                    <div className="cart_content">
                        {isPrepareCartContent
                            ? <TablePreloader />
                            : <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnMyCartDefs(dispatch, t, showProdHistory, isOpenDropshipping, supplierTypes)}
                                    defaultColDef={defaultColDef}
                                    rowHeight="76"
                                    rowStyle={gridStyles}
                                    rowGroupPanelShow={'always'}
                                    rowGroup={'true'}
                                    animateRows={true}
                                    singleClickEdit={true}
                                    // onCellValueChanged={onCellValueChanged}
                                    onSelectionChanged={onSelectionChanged}
                                    rowSelection={'multiple'}
                                    masterDetail={true}
                                    detailRowHeight={75}
                                    groupDisplayType={'groupRows'}
                                    localeText={localeText}
                                    groupRowRendererParams={groupRowRendererParams}
                                    suppressDragLeaveHidesColumns={true}
                                />
                            </div>
                        }
                        <div className="cart_product__actions">
                            <div className="cart_product__actions__label">{selectedProducts} {t('niki.b2b.cartflow.productsselected')}</div>
                                <select className="form-control" disabled={isPrepareCartContent} onChange={e => SetProdActions(e.target.value)}>
                                    <option value="none">{t('niki.b2b.cartflow.bulkactions')}</option>
                                    <option value="delete">{t('niki.b2b.cartflow.deleteproducts')}</option>
                                </select>
                            <button className="btn btn-info" disabled={isPrepareCartContent} onClick={ApplyClick}>{t('niki.b2b.cartflow.apply')}</button>
                        </div>
                        <div className="add_product_to_cart">
                            <button className="plus_product">+</button>
                            <InputSearchMyShopCart searchProductForm={searchProductForm} searchProductSubmitEl={searchProductSubmitEl} searchProductEl={searchProductEl} disabled={isPrepareCartContent}/>
                        </div>
                        {openHistoryProduct && <ProdHistoryPopup
                            matchcode={productData.name}
                            unit_code={productData.unitcode}
                            qty_in_box={productData.qtyinbox}
                            item_id={productData.item_id}
                            imgsrc={productData.image}
                            setOpenHistoryProduct={setOpenHistoryProduct}
                            product_id={productData.productid}
                        />}
                    </div>
                    <CartAside supplierTypes={supplierTypes} isOpenDropshipping={isOpenDropshipping} setIsOpenDropshipping={setIsOpenDropshipping} />
                    <button className="change_columns_view" type="button" onClick={ChangeColumnsView}></button>
                </div>
            </div>
            <div className="more_menu__overlay" onClick={CloseMoreMenu} ref={moreMenuOverlayEl}></div>
            <PopupWithInput refWrapper={cartRenameWrapperEl} value={cartData.name} onSubmit={SubmitNewName} setNewValue={setNewName}
                close={CloseRenamePopup} title='niki.b2b.cartflow.rename' titleSubmitButton='niki.b2b.cartflow.renamecart'
            />
            {isOpenDeleteCartAlert && <ModalDeleteCart refEl={deleteCartAlertRef} ClosePopup={ClosePopup} cartIDReal={cartData.idreal} skus={rowData.length} 
                cartID={cartData.id} 
            />}
            <PopupWithInput refWrapper={refEnterCartName} onSubmit={CreateNewCart} setNewValue={setNewCartName}
                close={closePopupEnterCartName} title='niki.b2b.cart.enter_cart_name' titleSubmitButton='niki.b2b.cart.create'
            />
            {isOpenAlert &&
                <AlertPopup
                    text={`${t('niki.b2b.cartflow.item_has_in_cart')}. ${t('niki.b2b.can_ignore_or_add_box')}.`}
                    closeAlert={closeAlert}
                    titleFirstButton={'niki.b2b.cartflow.addtocart'}
                    towButtons={true}
                    onFirstButtonClick={onUpdateProductQuantity}
                />
            }
            {isOpenProductNotFoundAlert && <AlertPopup text={t('niki.b2b.alert.productnotfound')} closeAlert={closeAlert} />}
            {isOpenDeleteAlert && <AlertPopup text={t('niki.b2b.you_want_remove_product')} closeAlert={closeAlert} towButtons={true} onFirstButtonClick={onDeleteFromAlert}/>}
            {errorText && <AlertPopup text={errorText} closeAlert={() => setErrorText('')} />}
        </>
    )
}