import { useEffect, useState, useMemo } from "react"
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { t } from "i18next"
import { priceLineID, keyInvoices } from "../config"
import { PriceFormat, ShowLoader, HideLoader, TranslateJSON } from "../functions"
import { getRequestOptions, getOpenPDF } from '../helpers/apiHelper'

import { Breadcrumbs } from "../breadcrumbs"
import { TablePreloader } from "../components/TablePreloader"

import "./../assets/css/staticpages.css"

import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

import { AG_GRID_LOCALE } from "../aggridtranslations/locale.full"

// import { ReactComponent as Printer } from './../assets/svg/feedbackstatusprinter.svg'
import { ReactComponent as Return } from './../assets/svg/invoicesreturn.svg'
import { ReactComponent as Download } from './../assets/svg/productcatalogdownload.svg'

import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey("CompanyName=SmartDigit, Lda,LicensedApplication=NIKI24,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-030570,SupportServicesEnd=27_August_2023_[v2]_MTY5MzA5MDgwMDAwMA==197101bde71f7a31a7dac0b3d3f0a6c8")

const DateFormat = localDate => localDate.split(' ')[0].replaceAll('/','.')

const gridStyles = {
    color: 'var(--darkgrey)',
    fontSize: '16px',
    overflow: 'visible',
    rowGroupPanelShow: 'always'
}

const InvoicesContent = () => {
    const dispatch = useDispatch()

    // eslint-disable-next-line
    // const [gridApi, setGridApi] = useState(null)
    const [rowData, setRowData] = useState([])
    const [loading, setLoading] = useState(false)
    const idDownloadDocument = useSelector(state => state.universalContent.idDownloadDocument)

    // const onGridReady = (params) => {
    //     setGridApi(params.api)
    // }
    const passed14Days = new Date().getTime() - 1209600000
    const localeText = useMemo(() => {
        return TranslateJSON(AG_GRID_LOCALE)
    }, [])

    useEffect(() => {
        // if (gridApi) {
            setLoading(true)
            ShowLoader()
            // const dataSource = {
            // getRows: (params) => {
                fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents-invoices?include=details,status,documentType,paymentType,paymentMethod&showVat=true&realKey=true&page=1&search=documentType.id:1;account_id:${localStorage.getItem('account.entity.id.real')};${keyInvoices}&searchFields=key:in;account_id:in;documentType.id:in&limit=${100}&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`,getRequestOptions())
                .then(resp => resp.json())
                .then(json => {
                    let localData = []
                    json.data.length > 0 && json.data.forEach(e => {
                        const tempObj = {
                            uuid: e.uuid,
                            processID: e.process_id,
                            processIDReal: e.process_id_real,
                            Invoice: `${e.key}-${e.serial}-${String(e.number).padStart(6,'0')}`,
                            Date: DateFormat(e.created_at),
                            Month: DateFormat(e.created_at).split('.')[1],
                            Year: DateFormat(e.created_at).split('.')[2],
                            SKUs: (e.details && e.details.data && e.details.data.length) || 0,
                            Gros: e.total_tax_included_amount,
                            VAT: e.total_tax_amount,
                            Net: e.total_net_amount,
                            payment: e?.paymentMethod?.data?.matchcode ? e.paymentMethod.data.matchcode : '',
                            order: `${e.key} ${String(e.number).padStart(6,'0')}`,
                        }
                        localData.push(tempObj)
                    })
                    setRowData(localData)
                    setLoading(false)
                    //params.successCallback(localData, json.meta.pagination.total)
                }).catch(err => {
                    //params.successCallback([], 0)
                    console.error(err)
                    setLoading(false)
                })
                .finally(() => HideLoader())
        //     }
        // }

        //gridApi.setDatasource(dataSource)
        //}
    }, [])

    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            // filter: 'agTextColumnFilter',
            // floatingFilter: true
        }
    }, [])

    return (
        <div className="wrapper">
            <h1>{t('niki.b2b.menu.invoices')}</h1>
            {loading
                ? <TablePreloader />
                : <div className="ag-theme-alpine" style={{height: 810, width: '100%'}}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        pagination={false}
                        rowData={rowData}
                        rowModelType={'clientSide'}
                        // rowModelType={'infinite'}
                        // paginationPageSize={perPage}
                        // cacheBlockSize={perPage}
                        // onGridReady={onGridReady}
                        rowHeight={60}
                        rowStyle={gridStyles}
                        groupDisplayType={'groupRows'}
                        groupRowRenderer={'agGroupCellRenderer'}
                        rowGroupPanelShow={'always'}
                        localeText={localeText}
                        suppressDragLeaveHidesColumns={true}
                    >
                        <AgGridColumn
                            field="processID"
                            headerName={t('niki.b2b.debts.processid')}
                            width={150}
                            filter={'text'}
                            cellRenderer={params => {
                                const obj = {...params.data}
                                return obj.processIDReal === 0 ? <div>{obj.processIDReal}</div> : <NavLink to={`/process/${obj.processID}/`} className="invoices_process" onClick={() => localStorage.setItem('from_enter_process', 'Invoices')}>{obj.processIDReal}</NavLink>
                            }}
                            enableRowGroup={true}
                        />
                        <AgGridColumn
                            field="Invoice"
                            headerName={t('niki.b2b.debts.invoice')}
                            width={150}
                            filter={'text'}
                            cellRenderer={params => {
                                const obj = { ...params.data }
                                return <NavLink to={`/document/${obj.uuid}/`} className="orders_link">{params.value}</NavLink>
                            }}
                        />
                        <AgGridColumn
                            field="Date"
                            headerName={t('niki.b2b.product.date')}
                            width={170}
                            //filter={'date'}
                            filter={'text'}
                            enableRowGroup={true}
                        />
                        <AgGridColumn
                            field="Year"
                            headerName={t('niki.b2b.orders.year')}
                            width={100}
                            filter={'agSetColumnFilter'}
                            enableRowGroup={true}
                        />
                        <AgGridColumn
                            field="Month"
                            headerName={t('niki.b2b.orders.month')}
                            width={90}
                            filter={'agSetColumnFilter'}
                            enableRowGroup={true}
                        />
                        <AgGridColumn
                            field="SKUs"
                            headerName={t('niki.b2b.debts.skus')}
                            width={90}
                            cellStyle={{'justifyContent': "center"}}
                        />
                        <AgGridColumn
                            field="Net"
                            headerName={t('niki.b2b.product.net')}
                            width={120}
                            cellStyle={{'justifyContent': "end"}}
                            cellRenderer={params => `${PriceFormat(params.value)}`}
                        />
                        <AgGridColumn
                            field="VAT"
                            headerName={t('niki.b2b.product.vat')}
                            width={120}
                            cellStyle={{'justifyContent': "end"}}
                            cellRenderer={params => `${PriceFormat(params.value)}`}
                        />
                        <AgGridColumn
                            field="Gros"
                            headerName={t('niki.b2b.product.gross')}
                            width={120}
                            cellStyle={{'justifyContent': "end"}}
                            cellRenderer={params => {
                                return `${PriceFormat(params.value)}`
                            }}
                        />
                        <AgGridColumn
                            field="payment"
                            headerName={t('niki.b2b.cartflow.payment')}
                            width={220}
                            cellRenderer={params => <span className={['payment_status',String(params.value).toLowerCase()].join(' ')}>{params.value}</span>}
                        />
                        {/* <AgGridColumn
                            field=""
                            headerName={t('niki.b2b.product.order')}
                            width={130}
                        /> */}
                        <AgGridColumn
                            headerName={t('niki.b2b.process.return')}
                            width={100}
                            cellRenderer={params => {
                                const obj = { ...params.data }
                                const invoiceDate =  new Date(params.data.Date.split('.').reverse().join('-')).getTime()
                                return <>
                                    {(passed14Days < invoiceDate)
                                        ? <NavLink to={`/returnproducts/${obj.uuid}/`} className={['return_status', String(obj.payment).toLowerCase()].join(' ')}><Return /></NavLink>
                                        : <div className={['return_status', 'disabled_color'].join(' ')}><Return/></div>
                                    }
                                </>
                            }}
                        />
                        <AgGridColumn
                            headerName={t('niki.b2b.process.download')}
                            width={120}
                            cellStyle={{'textAlign': 'center'}}
                            cellRenderer={params => {
                                const obj = {...params.data}
                            return <button className="download_button btn_svg_20" disabled={idDownloadDocument} onClick={() => getOpenPDF(obj.uuid, obj.Invoice, dispatch)}>
                                    {idDownloadDocument === obj.uuid
                                        ? <div className="spinner_ring spinner__red"><div></div><div></div><div></div><div></div></div>
                                        : <Download />
                                    }
                                </button>
                            }}
                        />
                    </AgGridReact>
                </div>
            }
        </div>
    )
}

export const Invoices = () => {
    return (
        <>
            <Breadcrumbs itemlist={[
                {'url':'/account/','title':t('niki.b2b.menu.myaccount')},
                {'url':'/invoices/','title':t('niki.b2b.menu.myinvoices')},
            ]} />
            <InvoicesContent />
        </>
    )
}