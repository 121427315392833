import { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from "react-redux"

import { setIsClearInputMyShCart } from '../../reduxToolkit/cartSlice'

import "../../assets/css/cart.css"

export const InputSearchMyShopCart = ({ searchProductForm, searchProductSubmitEl, searchProductEl, disabled }) => {
    const dispatch = useDispatch()

    const isClearInputMyShCart = useSelector(state => state.cartContent.isClearInputMyShCart)
    
    const [searchProduct, setSearchProduct] = useState('')

    useEffect(() => {
        if (searchProduct) {
            setSearchProduct('')
            dispatch(setIsClearInputMyShCart())
        }
        // eslint-disable-next-line
    }, [isClearInputMyShCart])

    return (
        <form className="add_product_to_cart__wrapper" disabled={disabled} onSubmit={(e) => searchProductForm(e, searchProduct)}>
            <input
                type="search"
                className="form-control form-control-little nomagnify search_product_input"
                placeholder={t('niki.b2b.cartflow.productsku')}
                onChange={e => setSearchProduct(e.target.value)}
                value={searchProduct}
                ref={searchProductEl}
                disabled={disabled}
            />
            <button type="submit" className="add_product_to_cart__submit" ref={searchProductSubmitEl}></button>
        </form>
    )
}