import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { t } from "i18next"
import { xCompanyID } from "../config"
import { PriceFormat, ShowLoader, HideLoader, dateDayDifference, FormatDate } from "../functions"
import { getRequestOptions, getOpenPDF } from '../helpers/apiHelper'
import { setIsAccountBlockedModal } from '../reduxToolkit/universalSlice'

import { Breadcrumbs } from "../breadcrumbs"
import { TablePreloader } from "../components/TablePreloader"

import "./../assets/css/staticpages.css"

import { ReactComponent as Download } from './../assets/svg/debtsdownload.svg'

const LoadDebtsData = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/entity-ledgers?search=entity_account_id%3A${localStorage.getItem('account.entity.id.real')}&realKey=1`
    const response = await fetch(URL, getRequestOptions())
    const json = await response.json()
    return json
}

const DebtsContent = () => {

    const dispatch = useDispatch()

    const isAccountBlocked = useSelector(state => state.universalContent.isAccountBlocked)
    const idDownloadDocument = useSelector(state => state.universalContent.idDownloadDocument)

    const [tableData, setTableData] = useState([])
    const [tableDataOverdue, setTableDataOverdue] = useState([])
    const [totalDue, setTotalDue] = useState(0)
    const [totalOverdue, setTotalOverdue] = useState(0)
    const [dataLoaded, setDataLoaded] = useState(true)

    useEffect(() => {
        isAccountBlocked && dispatch(setIsAccountBlockedModal(isAccountBlocked))
        // eslint-disable-next-line
    }, [isAccountBlocked])

    useEffect(() => {
        ShowLoader()
        LoadDebtsData()
            .then(json => {
                setDataLoaded(true)
                setTableData([])
                setTableDataOverdue([])
                let totalOverdueSumm = 0
                let totalDueSumm = 0
                json.data && json.data.forEach(e => {
                    const differenceInDays = dateDayDifference(e.payment_at)
                    const tempObj = {
                        id: e.id,
                        idReal: e.real_id,
                        invoice: xCompanyID === '4' ? e.reference : `${e.document}-${e.serial}-${String(e.number).padStart(6, '0')}`,
                        value: e.total_amount,
                        orderdate: e.created_at,
                        duedate: e.payment_at,
                        overdueby: differenceInDays,
                        skus: e.SKUs,
                        uuid: e.document_uuid,
                    }
                    if (differenceInDays >= 0) {
                        setTableData(prevState => ([...prevState, tempObj]))
                        totalDueSumm += e.total_amount
                    } else {
                        setTableDataOverdue(prevState => ([...prevState, tempObj]))
                        totalOverdueSumm += e.total_amount
                    }
                })
                setTotalDue(totalDueSumm)
                setTotalOverdue(totalOverdueSumm)
            })
            .catch(error => console.error(error))
            .finally(() => {
                HideLoader()
                setDataLoaded(false)
            })
    }, [])

    return (
        <div className="wrapper">
            <h1>{t('niki.b2b.debts.h1')}</h1>
            <h3 className="red">{t('niki.b2b.debts.overdue')}</h3>
            {dataLoaded
                ? <TablePreloader />
                : <table className="table overduedebts_table">
                    <thead>
                        <tr>
                            <th>{t('niki.b2b.debts.processid')}</th>
                            <th>{t('niki.b2b.debts.invoicenumber')}</th>
                            <th>{t('niki.b2b.cart.value')}</th>
                            <th>{t('niki.b2b.debts.orderdate')}</th>
                            <th>{t('niki.b2b.debts.duedate')}</th>
                            <th>{t('niki.b2b.debts.overdueby')}</th>
                            {/* <th>{t('niki.b2b.debts.skus')}</th> */}
                            <th>{t('niki.b2b.debts.invoice')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableDataOverdue && tableDataOverdue.length > 0 && tableDataOverdue.map((e, i) =>
                            <tr key={`overduetr${i}`}>
                                <td>{e.idReal}</td>
                                <td>{e.invoice}</td>
                                <td className="right">{PriceFormat(e.value)}</td>
                                <td>{FormatDate(e.orderdate)}</td>
                                <td>{FormatDate(e.duedate)}</td>
                                <td className="red">{Math.abs(e.overdueby)} {t('niki.b2b.debts.days')}</td>
                                {/* <td>{e.skus}</td> */}
                                <td>
                                    <button disabled={xCompanyID === '4' || idDownloadDocument} className="table_button" onClick={() => getOpenPDF(e.uuid, e.invoice, dispatch)} data-uuid={e.uuid}>
                                        {idDownloadDocument === e.uuid
                                            ? <div className="spinner_ring spinner__red"><div></div><div></div><div></div><div></div></div>
                                            : <Download />
                                        }
                                    </button>
                                </td>
                            </tr>
                        )}
                        <tr className="redbg">
                            <td></td>
                            <td><strong>{t('niki.b2b.debts.totaloverdue')}</strong></td>
                            <td className="right"><strong>{PriceFormat(totalOverdue)}</strong></td>
                            <td colSpan="7"></td>
                        </tr>
                    </tbody>
                </table>
            }

            <h3>{t('niki.b2b.account.debts')}</h3>
            {dataLoaded
                ? <TablePreloader />
                : <table className="table debts_table">
                    <thead>
                        <tr>
                            <th>{t('niki.b2b.debts.processid')}</th>
                            <th>{t('niki.b2b.debts.invoicenumber')}</th>
                            <th>{t('niki.b2b.cart.value')}</th>
                            <th>{t('niki.b2b.debts.orderdate')}</th>
                            <th>{t('niki.b2b.debts.duedate')}</th>
                            <th>{t('niki.b2b.debts.overdueby')}</th>
                            {/* <th>{t('niki.b2b.debts.skus')}</th> */}
                            <th>{t('niki.b2b.debts.invoice')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((e, i) =>
                            <tr key={`tr${i}`}>
                                <td>{e.idReal}</td>
                                <td>{e.invoice}</td>
                                <td className="right">{PriceFormat(e.value)}</td>
                                <td>{FormatDate(e.orderdate)}</td>
                                <td>{FormatDate(e.duedate)}</td>
                                <td className="green">+{e.overdueby} {t('niki.b2b.debts.days')}</td>
                                {/* <td>{e.skus}</td> */}
                                <td>
                                    <button disabled={xCompanyID === '4' || idDownloadDocument} className="table_button" onClick={() => getOpenPDF(e.uuid, e.invoice, dispatch)}>
                                        {idDownloadDocument === e.uuid
                                            ? <div className="spinner_ring spinner__red"><div></div><div></div><div></div><div></div></div>
                                            : <Download />
                                        }
                                    </button>
                                </td>
                            </tr>
                        )}
                        <tr className="greybg">
                            <td></td>
                            <td><strong>{t('niki.b2b.debts.totaldue')}</strong></td>
                            <td className="right"><strong>{PriceFormat(totalDue)}</strong></td>
                            <td colSpan="7"></td>
                        </tr>
                    </tbody>
                </table>
            }
            {/* {isNotImpossiblePrint &&  <AlertPopup text={t('niki.b2b.debts.error_print')} closeAlert={() => setIsNotImpossiblePrint(false)} />} */}
        </div>
    )
}

export const Debts = () => {
    return (
        <>
            <Breadcrumbs itemlist={[
                { 'url': '/account/', 'title': t('niki.b2b.menu.myaccount') },
                { 'url': '/debts/', 'title': t('niki.b2b.debts.h1') },
            ]} />
            <DebtsContent />
        </>
    )
}