import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { xCompanyID, companyCountryShort, xTenant } from '../../config'
import { ShowLoader, HideLoader, FixCompanyName, LangFix, GetBannerData } from '../../functions'
import { useTranslation } from "react-i18next"

import { SnedVerifyCode } from './SnedVerifyCode'
import { LoginFormFooter } from './LoginFormFooter'
import { getRequestOptionsNoAuth } from '../../helpers/apiHelper'
import LoadableImage from "../LoadableImage/LoadableImage"

import '../../assets/css/style.css'
import '../../assets/css/loginform.css'

import { ReactComponent as InfoWhite } from '../../assets/svg/infowhite.svg'
import { ReactComponent as LoginSvg } from '../../assets/svg/login-enter.svg'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import es from 'react-phone-input-2/lang/es.json'
import ru from 'react-phone-input-2/lang/ru.json'
import it from 'react-phone-input-2/lang/it.json'
import pt from 'react-phone-input-2/lang/pt.json'
import de from 'react-phone-input-2/lang/de.json'
import fr from 'react-phone-input-2/lang/fr.json'

const FetchClientInfo = async () => {
    const response = await fetch(`https://geolocation-db.com/json/`)
    const json = await response.json()
    return json
}

const FetchPhone = async (url,requestOptions) => {
    const response = await fetch(url,requestOptions)
    return response.ok
}
const lang = LangFix(localStorage.getItem('locale'))

export const LoginPhone = () => {
    const { t } = useTranslation()

    const bannersData = useSelector(state => state.universalContent.bannersData)

    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneStatus, setPhoneStatus] = useState('enterphone')
    const [wrongCode, setWrongCode] = useState(false)
    const [isCheckCode, setIsCheckCode] = useState(false)
    // eslint-disable-next-line
    const [verificationCode,setVerificationCode] = useState('')

    useEffect(()=>{
        FetchClientInfo()
        .then(json => {
            localStorage.setItem('client.info',JSON.stringify(json))
        })
    }, [])
    
    const ValidatePhone = e => {
        e.preventDefault()
        if (phoneNumber.length) {
            
            const requestOptions = getRequestOptionsNoAuth('POST', null, 'application/json')
            requestOptions.body = JSON.stringify({ phone: `+${phoneNumber}` })
            const url = `${process.env.REACT_APP_API_DOMAIN}/b2b/clients/web/phone`

            ShowLoader()
            FetchPhone(url,requestOptions)
            .then(response => {
                if (response) {
                    setPhoneStatus('phonecorrect')
                    localStorage.setItem('account.user.phone',`+${phoneNumber}`)
                } else {
                    setPhoneStatus('usernotfound')
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        } else {
            setPhoneStatus('wrongnumber')
        }
    }
    
    const [bannerTwo,setBannerTwo] = useState({})
    useEffect(() => {
        const data = bannersData || JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            // 'B2B - Login - Step 1 - Banner'
            e.content_subtype_id === 'J8RvMwowm7k2pNOr' && e.content_type_id === "J8RvMwowm7k2pNOr" && setBannerTwo(GetBannerData(e))
        })
    }, [bannersData])
    // todo
    // const TranslateBundle = () => {
    //     return i18n.getResourceBundle('gr', 'translation');
    // }
    const localLanguage = (lang) => {
        // function getLocatilization() {
        //     const trans = TranslateBundle()
        //     let localizations = {};
        //     let newObj = {}
        //     Object.keys(trans).forEach((key) => {
        //       if (key.startsWith("countries.") && key.endsWith(".name")) {
        //             newObj = {[key.slice(10,13).toLocaleLowerCase()]:trans[key], ...newObj}
        //         }
        //     });
        //     return localizations; 
        // }

        switch (lang) {
            case 'es': return es
            case 'ru': return ru
            case 'pt': return pt
            case 'de': return de
            case 'fr': return fr
            case 'it': return it
            // case 'gr': return getLocatilization()
            default: {}
        }
    }
    function generateLocatilization() {
        let localizations = localLanguage(lang);

        // const localizations = TranslateBundle()?.call_codes?.map((item) => {
        //     return {
        //         [String(item.country_code.trim())]: item.country_name.trim(),
        //     }
        // });
        // return Object.assign({}); 
        return localizations; 
        //  [`${countries.ARG.name}`]
    }

    // function generateCountriesList() {
    // return ['pt', 'gb', 'ru', 'ua']
        // return TranslateBundle()?.call_codes?.map((item) => {
        //     return String(item?.country_code?.trim());
        // });
    // }

    return (
        <>
            <div className="login_form__wrapper">
                <div className="login_form">
                    <NavLink to="/" className="login_form__logo"><img src={`/logo/${xTenant}_${xCompanyID}.svg`} alt="Logo" /></NavLink>
                    <div className="login_form__header">{FixCompanyName(t('niki.b2b.login.smslogin'))}</div>
                    
                    {phoneStatus === 'phonecorrect' && <h2>{t('niki.b2b.login.checkphone')}</h2>}

                    {phoneStatus === 'wrongnumber' && (
                        <div className="login_form__error__fedback_alert">
                            <InfoWhite />
                            {t('niki.b2b.login.numberincorrect')}
                        </div>
                    )}
                    
                    {phoneStatus === 'usernotfound' && (
                        <div className="login_form__error__fedback_alert">
                            <InfoWhite />
                            {t('niki.b2b.login.userwith')} {phoneNumber} {t('niki.b2b.login.notfound')}
                        </div>
                    )}
                    
                    {wrongCode && (
                        <div className="login_form__error__fedback_alert">
                            <InfoWhite />
                            {t('niki.b2b.login.wrongcode')}
                        </div>
                    )}

                    {phoneStatus !== 'phonecorrect'
                        ? (<form onSubmit={ValidatePhone}>
                            <div className="marginbottom20">{t('niki.b2b.login.pleaseinputnumber')}</div>
                            <div className="marginbottom20 wrapprePhoneLogin">
                                <PhoneInput
                                    // onlyCountries={generateCountriesList()}
                                    localization={generateLocatilization()}
                                    country={companyCountryShort || 'gb'}
                                    inputClass="form-control"
                                    value=""
                                    placeholder={t('niki.b2b.login.phonenumber')}
                                    preferredCountries={['it','de','fr','gr','ru','es','pt','gb']}
                                    onChange={phone => {
                                        setPhoneNumber(phone)
                                        if (phoneStatus === 'usernotfound') {
                                            setPhoneStatus('enterphone')
                                        }
                                    }}
                                />
                            </div>
                            <div><button className="btn login_form__button" type="submit">{t('niki.b2b.login.login')}<LoginSvg/></button></div>
                        </form>)
                        : <SnedVerifyCode setWrongCode={setWrongCode} status={phoneStatus === 'phonecorrect'} setIsCheckCode={(val) => setIsCheckCode(val)}/> 
                    }

                    <div className="login_form__forget_email login_form__small_text">
                        {t('niki.b2b.login.cannotreceiveemail')}?
                        {isCheckCode
                            ? <span className="login_form__link" style={{color: '#888'}}>{t('niki.b2b.login.recover')}</span>
                            : <NavLink to="/recover-sms/" className="login_form__link">{t('niki.b2b.login.recover')}</NavLink>
                        }
                    </div>
                    <div className="login_form__forget_email login_form__small_text">
                        {t('niki.b2b.login.preferloginemail')}
                        {isCheckCode
                            ? <span className="login_form__link" style={{color: '#888'}}>{t('niki.b2b.login.now')}</span>
                            : <NavLink to="/" className="login_form__link">{t('niki.b2b.login.now')}</NavLink>
                        }
                    </div>
                    
                    <LoginFormFooter />
                </div>
                <a href={bannerTwo.url} target={bannerTwo.target} rel="noopener noreferrer" className="login_form__banner">
                    <LoadableImage src={bannerTwo.image} alt={bannerTwo.title} />
                </a>
            </div>
            <div className="loader"></div>
        </>
    )
}