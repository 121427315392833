import { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { getRequestOptions } from "../helpers/apiHelper"
import { Breadcrumbs } from "../breadcrumbs"
import LoadableImage from "../components/LoadableImage/LoadableImage"

import "./../assets/css/staticpages.css"
import { FixCompanyName, GetGlobalBannerData, ShowLoader, HideLoader } from "../functions"

const YoutubeID = url => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    var match = url.match(regExp)
    return (match&&match[7].length===11)? match[7] : false
}

const GetVideos = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/content-videos`,getRequestOptions())
    const json = await response.json()
    return json
}

const VideosContent = () => {

    const { t } = useTranslation()
    const youtube = (localStorage.getItem('socials') && localStorage.getItem('socials') !== 'undefined') ? JSON.parse(localStorage.getItem('socials'))?.filter(i => i.matchcode === 'Youtube') : null
    const bannersData = useSelector(state => state.universalContent.bannersData)

    const videoWrapperEl = useRef(null)

    const [videosData,setVideosData] = useState([])
    const [currentVideo,setCurrentVideo] = useState('')
    const [currentVideoTitle,setCurrentVideoTitle] = useState('')
    const [pageImage,setPageImage] = useState({})

    const YoutubePlayer = () => {
        return (
            <iframe
                src={currentVideo}
                title={currentVideoTitle}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        )
    }

    const ExplandVideo = e => {
        setCurrentVideo(e.target.dataset.video)
        setCurrentVideoTitle(e.target.dataset.title)
        videoWrapperEl.current.classList.add('active')
    }
    const HideVideo = () => {
        setCurrentVideo('')
        videoWrapperEl.current.classList.remove('active')
    }

    useEffect(()=>{
        try {
            ShowLoader()
            GetVideos()
            .then(json => {
                setVideosData([])
                json.data && json.data.forEach(e => {
                    const videoID = YoutubeID(e.url)
                    const tempObj = {
                        url: e.url.replace('/watch?v=','/embed/'),
                        id: videoID,
                        thumb: `http://img.youtube.com/vi/${videoID}/0.jpg`,
                        title: e.matchcode,
                    }
                    setVideosData(prevState => ([...prevState, tempObj]))
                })
            })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    },[])

    useEffect(() => {
        const data = bannersData || JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            // e.matchcode === 'B2B - Marketing - Videos image'
            e.content_subtype_id === '21P0praYMaJGEYLx' && e.content_type_id === "21P0praYMaJGEYLx" && setPageImage(GetGlobalBannerData(e)[0] || {})
        })
    }, [bannersData])
    
    return (
        <>
            <Breadcrumbs itemlist={[
                {'url':'/marketing/','title':t('niki.b2b.menu.marketing')},
                {'url':'/videos/','title':t('niki.b2b.marketing.videos')}
            ]} />
            <div className="wrapper">
                <h1>{t('niki.b2b.marketing.videos')}</h1>
                <div className="videos_columns">
                    <div>
                        {/* <div className="video_placeholder">
                            <picture>
                                <source type="image/webp" srcSet={pageImage.webp} />
                                <source type="image/jpeg" srcSet={pageImage.image} />
                                {pageImage.image && <img src={pageImage.image} alt="Video placeholder" />}
                            </picture>
                        </div> */}
                        {pageImage?.image && <LoadableImage src={pageImage.image} alt="Video placeholder" height={'410px'}/>}
                    </div>
                    <div>
                        <h3>{t('niki.b2b.videos.updates')}</h3>
                        <div className="videos__main_description">{t('niki.b2b.videos.text1')}.<br />{t('niki.b2b.videos.text2')}.</div>
                        {youtube?.length && <div><a href={youtube[0].value} className="btn btn-info" target="_blank" rel="noopener noreferrer nofollow">{t('niki.b2b.videos.followyoutube')}</a></div>}
                    </div>
                </div>
                <h2 className="center">{t('niki.b2b.videos.archive')}</h2>
                <ul className="videos_grid">
                    {videosData.length > 0 && videosData.map((e,i) =>
                        <li key={`videosdata${i}`}>
                            <img
                                src={e.thumb}
                                alt={e.title}
                                data-image={e.thumb}
                                data-video={e.url}
                                data-title={e.title}
                                onClick={ExplandVideo}
                            />
                        </li>
                    )}
                </ul>
            </div>
            <div className="video_wrapper" ref={videoWrapperEl}>
                <div className="video_content">
                    <div className="video_content__header">{FixCompanyName(t('niki.b2b.videos.reel'))}</div>
                    <div className="video_content__placeholder">
                        <YoutubePlayer />
                    </div>
                    <div className="video_content__description">{currentVideoTitle}</div>
                    <button className="delivery_addresses__popup__close" onClick={HideVideo}></button>
                </div>
                <div className="video_overlay" onClick={HideVideo}></div>
            </div>
        </>
    )
}

export const Videos = () => {
    return (
        <VideosContent />
    )
}