import { createSlice } from "@reduxjs/toolkit"

const allProductsSlice = createSlice({
    name: 'allproducts',
    initialState: {
        productsView: 'grid',
        isClickOnBrand: false,
        isClickFavorites: false,
        isOnBannerFromDetails: false,
        brandName: '',
        isFavoritesFromAccountPopup: false,
    },
    reducers: {
        setProductsView(state, action) {
            state.productsView = action.payload
        },
        setIsClickOnBrand(state, action) {
            state.isClickOnBrand = action.payload
        },
        setIsClickFavorites(state, action) {
            state.isClickFavorites = action.payload
        },
        setIsOnBannerFromDetails(state, action) {
            state.isOnBannerFromDetails = action.payload
        },
        setBrandName(state, action) {
            state.brandName = action.payload
        },
        setIsFavoritesFromAccountPopup(state, action) {
            state.isFavoritesFromAccountPopup = action.payload
        },
    }
})

export default allProductsSlice.reducer
export const { setIsClickOnBrand, setIsClickFavorites, setProductsView, setIsOnBannerFromDetails, setBrandName, setIsFavoritesFromAccountPopup } = allProductsSlice.actions