import { setDetailsProductId } from '../../reduxToolkit/universalSlice'
import { PriceFormat, GetHistoryData, FormatDate } from '../../functions'

import { ReactComponent as Refresh } from '../../assets/svg/refresh.svg'
import { ReactComponent as ProdHistoryIcon } from '../../assets/svg/prodhistory.svg'

export const columnCartDefs = (dispatch, t, showProdHistory) => {
    const columnDefs = [
        {
            headerName: '',
            field: 'image',
            width: 90,
            floatingFilter: false,
            pinned: 'left',
            cellRenderer: params => <div onClick={() => dispatch(setDetailsProductId(params.data.productid))} className="grid_prod_img">
                <img src={params.data.image} alt={params.data.name} />
            </div>
        },
        {
            headerName: 'ID',
            width: 120,
            field: 'itemid',
            pinned: 'left',
        },
        {
            field: 'name',
            headerName: t('niki.b2b.cartflow.product'),
            filter: 'agTextColumnFilter',
            width: 300,
            autoHeight: true,
            cellRenderer: params => {
                const prodHistory = GetHistoryData(params.data.productid)
                return (
                    <>
                        <strong className="ag_prodname">{params.data.name} {params.data.itemid}</strong>
                        {prodHistory.length > 0 && <>
                            <button
                                className="ag_prodhistory"
                                onClick={showProdHistory}
                                data-id={params.data.itemid}
                                data-data={JSON.stringify(params.data)}
                            >
                                <Refresh className="ag_prodhistory__refresh" />
                                {prodHistory.filter((_, index) => index === 0).map((e, i) => <span key={`historyel${params.data.id}${i}`}>
                                    {FormatDate(e.created_at)} &mdash; {e.quantity} {t('niki.b2b.returnproducts.units')}
                                </span>)}
                                <ProdHistoryIcon className="ag_prodhistory__list" />
                            </button>
                        </>}
                    </>
                )
            }
        },
        {
            field: 'group',
            headerName: t('niki.b2b.shoppingcart.group'),
            width: 100,
            enableRowGroup: true,
            filter: false,
        },
        {
            field: 'type',
            headerName: t('niki.b2b.feedback.type'),
            width: 100,
            enableRowGroup: true,
            filter: false,
        },
        {
            field: 'qty',
            headerName: t('niki.b2b.feedback.qty'),
            width: 90,
            filter: false,
        },
        {
            field: 'supplier',
            headerName: t('niki.b2b.feedback.supplier'),
            enableRowGroup: true,
        },
        {
            field: 'translateStock',
            headerName: t('niki.b2b.feedback.status'),
            width: 130,
            filter: false,
            enableRowGroup: true,
            cellRenderer: params => {
                return (
                    <div className="aggrid_status__wrapper">
                        <div className={['aggrid_status', params.data.instock].join(' ')}></div>
                        <div className={['aggrid_status_text', params.data.instock].join(' ')}>{params.data.translateStock}</div>
                    </div>
                )
            }
        },
        {
            field: '',
            headerName: `${t('niki.b2b.allproducts.discounts')}.`,
            width: 100,
            filter: false,
            cellRenderer: params => {
                const priceOld = parseFloat(params.data.priceold)
                const price = parseFloat(params.data.price)
                if (priceOld > 0) {
                    return <div className="aggrid_discount">{Math.round((priceOld - price) / priceOld * 100) <= 0 ? '' : `-${Math.round((priceOld-price)/priceOld*100)}%`}</div>
                } else {
                    return null
                }
            }
        },
        {
            field: 'price',
            headerName: t('niki.b2b.cart.unipr'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params =>  `${PriceFormat(params.value)}`
        },
        {
            field: 'total',
            headerName: t('niki.b2b.cart.total'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => {
                return `${PriceFormat(params.value)}`
            }
        },
        {
            field: 'vat',
            headerName: t('niki.b2b.product.vat'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => params.data.vat > 0 ? `${params.value}%` : null
        },
    ]
    return columnDefs
}