import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import { PriceFormat } from '../functions'
import { setIsCartContentReady } from '../reduxToolkit/cartSlice'

import '../assets/css/header.css'
import { ReactComponent as RemoveFromCart } from '../assets/svg/removefromcart.svg'

export const CartContent = ({ cartData, ChangeCartInput, RemoveCartItem, activeRemoveItem }) => {
    const dispatch = useDispatch()
    return (
        <div className="header_cart__popup__units" >
            {cartData.length > 0 ? cartData.map((e, i) =>
                <table className="header_cart__popup__unit__table" key={`cartpopuptable${i}`}>
                    <tbody>
                        <tr>
                            <td colSpan="6">
                                <span className="header_cart__popup__unit__table__title">{e.name}</span>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="2">
                                <input
                                    type="text"
                                    className="header_cart__popup__unit__table__input"
                                    value={e.qty}
                                    name={e.id}
                                    data-productid={e.productid}
                                    data-price={e.price}
                                    onChange={(event) => {
                                        ChangeCartInput(event, e.sale_unit_code)
                                        window.location.pathname === '/myshoppingcart/' && dispatch(setIsCartContentReady(true))
                                    }}
                                />
                            </td>
                            <td>{t('niki.b2b.cart.ref')}</td>
                            <td className="header_cart__popup__unit__table__value">{e.productitemid || ''}</td>
                            <td>{t('niki.b2b.cart.unit')}</td>
                            <td className="header_cart__popup__unit__table__value">{e.unitcode}</td>
                            {activeRemoveItem && <td rowSpan="2">
                                <button
                                    onClick={RemoveCartItem}
                                    data-id={e.itemid}
                                    data-price={e.price}
                                    data-qty={e.qty}
                                    data-cartdetail={e.id}
                                ><RemoveFromCart /></button>
                            </td>}
                        </tr>
                        <tr>
                            <td>{t('niki.b2b.allproducts.price')}</td>
                            <td className="header_cart__popup__unit__table__value">{PriceFormat(e.price)}</td>
                            <td>{t('niki.b2b.cart.total')}</td>
                            <td className="total">{PriceFormat(parseFloat(e.price) * parseFloat(e.qty))}</td>
                        </tr>
                    </tbody>
                </table>
            ) : t('niki.b2b.cartflow.noproducts')}
        </div>
    )     
}