import { useState, useEffect } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { priceLineID } from "../config"
import { getRequestOptions, getOpenPDF, getCartsData } from '../helpers/apiHelper'
import { ShowLoader, HideLoader } from "../functions"

import { Breadcrumbs } from "../breadcrumbs"
import { ContactsAside } from "../components/contactsaside"
import LoadableImage from "../components/LoadableImage/LoadableImage"

import { ReactComponent as Download } from './../assets/svg/productcatalogdownload.svg'
import { ReactComponent as Account } from './../assets/svg/thankyouaccount.svg'
import { ReactComponent as Orders } from './../assets/svg/thankyouorders.svg'
import { ReactComponent as ShoppingCarts } from './../assets/svg/thankyoushoppingcarts.svg'
import "./../assets/css/staticpages.css"

const LoadOrderData = async orderID => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/documents/${orderID}?include=details,status&realKey=true&showVat=true&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`
    const responce = await fetch(URL, getRequestOptions())
    const json = await responce.json()
    return json
}

const ThankYouContent = props => {

    const dispatch = useDispatch()
    const idDownloadDocument = useSelector(state => state.universalContent.idDownloadDocument)

    let orderIDs = []
    if (props.order_ids?.length > 0) orderIDs = props.order_ids

    const [numberCarts, setNumberCarts] = useState(0)
    const [orderNumber, setOrderNumber] = useState([])

    useEffect(() => {
        getCartsData().then(json => json?.data && setNumberCarts(json.data.length))
        const getOrderid = async (orderId) => {
            try {
                ShowLoader()
                const result = await LoadOrderData(orderId)
                result.data.serial && setOrderNumber(prev => [...prev, { order_number: `${result.data.key}-${result.data.serial}${result.data.number ? '-'+result.data.number : ''}`, order_id: orderId }])
            } catch (error) {
                console.error(error)
            } finally {
                HideLoader()
            }
        }

        orderIDs.forEach(uuid => getOrderid(uuid))
        // eslint-disable-next-line
    }, [])

    return (
        <div className="wrapper">
            <div className="thank_you__columns">
                <div>
                    <h1>{t('niki.b2b.cartflow.thankyouh1')}!</h1>
                    {orderNumber.length === orderIDs.length
                        ? orderNumber.sort((a, b) => a.order_number - b.order_number).map(el => {
                            return (
                                <div className="thank_you__red_alert" key={el.order_id}>
                                    <div className="thank_you__red_alert__header">
                                        {t('niki.b2b.product.order')} #{el.order_number}
                                    </div>
                                    <div className="thank_you__red_alert__text">
                                        {t('niki.b2b.cartflow.redtext1')}.
                                        <br />
                                        {t('niki.b2b.cartflow.redtext2')}
                                    </div>
                                    <button disabled={idDownloadDocument} onClick={() => getOpenPDF(el.order_id, el.order_number, dispatch)} className="thank_you__red_alert__link btn_svg__stroke_white">
                                        {t('niki.b2b.process.download')}
                                        {idDownloadDocument === el.order_id
                                            ? <div className="spinner_ring spinner__smaller"><div></div><div></div><div></div><div></div></div>
                                            : <Download />
                                        }
                                    </button>
                                </div>
                            )
                        })
                        : <LoadableImage src={'src'} alt={'title'} height={'240px'} margin={'0 0 40px 0'} />
                    }

                    <h3>{t('niki.b2b.feedback.otheroptons')}</h3>
                    <ul className="thank_you__buttons">
                        <li><NavLink to="/account/"><Account />{t('niki.b2b.menu.myaccount')}</NavLink></li>
                        <li><NavLink to="/orders/"><Orders />{t('niki.b2b.account.orders')}</NavLink></li>
                        <li><NavLink to="/shoppingcarts/" className="shoppingcarts_margin">
                            <ShoppingCarts />
                            {t('niki.b2b.cart.myshoppingcarts')}
                            {numberCarts > 0 &&
                                <span className="thank_you__buttons__alert">{numberCarts}</span>
                            }
                        </NavLink></li>
                    </ul>
                </div>
                <div>
                    <div className="your_designated_support_team on_thanks_page">{t('niki.b2b.account.supportteam')}</div>
                    <ContactsAside />
                </div>
            </div>
        </div>
    )
}

export const ThankYou = () => {
    const [searchParams] = useSearchParams()
    const ids = searchParams.get('orderIds')?.split(',')

    return (
        <>
            <Breadcrumbs itemlist={[
                { 'url': '/myshoppingcart/', 'title': t('niki.b2b.cart.shoppingcart') },
                { 'url': '/checkout/', 'title': t('niki.b2b.cartflow.chckt') },
                { 'url': `/thankyou`, 'title': t('niki.b2b.cartflow.ordercomplete') },
            ]} />
            <ThankYouContent order_ids={ids} />
        </>
    )
}