import { useEffect, useState } from "react"
import { bookletsTypeID, currentMainColor, defaultCompanyBooklet } from "../../config"
import { t } from "i18next"
import { NavLink } from 'react-router-dom'

import { Breadcrumbs } from "../../breadcrumbs"
import { ShowLoader, HideLoader } from "../../functions"
import { prepareMarketingData } from "./marketingFunctions"
import { getRequestOptions } from "../../helpers/apiHelper"
import LoadableImage from "../LoadableImage/LoadableImage"

export const Promotions = () => {

    const BookletClick = (event, e) => {
        document.querySelector('.promotions__booklet.current').classList.remove('current')
        event.target.classList.add('current')
        setCurrentBooklet(e)
    }

    const [booklets, setBooklets] = useState([])
    const [currentBooklet, setCurrentBooklet] = useState({})

    useEffect(() => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/marketing-campaigns?include=attachments&search=typeableType.id:${bookletsTypeID}&limit=30&get_related_items_id=1`,getRequestOptions())
            .then(response => response.json())
            .then(json => {
                const data = json.data || []
                if (data.length) {     
                    const marketingData = prepareMarketingData(data)
                    setBooklets(marketingData)
                    setCurrentBooklet(marketingData[0])
                } else {
                    setCurrentBooklet(null)
                }
            })
            .catch(error => {
                console.error(error)
                setCurrentBooklet(null)
            })
            .finally(() => HideLoader())
    }, [])


    const onBookletView = () => {
        localStorage.setItem('booklet.data', JSON.stringify(currentBooklet))
    }

    return (
        <>
            <Breadcrumbs itemlist={[{'url':'/marketing/','title':t('niki.b2b.menu.marketing')},{'url':'/promotional-booklets/','title':t('niki.b2b.marketing.booklets')}]} />
            <div className="wrapper">
                <h1 className="promotions__h1">{t('niki.b2b.marketing.booklets')}</h1>
                <div className="promotions__two_columns">
                    {currentBooklet 
                        ? <div className="promotions__two_columns__first">
                            <a href={currentBooklet.pdf} target="_blank" rel="noopener noreferrer">
                                {/* <img className="promotional_booklet__main_image" src={currentBooklet.thumb} alt={currentBooklet.title} /> */}
                                <LoadableImage src={currentBooklet.thumb} alt={currentBooklet.title} styleImg={{border: `1px solid ${currentMainColor}`, borderRadius: 10}} />
                            </a>
                            <div className="promotions__two_columns__first__current_booklet">{t('niki.b2b.promotions.current')}</div>
                            <div>{currentBooklet.from} - {currentBooklet.to}</div>
                        </div>
                        : <div className="promotions__two_columns__first">
                           <img className="promotional_booklet__main_image" src={defaultCompanyBooklet} alt={'defaul banner'} />  
                        </div>
                    }
                    <div className="promotions__two_columns__second">
                        <h2>{t('niki.b2b.promotions.currentoffers')}</h2>
                        <div className="promotions__two_columns__text">{t('niki.b2b.promotions.text')} {t('niki.b2b.promotions.text2')}</div>
                        {currentBooklet && <button className={`btn ${currentBooklet?.productsIdArray?.length > 0 && 'btn-info'}`} disabled={currentBooklet?.productsIdArray?.length === 0}>
                            <NavLink
                                // style={{ isActive: currentBooklet?.productsIdArray?.length === 0 }}
                                disabled={currentBooklet?.productsIdArray?.length === 0}
                                to={`/all-products/?booklet=${currentBooklet.id}`}
                                onClick={onBookletView}
                                className={`btn ${currentBooklet?.productsIdArray?.length > 0 && 'btn-info'}`}
                            >
                                {t('niki.b2b.promotions.view')}
                            </NavLink>
                        </button>}
                    </div>
                </div>
                {currentBooklet && <h3 className="all_promotional_booklets">{t('niki.b2b.promotions.all')}</h3>}
                <div className="promotions__booklets">
                    {booklets.length > 0 && booklets.map((e,i) =>
                        <div
                            className={[`promotions__booklet`,i === 0 && 'current'].join(' ')}
                            onClick={(event) => BookletClick(event, e)}
                            key={`promobooklet${i}`}
                        >
                            <div className="promotions__booklet__img">{e.thumb &&
                                // <img className="promotions__booklet__image" src={e.thumb} alt={e.title} />
                                <LoadableImage  src={e.thumb} alt={e.title} />
                            }</div>
                            <div className="promotions__booklet__dates">{e.from} - {e.to}</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}