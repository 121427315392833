import { setDetailsProductId } from '../../reduxToolkit/universalSlice'
import { PriceFormat, GetHistoryData, FormatDate } from '../../functions'
import { InputRenderer } from './InputRenderer'

import { ReactComponent as Refresh } from '../../assets/svg/refresh.svg'
import { ReactComponent as ProdHistoryIcon } from '../../assets/svg/prodhistory.svg'

export const columnMyCartDefs = (dispatch, t, showProdHistory, isOpenDropshipping, supplierTypes) => {
    const columnDefs = [
        {
            headerName: '',
            field: 'image',
            width: 90,
            floatingFilter: false,
            checkboxSelection: true,
            pinned: 'left',
            cellRenderer: params => {
                return (
                    <div onClick={() =>  dispatch(setDetailsProductId(params.data.productid))} className="grid_prod_img">
                        <img src={params.data.image} alt={params.data.name} />
                    </div>
               ) 
            }
        },
        {
            headerName: 'ID',
            width: 120,
            field: 'itemid',
            pinned: 'left',
        },
        {
            field: 'name',
            headerName: t('niki.b2b.cartflow.product'),
            width: 300,
            autoHeight: true,
            filter: 'agTextColumnFilter',
            cellRenderer: params => {
                const prodHistory = GetHistoryData(params.data.productid)
                return (
                    <>
                        <strong className="ag_prodname">{params.data.name.slice(0, params.data.name.indexOf('('))}</strong>
                        {prodHistory.length > 0
                            &&
                            <button
                                className="ag_prodhistory"
                                onClick={showProdHistory}
                                data-id={params.data.item_id}
                                data-data={JSON.stringify(params.data)}
                            ><Refresh className="ag_prodhistory__refresh" />{prodHistory.filter((_, index) => index === 0).map((e, i) =>
                                <span key={`historyel${params.data.id}${i}`}>{FormatDate(e.created_at)} &mdash; {e.quantity} {t('niki.b2b.returnproducts.units')}</span>
                            )}<ProdHistoryIcon className="ag_prodhistory__list" /></button>
                        }
                    </>
                )
            },
        },
        {
            field: 'group',
            headerName: t('niki.b2b.shoppingcart.group'),
            width: 100,
            enableRowGroup: true,
            filter: false,
        },
        {
            field: 'type',
            headerName: t('niki.b2b.feedback.type'),
            width: 100,
            enableRowGroup: true,
            filter: false,
        },
        {
            field: 'qty',
            headerName: t('niki.b2b.feedback.qty'),
            filter: false,            
            aggFunc: (params) => {
                let sum = 0;
                params.values.forEach((value) => (sum += value));
                return sum;
            },
            cellRenderer:  InputRenderer,
        },
        {
            field: 'unitcode',
            headerName: t('niki.field.sale_unit_id'),
            width: 130,
            enableRowGroup: true,
            filter: false,
        },
        {
            field: 'supplier',
            headerName: t('niki.b2b.feedback.supplier'),
            enableRowGroup: true,
            rowGroup: isOpenDropshipping && supplierTypes.filter(i => i !== undefined).length  > 1,
            hide: isOpenDropshipping && supplierTypes.filter(i => i !== undefined).length > 1,
        },
        {
            field: 'translateStock',
            headerName: t('niki.b2b.feedback.status'),
            width: 100,
            filter: false,
            cellRenderer: params => {
                return (
                    <div className="aggrid_status__wrapper">
                        <div className={['aggrid_status', params.data.instock].join(' ')}></div>
                        <div className={['aggrid_status_text', params.data.instock].join(' ')}>{params.data.translateStock}</div>
                    </div>
                )
            },
            enableRowGroup: true,
        },
        {
            field: '',
            headerName: t('niki.b2b.allproducts.discounts'),
            width: 100,
            filter: false,
            cellRenderer: params => {
                const price = params.data.price
                const priceOld = params.data.priceold
                if (priceOld > 0 && price !== priceOld) {
                    return <div className="aggrid_discount">{Math.round((priceOld - price) / priceOld * 100) < 0 ? '' : '-'}{Math.round((priceOld - price) / priceOld * 100)}%</div>
                } else {
                    return null
                }
            }
        },
        {
            field: 'price',
            headerName: t('niki.b2b.cart.unipr'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => `${PriceFormat(params.value)}`,
        },
        {
            field: 'total_net',
            headerName: t('niki.b2b.orders.totalnet'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => `${PriceFormat(params.value)}`,
        },
        {
            field: 'total',
            headerName: t('niki.b2b.cart.total'),
            filter: 'agTextColumnFilter',
            width: 0,
            minWidth: 0,
            cellClass: 'custom_style_cell__not_show',
            aggFunc: (params) => {
                let sum = 0;
                const valuta = PriceFormat(params.values[0]) && PriceFormat(params.values[0]).slice(0,1)
                params.values.forEach((value) => (sum += +PriceFormat(value).replace(/[^0-9.]/g, "")));
                return `${valuta}${sum}`;
            },
        },
        {
            field: 'estimated_delivery',
            filter: 'agTextColumnFilter',
            width: 0,
            minWidth: 0,
            cellClass: 'custom_style_cell__not_show',
            aggFunc: (params) => {
                return params.values[0];
            },
        },
        {
            field: 'vat',
            headerName: t('niki.b2b.product.vat'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => params.data.vat > 0 ? `${params.value}%` : null
        },
    ]
    return columnDefs
}