import { xCompanyID, xTenant, priceLineID, meilisearchUrl } from '../config'
import { ShowLoader, HideLoader, LangFix, ArrayUniqe } from '../functions.js'
import { setIsOpenProductNotFoundAlert, setIsOpenNoCartAlert, setIsNotImpossiblePrint, setIdDownloadDocument, setIsReadyFavorites } from '../reduxToolkit/universalSlice'
import { CheckTokenLifeTime } from './checkAccessToken'

export const guruRequestOptions = {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CompanyID': xCompanyID,
        'Authorization': `Bearer ${process.env.REACT_APP_MEILISEARCH_SECRET_KEY}`
    }
}

export const getRequestOptions = (method, accept, contentType) => {
    return {
        method: method ? method : 'GET',
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        }
    }
}

export const getRequestApi = async ({ method, accept, contentType, URL, body = null }) => {
    const headers = {
        method: method ? method : 'GET',
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        },
        body
    }

    let response = {}
    try {
        const result = await fetch(URL, headers)
        if (result.status === 401) {
            response = result
            !localStorage.getItem('isCheckTokenLifeTime') && CheckTokenLifeTime()
        } else {
            response = result
        }
    } catch (error) {
        console.error(error)
    }

    return response
}

export const getRequestOptionsNoAuth = (method, accept, contentType) => {
    return {
        method: method ? method : 'GET',
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
        }
    }
}

export const getBanners = async (item_segmentations = '') => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/contents?include=contentType,attachments,contentManagementPlans,contentManagementPlans.attachments${item_segmentations}`, getRequestOptionsNoAuth())
    const json = await response.json()
    return json
}

export const updateProductInCart = (productId, qty) => {
    const requestOptions = getRequestOptions('POST', null, 'application/json')
    requestOptions.body = JSON.stringify({ qty: typeof qty === "string" ? parseFloat(qty) : qty })

    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${productId}`, requestOptions)
        .then(response => {
            // console.log(response)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
}

export const getOpenPDF = (documentUuid, fileName, dispatch) => {
    dispatch(setIdDownloadDocument(documentUuid))
    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents/${documentUuid}/print`, getRequestOptions('GET', 'text/plain'))
        .then(response => {
        if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
        }
        return response.blob()
    })
    .then(blob => {        
        const href = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `${fileName}.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link);
        URL.revokeObjectURL(href);           
    })
    .catch(error => {
        dispatch(setIsNotImpossiblePrint(true))
        console.error(error)
    })
    .finally(() => {
        HideLoader()
        dispatch(setIdDownloadDocument(''))
    })
}

export const getCartsData = async (url, limit) => {
    // include=status,company,cartDetails
    const URL = limit
        ? `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id')}?include=cartDetails&realKey=1&limit=5&orderBy=id&sortedBy=desc`
        : `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id')}?realKey=1&sortedBy=desc`

    let response = {}
    try {
        const result = await fetch(url ? url : URL, getRequestOptions())
        if (result.status === 401) {
            response = result
            !localStorage.getItem('isCheckTokenLifeTime') && CheckTokenLifeTime()
        } else {
            response = result
        }
    } catch (error) {
        console.error(error)
    }

    const json = await response.json()
    return json
}

export const returnErrorDuringAddProductToCart = (error, dispatch) => {
    if (localStorage.getItem('cart.uuid')) {
        getCartsData()
            .then(json => {
                let existCart = false
                json.data && json.data.forEach(e => {
                    existCart = e.uuid === localStorage.getItem('cart.uuid') && true
                })
                existCart
                    ? dispatch(setIsOpenProductNotFoundAlert(true))
                    : dispatch(setIsOpenNoCartAlert(true))
            })
            .catch(error => {
                dispatch(setIsOpenProductNotFoundAlert(true))
                console.error(error)
            })
    } else {
        dispatch(setIsOpenProductNotFoundAlert(true))
    }
    console.error(error)
}

export const loadDebtsData = async (url) => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/entity-ledgers?search=entity_account_id%3A${localStorage.getItem('account.entity.id.real')}&realKey=1`

    let response = {}
    try {
        const result = await fetch(url ? url : URL, getRequestOptions())
        response = result
    } catch (error) {
        console.error(error)
    }
    const json = await response.json()
    return json
}

export const fetchPreferences = async () => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/b2b/api-module-preferences?include=module,user&realKey=1&search=module_id:999`
    const response = await fetch(url, getRequestOptions())
    const json = await response.json()
    return json
}

export const requestCreateNewCart = (newCartName) => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers`
    const params = {
        company_id: localStorage.getItem('account.company.hashed'),
        customer_account_id: localStorage.getItem('account.entity.id'),
        status_id: 'J8RvMwowm7k2pNOr',
        realKey: '1',
    }

    if (newCartName) params.matchcode = newCartName
    const body = JSON.stringify(params)

    const response = getRequestApi({ URL, method: 'POST', contentType: 'application/json', body })
    return response
}

export const getAllFavorites = async (dispatch) => {
    fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("favorites")}/search?filter=user_id=${localStorage.getItem('user.id')}&limit=8000&attributesToRetrieve=favoriteable_id`, guruRequestOptions)
    .then(response => response.json())
    .then(json => {
        json.hits?.length > 0
            ? localStorage.setItem('favorites', json.hits.map(i => i.favoriteable_id).join(','))
            : localStorage.setItem('favorites', '')
        json.hits?.length > 0 && dispatch(setIsReadyFavorites(true))
    })
    .catch(error => console.error(error))
}

export const paramsForCartDetails = `include=item,item.sale_unit_code,item.attachment,item.itemDescriptions,item.itemPrices,item.itemDiscounts,item.entityAccount,item.itemKinds,item.itemStocks,item.itemFamily,item.attachments`

export const cartHeaderInclude = ['cartDetails', 'cartDetails.item', 'cartDetails.sale_unit_code', 'cartDetails.item.attachment', 'cartDetails.item.itemDescriptions', 'cartDetails.item.itemPrices', 'cartDetails.item.itemDiscounts', 'cartDetails.item.itemKinds', 'cartDetails.item.itemStocks', 'cartDetails.item.itemFamily', 'cartDetails.item.entityAccount', 'cartDetails.item.attachments']

export const getMeilisearchIndexName = (indexName) => `${xTenant}_${xCompanyID}_${indexName}`

export let segmentationInsert = ''
if (localStorage.getItem('account.segmentationids')) segmentationInsert = ArrayUniqe(localStorage.getItem('account.segmentationids').split(',')).join(',')

export const startFacetFilters = [
    '',
    `itemPrices.${priceLineID}.priceline_id=${priceLineID}`,
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    `itemSegmentations IN [${segmentationInsert}]`,
]

export const bookletFacetFilters = [
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    localStorage.getItem('marketingCampaignIds')
        ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.booklet}]`
        : `is_booklet=true`,
    `itemSegmentations IN [${segmentationInsert}]`,
]

export const alcoholicFacetFilters = [
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    localStorage.getItem('marketingCampaignIds')
        ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.promo}]`
        : `is_discount=true`,
    `itemSegmentations IN [${segmentationInsert}]`,
]