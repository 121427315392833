import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { InstantSearch, Configure, Hits } from 'react-instantsearch-dom'
import { t } from 'i18next'

import { searchClient, priceLineID, xCompanyID } from "./../config"
import { getMeilisearchIndexName, bookletFacetFilters } from '../helpers/apiHelper'
import { setProductsView } from './../reduxToolkit/allproduct'
import { Product } from '../components/product/Product'

const bookletHit = ({ hit }) => <Product {...hit}/>

export const NoMatch = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setProductsView('grid'))
        // eslint-disable-next-line
    }, [])

    return (
        <div className="wrapper center page404">
            <div className="big404">404</div>
            <h1>{t('niki.b2b.404.h1')}</h1>
            <div>{t('niki.b2b.404.underh1')}.</div>
            <div className="help_404">{t('niki.b2b.404.underh12')} {t('niki.b2b.404.underh13')}:</div>

            <h2>{t('niki.b2b.404.booklet')}</h2>
            <div className="wrapper">
                <InstantSearch
                    indexName={getMeilisearchIndexName("items")}
                    searchClient={searchClient}
                >
                    <Configure
                        facetFilters={bookletFacetFilters}
                        hitsPerPage={12}
                        attributesToHighlight={[]}
                        attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions',`${xCompanyID === '4' ? 'brand' : 'trademark' }`,'country','kinds']}
                    />
                    <Hits hitComponent={bookletHit} />
                </InstantSearch>
            </div>

        </div>
    )
}