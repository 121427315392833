import React, { useEffect, useState, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { t } from "i18next"
import { useSelector } from 'react-redux'

import { xCompanyID, xTenant} from '../../config'
import { FixCompanyName, ShowLoader, HideLoader, GetBannerData } from '../../functions'
import { LoginFormFooter } from './LoginFormFooter'
import { getRequestOptionsNoAuth } from '../../helpers/apiHelper'
import { ModalShowRows } from '../ModalShowRows'
// import { AlertPopup } from '../AlertPopup'
import LoadableImage from "../LoadableImage/LoadableImage"

const GetResault = async endpoint => {
    const response = await fetch(endpoint,getRequestOptionsNoAuth())
    const json = await response.json()
    return json
}

export const RecoverMail = () => {
    const navigate = useNavigate()

    const bannersData = useSelector(state => state.universalContent.bannersData)

    const [bannerTwo, setBannerTwo] = useState({})
    const [vatID, setVatID] = useState('')
    const [clientID, setClientID] = useState('')
    const [emptyVatID, setEmptyVatID] = useState(false)
    const [emptyClientID, setEmptyClientID] = useState(false)
    const [emails, setEmails] = useState([])
    const cartPopupEl = useRef(null)

    const ClosePopup = () => cartPopupEl.current.classList.remove('active')
    const onBackToLogin = () => {
        ClosePopup()
        navigate('/')
    }
    
    const RecoverMail = e => {
        e.preventDefault()
        vatID.trim().length === 0 ? setEmptyVatID(true) : setEmptyVatID(false)
        clientID.trim().length === 0 ? setEmptyClientID(true) : setEmptyClientID(false)
        if (vatID.trim().length > 0 && clientID.trim().length > 0) {
            try {
                ShowLoader()
                GetResault(`${process.env.REACT_APP_API_DOMAIN}/b2b/users_email_access_recovery?vat_id=${vatID}&customer_number=${clientID}`).then(json => {
                    if (json.data) {
                        if(json.data.length > 0) {
                            setEmails(json.data)
                            cartPopupEl.current.classList.add('active')
                            setVatID('')
                            setClientID('')
                        } else {
                            document.querySelector('.product_not_found').classList.add('active')
                            setVatID('')
                            setClientID('')
                        }
                    } else {
                        document.querySelector('.product_not_found').classList.add('active')
                    }
                })
            } catch (error) {
                document.querySelector('.product_not_found').classList.add('active')
                console.error(error)
            } finally {
                HideLoader()
            }
        }
    }

    useEffect(() => {
        const data = bannersData || JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            // 'B2B - Login - Step 1 - Banner'
            e.content_subtype_id === 'J8RvMwowm7k2pNOr' && e.content_type_id === "J8RvMwowm7k2pNOr" && setBannerTwo(GetBannerData(e))
        })
    }, [bannersData])
    
    return (
        <div className="login_form__wrapper">
            <form autoCapitalize="off" autoComplete="off" autoCorrect="off" className="login_form" onSubmit={RecoverMail}>
                <NavLink to="/" className="login_form__logo"><img src={`/logo/${xTenant}_${xCompanyID}.svg`} alt="Logo" /></NavLink>
                <div className="login_form__header">{FixCompanyName(t('niki.b2b.login.recoveraccess'))}</div>
                <div className="marginbottom20">{t('niki.b2b.login.inputvatid')}</div>
                {emptyVatID && <div className="alert alert-danger">{t('niki.b2b.login.emptyvatid')}</div>}
                <div className="marginbottom20">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t('niki.b2b.login.vatid')}
                        value={vatID}
                        onChange={e => setVatID(e.target.value)}
                    />
                </div>
                {emptyClientID && <div className="alert alert-danger">{t('niki.b2b.login.emptyclientid')}</div>}
                <div className="marginbottom20">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t('niki.b2b.login.clientid')}
                        value={clientID}
                        onChange={e => setClientID(e.target.value)}
                    />
                </div>
                <div className='wrapper_buttons_recovere_actions'>
                    <button className="btn">{t('niki.b2b.login.recoveremail')}</button>
                    <NavLink to="/" className="btn btn-primary">{t('niki.b2b.recovery_modal.back_to_login')}</NavLink>
                </div>
                <LoginFormFooter />
            </form>
            <a href={bannerTwo.url} target={bannerTwo.target} rel="noopener noreferrer" className="login_form__banner">
                <LoadableImage src={bannerTwo.image} alt={bannerTwo.title} />
            </a>
            <div className="loader"></div>
            {/* <AlertPopup text={t('niki.b2b.alert.emailnotfound')} addclass="email_not_found"/> */}
            {emails?.length > 0 && <ModalShowRows content={emails} refEl={cartPopupEl} ClosePopup={ClosePopup} onClickOneButton={onBackToLogin}
                title='niki.b2b.recovery_modal.title' title1='niki.b2b.recovery_modal.positive_message' buttonOne='niki.b2b.recovery_modal.back_to_login' />}
        </div>
    )
}