import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'

import { priceLineID } from "../config"
import { PriceFormat, ShowLoader, HideLoader } from '../functions'

import { Breadcrumbs } from "../breadcrumbs"
import { TablePreloader } from '../components/TablePreloader'
import { getRequestOptions, getOpenPDF } from '../helpers/apiHelper'

import { ReactComponent as Download } from './../assets/svg/productcatalogdownload.svg'

import "./../assets/css/staticpages.css"

const DateFormat = localDate => localDate.split(' ')[0].replaceAll('/','.')

const ProcessContent = props => {

    const dispatch = useDispatch()

    const idDownloadDocument = useSelector(state => state.universalContent.idDownloadDocument)

    const processID = props.process_id
    const [tableData,setTableData] = useState([])
    const [processData,setProcessData] = useState({})
    const [dataLoaded,setDataLoaded] = useState(false)
    const [balanceData,setBalanceData] = useState({
        order_total: 0,
        delivery_total: 0,
        invoice_total: 0,
        returns: 0,
        credit: 0,
    })
    
    useEffect(()=>{
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/transaction-processes/${processID}?entity_account_id=${localStorage.getItem('account.entity.id')}&include=transactionProcessStatus,creator,entity,modifier`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data && setProcessData({
                matchcode: json.data.matchcode,
                startedBy: json.data.creator && json.data.creator.data && json.data.creator.data.UserID === 1 ? t('niki.b2b.system') : json.data.creator.data.Name,
                dateOpened: json.data.created_at,
                //dateOpened: json.data.transactionProcessStatus && json.data.transactionProcessStatus.data && json.data.transactionProcessStatus.data.created_at,
            })
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents?searchFields=account_id%3A%3D&search=account_id%3A${localStorage.getItem('account.entity.id.real')}&realKey=1&process_id=${processID}&realKey=1&include=status,details&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            let orderTotal = 0
            let invoiceTotal = 0
            let returnsTotal = 0
            let creditTotal = 0
            setTableData([])
            json.data && json.data.forEach(e => {
                const gross = parseFloat(e.total_tax_included_amount) || 0
                const tempObj = {
                    processID: e.process_id,
                    processIDReal: e.process_id_real,
                    DocumentType: e.key,
                    DocumentID: e.id,
                    DocumentIDReal: e.id_real,
                    Date: e.created_at,
                    SKUs: (e.details && e.details.data && e.details.data.length) || 0,
                    Gros: gross,
                    document_serial: `${e.key}-${e.serial}-${e.number}`,
                    VAT: e.total_tax_amount,
                    Net: e.total_net_amount,
                    uuid: e.uuid,
                }
                setTableData(prevState => ([...prevState, tempObj]))
                if (e.key === 'NE') orderTotal += gross
                if (e.key === 'SRET') returnsTotal += gross
                if (e.key === 'FAC' || e.key === 'FAC2') invoiceTotal += gross
                if (e.key === 'NC' || e.key === 'NCW' || e.key === 'NCM') creditTotal += gross
            })
            setBalanceData({
                ...balanceData,
                order_total: orderTotal,
                delivery_total: 0,
                invoice_total: invoiceTotal,
                returns: returnsTotal,
                credit: creditTotal,
            })
            setDataLoaded(true)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
        // eslint-disable-next-line
    },[])

    const fromEnter = localStorage.getItem('from_enter_process')

    return (
        <>
            <Breadcrumbs itemlist={[
                {'url':'/account/','title':t('niki.b2b.menu.myaccount')},
                { 'url': `/${fromEnter}/`, 'title': fromEnter === 'orders' ? t('niki.b2b.account.myorders') : t('niki.b2b.menu.myinvoices')},
                { 'url': `/process/${processID}/`, 'title': `${t('niki.b2b.process.h1')} ${processData.matchcode || ''}`}
            ]} />
            <div className="wrapper">
                <h1>{t('niki.b2b.process.h1')} {processData.matchcode}</h1>
                <div className="process_grey">
                    <div>
                        <div className="process_grey__header">{t('niki.b2b.process.information')}</div>
                        <hr />
                        <div className="process_grey__three">
                            <div>
                                <div className="process_grey__three__def">{t('niki.b2b.process.startedby')}:</div>
                                {processData.startedBy
                                    ? <div>{processData.startedBy}</div>
                                    : <div>{t('niki.grid.none')}</div>
                                    // :<div><span className="content_placeholder inline-block width50"></span></div>
                                }
                            </div>
                            <div>
                                <div className="process_grey__three__def">{t('niki.b2b.process.dateopened')}:</div>
                                {processData.dateOpened
                                    ? <div>{processData.dateOpened}</div>
                                    : <div>{t('niki.grid.none')}</div>
                                    // : <div><span className="content_placeholder inline-block width50"></span></div>
                                }
                            </div>
                            <div>
                                <div className="process_grey__three__def">{t('niki.b2b.process.dateclosed')}:</div>
                                <div>{t('niki.grid.none')}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="process_grey__header">{t('niki.b2b.process.balance')}</div>
                        <hr />
                        <div className="cart_aside__table">
                            <div>{t('niki.b2b.process.ordertotal')}:</div>
                            <div>{PriceFormat(balanceData.order_total)}</div>
                        </div>
                        <div className="cart_aside__table">
                            <div>{t('niki.b2b.process.deliverytotal')}:</div>
                            <div>{PriceFormat(balanceData.delivery_total)}</div>
                        </div>
                        <div className="cart_aside__table">
                            <div>{t('niki.b2b.process.invoicetotal')}:</div>
                            <div>{PriceFormat(balanceData.invoice_total)}</div>
                        </div>
                        <div className="cart_aside__table">
                            <div>{t('niki.b2b.process.returns')}:</div>
                            <div>{PriceFormat(balanceData.returns)}</div>
                        </div>
                        <div className="cart_aside__table">
                            <div>{t('niki.b2b.process.credit')}:</div>
                            <div>{PriceFormat(balanceData.credit)}</div>
                        </div>
                    </div>
                </div>
                <h3>{t('niki.b2b.process.documents')}</h3>
                {dataLoaded
                    ? <table className="table">
                        <thead>
                            <tr>
                                <th>{t('niki.b2b.debts.processid')}</th>
                                <th>{t('niki.b2b.process.documenttype')}</th>
                                <th>{t('niki.b2b.document')}</th>
                                <th>{t('niki.b2b.product.date')}</th>
                                <th>{t('niki.b2b.debts.skus')}</th>
                                <th>{t('niki.b2b.product.net')}</th>
                                <th>{t('niki.b2b.product.vat')}</th>
                                <th>{t('niki.b2b.product.gross')}</th>
                                <th>{t('niki.b2b.feedback.print')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((e,i)=>
                                <tr key={`tr${i}`}>
                                    <td>{e.processIDReal}</td>
                                    <td>{e.DocumentType}</td>
                                    <td>{e.document_serial+` (${e.DocumentIDReal})`}</td>
                                    <td>{DateFormat(e.Date)}</td>
                                    <td>{e.SKUs}</td>
                                    <td className="right">{PriceFormat(e.Net)}</td>
                                    <td className="right">{PriceFormat(e.VAT)}</td>
                                    <td className="right">{PriceFormat(e.Gros)}</td>
                                    <td><button disabled={idDownloadDocument} type="button" className='btn_svg_20' onClick={() => getOpenPDF(e.uuid, e.document_serial, dispatch)}>
                                        {idDownloadDocument === e.uuid
                                            ? <div className="spinner_ring spinner__red"><div></div><div></div><div></div><div></div></div>
                                            : <Download />
                                        }
                                    </button></td> 
                                </tr>
                            )}
                        </tbody>
                    </table>
                    : <TablePreloader />
                }
            </div>
        </>
    )
}

export const Process = () => {
    const { id } = useParams()
    return (
        <ProcessContent process_id={id} />
    )
}