import { LangFix } from '../../functions'
import { priceLineID } from '../../config'

export const returnSearchProduct = (product) => {
    const selectedLanguage = LangFix(localStorage.getItem('locale'))

    const itemPrices = product?.itemPrices ? product?.itemPrices[priceLineID] : 0
    const discounts = product.is_discount ? product?.itemDiscounts[priceLineID] : 0 
    const unitPrice = parseFloat(itemPrices.unit_price) || 0
    const taxIncludedPrice = parseFloat(itemPrices.tax_included_price) || 0

    const prodName = product?.itemDescriptions && product?.itemDescriptions[selectedLanguage]?.name ? product.itemDescriptions[selectedLanguage].name : product.matchcode
    const prodPrice = product.is_discount ? parseFloat(discounts.unit_price) : unitPrice
    const prodTax = product.is_discount ? parseFloat(discounts.tax_included_price) : taxIncludedPrice
    const prodQty = parseInt(product?.itemUnitBarcodeByUnitCodeBox?.coefficient) || 0                    

    return { id: product.id, name: prodName, qty: prodQty, price: prodPrice, tax: prodTax }
}