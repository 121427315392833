import { useEffect, useMemo, useRef, useState } from "react"
import { scrollTopProducts } from './../functions'

import { Product } from "./product/Product"
import { ProductLoadingPlaceholder } from './productlistplaceholder'
import { connectInfiniteHits } from 'react-instantsearch-dom'

export const CustomInfiniteHits = connectInfiniteHits(({ hits, hasMore, refineNext, allProducts }) => {    
    const favorites = localStorage.getItem('favorites')
    const histories =  localStorage.getItem('history.ids')   
    const loaderRef = useRef(null)
    const loadRef = useRef(null)
    const search = window.location.search

    const [isFetching, setIsFetching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (!hasMore || isFetching) return
            if (loaderRef.current && loaderRef.current.getBoundingClientRect().top <= window.innerHeight*3) {
                setIsLoading(true)
                setIsFetching(true)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
        // eslint-disable-next-line
    }, [hits, isFetching, hasMore])

    useEffect(() => {
        if (document.querySelector('.product_in_listing')) {
            document.querySelector('.products_on_page').innerText = hits.length
            setIsLoading(false)
        }
    }, [hits.length])

    useEffect(() => {
        const sortFiled = document.querySelectorAll('.ais-SortBy-select');
        let timer;
        clearTimeout(timer);
        if (loadRef?.current && loadRef.current !== sortFiled[0]?.selectedOptions[0]?.value) {
            timer = setTimeout(() => {
                const isScrollTop = document.querySelector('.scroll_button_top_active')
                isScrollTop && scrollTopProducts()
                setLoading(true)
                clearTimeout(timer);
            }, 1300);
        }
        loadRef.current = sortFiled[0]?.selectedOptions[0]?.value
    }, [hits])

    useEffect(() => {
        if (allProducts) {
            setLoading(true)
        }
    }, [allProducts])
    
    useEffect(() => {
        if (isFetching) {
            refineNext()
            setIsFetching(false)
        }
        // eslint-disable-next-line
    }, [isFetching])


    const hitsMemo = useMemo(() => {
        loading && setLoading(false)
        return (
            <div className="products_listing">
                {hits.map(hit => {
                    if (search && (search.includes('favorites') || search.includes('purchased'))) {
                        if (search.includes('favorites')) {
                            return favorites.includes(hit.id) && <Product {...hit}/>
                        } else {
                            return histories.includes(hit.id) && <Product {...hit}/>
                        }
                    } else {
                        return <Product {...hit}/>
                    } 
                })}
            </div>
        )
        // eslint-disable-next-line
    }, [hits.length, loading])
    
    return (
        <div>
            {hitsMemo}
            <div ref={loaderRef}></div>
            {isLoading && <ProductLoadingPlaceholder />}
        </div>
    )
})

