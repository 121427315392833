import { useRef, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from 'react-router-dom'
import axios from "axios"
import { t } from "i18next"

import { setDetailsProductId } from './../reduxToolkit/universalSlice'
import { addCartProduct, updateCartProduct, deleteCartProduct, setIsCloseCartPreviewModal } from "./../reduxToolkit/cartSlice"
import { xCompanyID, meilisearchUrl, xTenant } from "./../config"
import { PriceFormat, ShowLoader, HideLoader, LangFix, storingCartData } from "./../functions"
import { getRequestOptions, updateProductInCart, guruRequestOptions, returnErrorDuringAddProductToCart, paramsForCartDetails, getMeilisearchIndexName } from "../helpers/apiHelper"
import { prepareProductToCart} from "../helpers/functionsRedux"

import { Alert } from "./alert"
import { AlertPopup } from "./AlertPopup"
import { CartContent } from './CartContent'

import { ReactComponent as Trash } from './../assets/svg/quickordertrash.svg'
import { ReactComponent as Cart } from './../assets/svg/quickordercart.svg'
import { ReactComponent as Upload } from './../assets/svg/quickorderupload.svg'
import { ReactComponent as Download } from './../assets/svg/quickorderdownload.svg'
import { ReactComponent as Arrow } from './../assets/svg/quickorderarrow.svg'
import { ReactComponent as ArrowRed } from './../assets/svg/quickorderarrowred.svg'
import { ReactComponent as Magnify } from './../assets/svg/magnify.svg'
import { ReactComponent as TrashGrey } from './../assets/svg/trash.svg'

const FetchProduct = async (codes) => {
    const filterInsert = `(barcode=${codes} OR source_item_id=${codes}) AND company_id=${xCompanyID}`
    //const attributesToRetrieve = `attributesToRetrieve","value":"id,company_id,entity_account_id,tax_group_id,source_id,status_id,attachment_id,source_item_id,source_family_id,source_supplier_id,source_item_type_id,source_barcode_type_id,main_unit_code,sale_unit_code,purchase_unit_code,barcode,matchcode,capacity,capacity_unit_code,width,height,length,netweight,grossweight,properties,order_apply_round,order_round_percent,order_round_unit_code,family,itemStocks,itemPrices.${priceLineID},is_booklet,is_new,itemUnitBarcodeByUnitCodeBox,ratings,attachment,attachments,is_discount,default_warehouse,is_in_stock,itemDescriptions,ingredients,ingredientNutrients,itemRecommendedPrices.${priceLineID},itemDiscounts.${priceLineID},kinds,country,id`
    const response = await fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("items")}/search?filter=${encodeURIComponent(filterInsert)}`, guruRequestOptions)
    const json = await response.json()
    return json
}

const CartDetailData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details?include=item,item.itemDescriptions&cart_header_uuid=${localStorage.getItem('cart.uuid')}`,getRequestOptions())
    const json = await response.json()
    return json
}

const CreateNewCart = async () => {
    const requestOptionsCart = getRequestOptions('POST')
    requestOptionsCart.body = JSON.stringify({
        company_id: localStorage.getItem('account.company.hashed'),
        customer_account_id: localStorage.getItem('account.entity.id'),
        status_id: 'J8RvMwowm7k2pNOr',
        realKey: '1',
    })
    
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers`,requestOptionsCart)
    const json = await response.json()
    return json
}

const returnProductData = (id, item_id, product_id, itemsku, barcode, uni, quantity, qty_in_box, price, stock, available, sale_unit_code) => {
    return {
        id: id,
        item_id: item_id,
        product_id: product_id,
        itemsku: itemsku,
        barcode: barcode,
        uni: uni,
        quantity: quantity,
        qty_in_box: qty_in_box,
        price: price,
        stock: stock,
        available: available,
        sale_unit_code: sale_unit_code,
    }
}

export const QuickOrder = ({setQuickOrder}) => {
    const dispatch = useDispatch()

    const selectedLanguage = LangFix(localStorage.getItem('locale'))
    const quickOrderWrapperEl = useRef(null)
    const searchInputRef = useRef(null)
    const blockRef = useRef(null)

    const cartContent = useSelector(state => state.cartContent)
    const cartProducts = useSelector(state => state.cartContent.cartContent)
    const productQTYs = useSelector(state => state.cartContent.productQTYs)

    const [tableData,setTableData] = useState([])
    const [searchProduct,setSearchProduct] = useState('')
    const [unsavedBarcodes, setUnsavedBarcodes] = useState([])
    const [productId, setProductId] = useState('')
    const [isOpenAlert, setIsOpenAlert] = useState(false)
    const [isLoadingUpload, setIsLoadingUpload] = useState(false)
    const [isCloseQuickOrder, setCloseQuickOrder] = useState(false)
    const [isOpenUploadAlert, setIsOpenUploadAlert] = useState(false)
    const [isOpenProductNotFoundAlert, setIsOpenProductNotFoundAlert] = useState(false)
    const [isOpenDownloadExcelAlert, setIsOpenDownloadExcelAlert] = useState(false)
    const [isCloseAlertPopup, setIsCloseAlert] = useState(false)   
    const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState('niki.b2b.excell_upload_error')
    const [productThatIsCart, setProductThatIsCart] = useState(null)
    const [isScrollDown, setIsScrollDown] = useState(false)

    const HideQuickOrder = (closeModals) => {
        setTableData([])
        setCloseQuickOrder(true)
        quickOrderWrapperEl.current.classList.remove('active')
        typeof closeModals === "boolean" && closeModals && dispatch(setIsCloseCartPreviewModal(true))
    }

    useEffect(() => {
        let timer;
        if (isCloseQuickOrder) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                setQuickOrder(false)
            }, 700)   
        }  
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isCloseQuickOrder])

    useEffect(() => {
        let timer;
        if (isScrollDown && blockRef?.current) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                HideLoader()
                blockRef.current.scrollTop += 85
                setIsScrollDown(false)
            }, 2000)   
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
      }, [isScrollDown]);
    
    useEffect(() => {
        let timer;
        clearTimeout(timer);
        timer = setTimeout(() => {
            searchInputRef.current.focus()
        }, 500);    
      
        return () => clearTimeout(timer);
    }, [])

    useEffect(() => {
        let timer;
        if (isCloseAlertPopup) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                searchInputRef.current.focus()
                setIsCloseAlert(false)
            }, 100);    
        }
        if (!isOpenUploadAlert) {
            setUploadFileErrorMessage('niki.b2b.excell_upload_error')
        }
        
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isOpenUploadAlert, isCloseAlertPopup]);

    const WrapCartSummary = () => document.querySelector('.aside_cart_summary').classList.toggle('wrapped')

    const ApplyFindData = data => {
        let CartDetail = ''
        let qtyInCart = 0
        const uuid = localStorage.getItem('cart.uuid')
        const prodID = data.id
        const prodItemID = data.source_item_id
        const prodName = data.itemDescriptions && data.itemDescriptions[selectedLanguage]?.description ? data.itemDescriptions[selectedLanguage]?.description : data.matchcode
        const available = (data?.itemStocks[0] && data.itemStocks[0].available) || 0
        let stockStatus = 'red'
        if (available > 0) stockStatus = 'orange'
        if (available > 20) stockStatus = 'green'
        const prodQTY = data.itemUnitBarcodeByUnitCodeBox.coefficient
        const itemPrices = data?.itemPrices[localStorage.getItem('priceline.id')]

        // const prodUNI = (data?.itemStocks[0] && data.itemStocks[0].stock_unit_code) || ''
        const prodPrice = (itemPrices && itemPrices.unit_price) || 0
        const prodGross = (itemPrices && itemPrices.tax_included_price) || 0
        
        CartDetailData().then(json => {
            if (json.data) {
                json.data.forEach(e => {
                    if (e.item && e.item.data.id && e.item.data.id === prodID) {
                        CartDetail = e.id
                        qtyInCart = parseInt(e.qty)
                    }
                })
            }
            if (CartDetail.length > 0) {
                const selectProduct = cartProducts.filter(p => p.productitemid === productId || p.itemid === productId)[0]
                const newValue = parseInt(qtyInCart) + parseInt(selectProduct?.qtyinbox ? selectProduct?.qtyinbox : productQTYs[prodID])
                dispatch(updateCartProduct({productid: prodID, cartdetail: CartDetail, qty: newValue}))
                updateProductInCart(newValue,CartDetail)
            } else {
                const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
                requestOptionsCart.body = JSON.stringify({
                    cart_header_uuid: uuid,
                    item_id: prodID,
                    matchcode: prodName,
                    variation_id: '3',
                    qty_in_box: prodQTY.toString(),
                    qty: prodQTY.toString(),
                    unit_price: prodPrice.toString(),
                    tax_included_price: prodGross.toString()
                })
                
                ShowLoader()
                fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details?${paramsForCartDetails}`,requestOptionsCart)
                .then(response => response.json())
                .then(json => {
                    if (json?.message) {
                        returnErrorDuringAddProductToCart(json.message, dispatch)
                    }else if (json.data) {
                        setSearchProduct('')
                        json.data && prepareProductToCart(json.data, dispatch)
                        
                        CartDetail = json.data.id
                        const tempObj = returnProductData(json.data.id, prodItemID, prodID, prodName, json.data.barcode, json.data.UNI, +prodQTY, +json.data.qty_in_box, prodPrice, stockStatus, +available, json.data.sale_unit_code)
                        setTableData(prevState => ([...prevState, tempObj]))
                    }
                })
                .catch(error => returnErrorDuringAddProductToCart(error, dispatch))
                .finally(() => HideLoader())
            }
        })
    }

    const FindProduct = e => {
        e.preventDefault()
        if (!e.target?.childNodes[0]?.value) return
        const productItemId = e.target?.childNodes[0]?.value
        productItemId && setProductId(productItemId)

        if (cartProducts.length > 0 && cartProducts.filter(p => p.productitemid === productItemId || p.itemid === productItemId).length === 1) {
            setProductThatIsCart(cartProducts.filter(p => p.productitemid === productItemId || p.itemid === productItemId)[0])
            setIsOpenAlert(true)           
        } else if (!localStorage.getItem('cart.uuid')) {
            ShowLoader()
            CreateNewCart()
            .then(json => json.data && storingCartData(json.data.uuid, json.data.id_real, json.data.id))
            .catch(error => console.error(error))
            .finally(() => {
                setIsScrollDown(true)
                HideLoader()
            })
        } else {
            ShowLoader()
            FetchProduct(searchProduct)
            .then(json => {
                if (json.hits?.length > 0) {
                    ApplyFindData(json.hits[0])
                    setIsScrollDown(true)
                } else {
                    setIsOpenProductNotFoundAlert(true)
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }

    const RemoveQIitem = (cartDetailID) => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${cartDetailID}`,getRequestOptions('DELETE'))
        .then(response => {
            if (response.ok) {
                let newTableData = []
                tableData.forEach(e => {
                    e.id !== cartDetailID && newTableData.push(e)
                })
                setTableData(newTableData)
                dispatch(deleteCartProduct(cartDetailID))
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const SelectFile = e => {
        setIsLoadingUpload(true)
        const UploadXLS = async () => {
            const data = new FormData()
            data.append('file',e.target.files[0])
            data.append('warehouse_id',localStorage.getItem('warehouse.id'))
            data.append('cart_header_uuid',localStorage.getItem('cart.uuid'))

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'X-Tenant': xTenant,
                    'X-CompanyID': xCompanyID,
                    'Authorization': `Bearer ${localStorage.getItem('access.token')}`,
                },
            }
            const res = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/b2b/upload-products-to-cart-details?${paramsForCartDetails}`,data,config)
            return res
        }

        const ApplyData = res => {
            if (res.data?.meta?.custom?.unsavedBarcodes?.length) {
                let result = `<p>${t(uploadFileErrorMessage)}</p><ul>`
                res.data?.meta?.custom?.unsavedBarcodes.forEach((i, index) => {
                    const end = res.data?.meta?.custom?.unsavedBarcodes?.length === index+1 ? '</ul>' : ''
                    result += `<li>${index + 1} - ${i};</li> ${end}`
                })
                setUploadFileErrorMessage(result)

                !isOpenUploadAlert && setIsOpenUploadAlert(true)
            }

            res.data.data.length === 0 && setIsOpenUploadAlert(true)

            res.data.data && res.data.data.forEach(e => {
                const available = parseInt(e.in_stock)
                let stockStatus = 'red'
                if (available > 0) stockStatus = 'orange'
                if (available > 20) stockStatus = 'green'

                const tempObj = returnProductData(e.id, e.product_item_id, e.product_id, e.matchcode, e.barcode, e.UNI, +e.qty, +e.qty_in_box, e.unit_price, stockStatus, available, e.sale_unit_code)
                e?.unsavedBarcodes && setUnsavedBarcodes(e.unsavedBarcodes)
                
                tableData.length === 0
                    ? setTableData(prevState => ([...prevState, tempObj]))
                    : tableData.filter(i => i.id === e.id).length > 0
                        ? setTableData(prevState => ([...prevState.filter(i => i.id !== e.id), tempObj]))
                        : setTableData(prevState => ([...prevState, tempObj]))
                
                const productData = prepareProductToCart(e, dispatch, true)
                cartProducts?.filter(i => i.id === e.id)?.length > 0 ? dispatch(updateCartProduct(productData)) : dispatch(addCartProduct(productData))
            })
        }
        
        const ifErrorr = (error) => {
            error?.response?.data?.message && setUploadFileErrorMessage(error.response.data.message)
            setIsOpenUploadAlert(true)
            console.error(error)
        }

        const finallyUpload = () => {
            HideLoader()
            setIsLoadingUpload(false)
        }

        if (!localStorage.getItem('cart.uuid')) {
            ShowLoader()
            CreateNewCart()
            .then(json => {
                if (json.data) {
                    storingCartData(json.data.uuid, json.data.id_real, json.data.id)
                    ShowLoader()
                    UploadXLS().then(res => ApplyData(res))
                    .catch(error => ifErrorr(error))
                    .finally(() => finallyUpload())
                }
            })
            .catch(error => console.error(error))
            .finally(() => finallyUpload())
        } else {
            ShowLoader()
            UploadXLS().then(res => ApplyData(res))
            .catch(error => ifErrorr(error))
            .finally(() => finallyUpload())
        }
    }

    const DownloadExample = () => {
        ShowLoader()
        const requestOptionsBlob = getRequestOptions()
        requestOptionsBlob.responseType = 'blob'
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/b2b/download-sample-excel`,requestOptionsBlob)
        .then(response => {
            if (response.status === 200) {
                const href = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = href
                link.setAttribute('download', 'example.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            } else {
                setIsOpenDownloadExcelAlert(true)
            }            
        })
        .catch(error => {
            setIsOpenDownloadExcelAlert(true)
            console.error(error)
        })
        .finally(() => HideLoader())
    }

    const ChangeCartInput = (e, sale_unit_code) => {
        const value = e.target.value
        const regex = /^\d+(\.)?(\d{1,2})?$/

        const localValue = sale_unit_code === 'KG' ? value.slice(0, value.indexOf('.')+3) : parseInt(value) || 0
        if (sale_unit_code === 'KG' && !regex.test(value)) return

        const cartDetail = e.target.name
        const productid = e.target.dataset.productid
        dispatch(updateCartProduct({productid: productid, cartdetail: cartDetail, qty: localValue}))
        updateProductInCart(cartDetail, localValue)
        setTableData(prevState => (prevState.map(i =>  i.product_id === productid ? { ...i, quantity: localValue } : i)))
    }

    const CartInputPlus = e => {
        const cartDetail = e.target.dataset.cartdetail
        const productID = e.target.dataset.productid
        const qtyInBox = cartContent.cartContent?.filter(i => i.productid === productID)[0]?.qtyinbox
        const localValue = productQTYs[productID]
        const resSum = +qtyInBox + Number(localValue)
        const newValue = Number.isInteger(resSum) ? resSum : resSum.toFixed(2)

        dispatch(updateCartProduct({ productid: productID, cartdetail: cartDetail, qty: newValue }))
        updateProductInCart(cartDetail, newValue)
        setTableData(prevState => (prevState.map(i =>  i.product_id === productID ? { ...i, quantity: newValue } : i)))
    }

    const CartInputMinus = e => {
        const cartDetail = e.target.dataset.cartdetail
        const productID = e.target.dataset.productid
        const qtyInBox = cartContent.cartContent?.filter(i => i.productid === productID)[0]?.qtyinbox
        const localValue = Number(productQTYs[productID])
        const difference = localValue - qtyInBox
        const newValue = Number.isInteger(difference) ? difference : difference.toFixed(2)
        dispatch(updateCartProduct({productid: productID, cartdetail: cartDetail, qty: newValue}))
        updateProductInCart(cartDetail, newValue)
        setTableData(prevState => (prevState.map(i =>  i.product_id === productID ? { ...i, quantity: newValue } : i)))
    }

    const onUpdateProductQuantity = () => {
        if (productThatIsCart) {
            const prod = productThatIsCart
            const newValue = +prod.qtyinbox + productQTYs[prod.productid]
            dispatch(updateCartProduct({ productid: prod.productid, cartdetail: prod.id, qty: newValue }))
            updateProductInCart(prod.id, newValue)
        }
        setIsOpenAlert(false)
        setIsCloseAlert(true)
        setSearchProduct('')
        setProductThatIsCart(null)
    }
   
    const last50Product = cartProducts.length > 0 && cartProducts.slice(cartProducts?.length > 50 ? -50 : 0)

    const onClickGoToProduct = (productId) => {
        dispatch(setDetailsProductId(productId))
        // HideQuickOrder()
    }

    return (
        <div className="quick_order__wrapper" ref={quickOrderWrapperEl}>
            <div className="quick_order__content">
                <div className="quick_order__header">{t('niki.b2b.quickinsert.h1')}</div>
                <div className="quick_order__columns">
                    <div>
                        <div className="quick_order__column_first" ref={blockRef}>
                            {unsavedBarcodes.length > 0 && <Alert text={`${t('niki.b2b.not_found')}: ${unsavedBarcodes.join(', ')}`} type="danger" />}
                            <table className="table quick_order__table marginbottom20">
                                <thead>
                                    <tr>
                                        <th>{t('niki.b2b.quickinsert.itemskuean')}</th>
                                        <th>{t('niki.b2b.cart.unit')}</th>
                                        <th>{t('niki.b2b.product.quantity')}</th>
                                        <th>{t('niki.b2b.allproducts.price')}</th>
                                        <th>{t('niki.b2b.feedback.status')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData && tableData.map((e,i)=>
                                        <tr key={`quickorder${i}`}>   
                                            <td className="quick_order__table__first">
                                                <div onClick={() => onClickGoToProduct(e.product_id)} className="strong pointer">{e.itemsku}</div>
                                                {e.item_id} / {e.barcode}
                                            </td>
                                            <td className="nowrap">{e.uni} {e.qty_in_box}</td>
                                            <td>
                                                <div className="quantity_group">
                                                    {e.quantity <= e.qty_in_box
                                                        ? <button
                                                            className="quantity_group__minus"
                                                            onClick={() => RemoveQIitem(e.id)}
                                                        ><TrashGrey /></button>
                                                        : <button
                                                            className="quantity_group__minus"
                                                            onClick={CartInputMinus}
                                                            data-cartdetail={e.id}
                                                            data-productid={e.product_id}
                                                            data-qtyinbox={e.qty_in_box}
                                                        >-</button>
                                                    }
                                                    <input
                                                        type="text"
                                                        name={e.id}
                                                        data-productid={e.product_id}
                                                        value={productQTYs[e.product_id]}
                                                        onChange={(event) => ChangeCartInput(event, e.sale_unit_code)}
                                                        onBlur={(event) => {
                                                            event.target.value === '' && RemoveQIitem(e.id);
                                                            event.target.value === '0' && RemoveQIitem(e.id)
                                                        }}
                                                    />
                                                    <button
                                                        className="quantity_group__plus"
                                                        onClick={CartInputPlus}
                                                        data-cartdetail={e.id}
                                                        data-productid={e.product_id}
                                                        data-qtyinbox={e.qty_in_box}
                                                    >+</button>
                                                </div>
                                            </td>
                                            <td>{PriceFormat(e.price)}</td>
                                            <td><div className={['quick_order__status',e.stock].join(' ')}></div></td>
                                            <td>
                                                <button
                                                    className="quick_order__trash"
                                                    onClick={() => RemoveQIitem(e.id)}
                                                ><Trash /></button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="table quick_order__table marginbottom20">
                                <tbody>
                                    <tr className="quick_order__table__last_row">
                                        <td>
                                            <form className="relative" onSubmit={FindProduct}>
                                                <input
                                                    className="form-control"
                                                    value={searchProduct}
                                                    ref={searchInputRef}
                                                    onChange={e => setSearchProduct(e.target.value)}
                                                    placeholder={`${t('niki.b2b.product.enter')} ${t('niki.b2b.product.sku_or_barcode')}`}
                                                />
                                                <button className="find_button" type="submit"><Magnify /></button>
                                            </form>
                                        </td>
                                        <td>{t('niki.b2b.cart.unit')}</td>
                                        <td>0</td>
                                        <td>&#163;0,00</td>
                                        <td><div className="quick_order__status"></div></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="quick_order__aside">
                        {localStorage.getItem('cart.uuid')
                            ? <div className="aside_cart_summary quick_order__aside__unit__two">
                                <div className="aside_cart_summary__header">{t('niki.b2b.quickinsert.cartsummary')}</div>
                                <div className="aside_cart_summary__above_header">
                                    <div style={{marginRight: 10}}>{t('niki.b2b.cart.shoppingcart')} {cartContent.idreal} {cartContent.name ? cartContent.name : ''}</div>
                                    <div className="header_cart__popup__status__second">{t('niki.b2b.cart.last')} {cartProducts.length < 50 ? cartProducts.length : 50} {t('niki.b2b.header.of')} {cartProducts.length} </div>
                                </div>
                                <button className="aside_cart_summar__arrow" onClick={WrapCartSummary}><ArrowRed /></button>
                                <CartContent cartData={last50Product} ChangeCartInput={ChangeCartInput} />
                                {last50Product > 0 && <div className="quick_cart__summary">
                                    <div className="header_cart__popup__summary__cart_total">{t('niki.b2b.cart.carttotal')} <span>{t('niki.b2b.cart.exclvat')}</span></div>
                                    <div className="header_cart__popup__summary__summ">{PriceFormat(cartContent.summ)}</div>
                                </div>}
                                <NavLink to="/myshoppingcart/" className="btn btn-primary btn-block" onClick={() => HideQuickOrder(true)}>
                                    {t('niki.b2b.cartflow.checkout')}
                                    <Arrow />
                                </NavLink>
                            </div>
                            : <div className="quick_order__aside__unit quick_order__aside__unit__one">
                                <div className="quick_order__aside__unit__header">{t('niki.b2b.quickinsert.products')}</div>
                                <div className="quick_order__aside__unit__text">{t('niki.b2b.quickinsert.additems')}.</div>
                                <button className="btn btn-primary btn-block">{t('niki.b2b.quickinsert.selectcart')}<Cart /></button>
                            </div>
                        }
                        <div className="quick_order__aside__unit quick_order__aside__unit__white quick_order__aside__unit__one">
                            <div className="quick_order__aside__unit__header">{t('niki.b2b.quickinsert.uploadorder')}</div>
                            <div className="quick_order__aside__unit__text">{t('niki.b2b.quickinsert.hereyoucan')}.</div>
                            {isLoadingUpload
                                ? <button className="btn btn-primary btn-block wrapper_loading__button">
                                    <div className="spinner_ring"><div></div><div></div><div></div><div></div></div>
                                </button>
                                : <label className="btn btn-block">
                                    {t('niki.b2b.quickinsert.uploadfile')}<Upload /><input type="file" className="hidden" onChange={SelectFile} />
                                </label>
                            }
                            <span className="quick_order__aside__unit__upload" onClick={DownloadExample}>{t('niki.b2b.quickinsert.downloadsample')} <Download /></span>
                        </div>
                    </div>
                </div>
                <button className="delivery_addresses__popup__close" onClick={HideQuickOrder}></button>
            </div>
            <div className="quick_order__overlay" onClick={HideQuickOrder}></div>
            {isOpenAlert &&
                <AlertPopup
                    text={`${t('niki.b2b.cartflow.item_has_in_cart')}. ${t('niki.b2b.can_ignore_or_add_box')}.`}
                    // text={`${t('niki.b2b.cartflow.isincart')}. ${t('niki.b2b.can_change_quantity_cart')}`}
                    closeAlert={() => { setIsOpenAlert(false); setIsCloseAlert(true) }}
                    titleFirstButton={'niki.b2b.cartflow.addtocart'}
                    towButtons={true}
                    onFirstButtonClick={onUpdateProductQuantity}
                />
            }
            {isOpenUploadAlert && <AlertPopup text={t(uploadFileErrorMessage)} closeAlert={() => {setIsOpenUploadAlert(false); setIsCloseAlert(true) }} />}
            {isOpenProductNotFoundAlert && <AlertPopup text={t('niki.b2b.alert.productnotfound')} closeAlert={() => {setIsOpenProductNotFoundAlert(false); setIsCloseAlert(true) }} />}
            {isOpenDownloadExcelAlert &&
                <AlertPopup
                    text={t('niki.b2b.something_went_wrong') + '. ' + t('niki.b2b.error_uploading_file') + '. ' + t('niki.b2b.quickorder.checkt_entered_data') + '.'}
                    closeAlert={() => { setIsOpenDownloadExcelAlert(false); setIsCloseAlert(true) }}
                />
            }
        </div>
    )
}