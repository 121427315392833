import { t } from "i18next"
import { useEffect, useRef } from "react"
import "../assets/css/cart.css"

export const PopupWithInput = props => {
    const { title, titleSubmitButton, refWrapper, onSubmit, setNewValue, close, value = '' } = props
    const cartRenameInputEl = useRef(null)

    useEffect(() => {
        let timer;
          
        clearTimeout(timer);
        timer = setTimeout(() => {
            cartRenameInputEl.current.focus()
        },500)
              
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [])
    
    return (
        <div className="rename_cart__wrapper" ref={refWrapper}>
            <form className="rename_cart__popup" onSubmit={onSubmit}>
                <div className="rename_cart__popup__title">{t(title)}</div>
                <div>
                    <input
                        className="form-control"
                        name="newcartname"
                        ref={cartRenameInputEl}
                        defaultValue={value}
                        onChange={e => setNewValue(e.target.value)}
                        />
                    </div>
                    <div className="rename_cart__popup__buttons">
                        <button className="btn btn-primary" type="submit">{t(titleSubmitButton)}</button>
                        <button className="btn" type="button" onClick={close}>{t('niki.b2b.cartflow.cancel')}</button>
                    </div>
                    <button className="rename_cart__popup__close" type="button" onClick={close}></button>
                </form>
                <div className="rename_cart__overlay" onClick={close}></div>
            </div>
    )
}