import { useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'

import { getCartsData } from "../../helpers/apiHelper"
import { PriceFormat, addedCartToShoppingCarts, ShowLoader, HideLoader, storingCartData } from '../../functions'
import { setIsShowAllShoppingCartsModal, setCartId } from '../../reduxToolkit/universalSlice'
import { CreateNewCartModal } from "../../components/CreateNewCartModal"

import '../../assets/css/header.css'

import { ReactComponent as AllShoppingCarts } from '../../assets/svg/allshoppingcarts.svg'
import { ReactComponent as PlayGrey } from '../../assets/svg/playgrey.svg'

export const ShowAllShoppingCartsModal = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cartId = useSelector(state => state.universalContent.cartId)

    const allShoppingCartsEl = useRef(null) 

    const cart = useSelector(state => state.cartContent)
    const cartName = useSelector(state => state.cartContent.name)
    
    const [shoppingCartsData, setShoppingCartsData] = useState([])
    const [isCloseModal, setIsCloseModal] = useState(false)
    const [isOpenCreateNewCartModal, setIsOpenCreateNewCartModal] = useState(false) 

    useEffect(() => {
        let timer;

        if (isCloseModal) {
            clearTimeout(timer);
            timer = setTimeout(() => dispatch(setIsShowAllShoppingCartsModal(false)), 600)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isCloseModal])

    useEffect(() => {
        ShowLoader()
        getCartsData('', true)
            .then(json => {
                const shoppingCarts = []
                json.data && json.data.forEach(e => {
                    const tempObj = addedCartToShoppingCarts(e, t)
                    shoppingCarts.push(tempObj)
                })
                shoppingCarts.length && setShoppingCartsData(shoppingCarts)
            })
            .catch(error => console.error(error))
            .finally(() => {
                HideLoader()
                allShoppingCartsEl.current.classList.add('active')
            })
    }, [])

    const closeAllShoppingCarts = () => {
        allShoppingCartsEl.current.classList.remove('active')
        setIsCloseModal(true)
    }

    useEffect(() => {
        setShoppingCartsData(prev => prev.map(i => i.id === cart.idreal ? {...i, cartname: cart.name} : i))
    // eslint-disable-next-line
    },[cartName])

    const openShoppingCart = val => {
        if (cartId !== val.id_hash) {
            storingCartData(val.uuid, val.id, val.id_hash)
            dispatch(setCartId(val.id_hash))
        }
        closeAllShoppingCarts()
        navigate('/myshoppingcart/')
    }

    return (
        <>
            <div className="all_shopping_carts" ref={allShoppingCartsEl}>
                <div className="all_shopping_carts__header">{t('niki.b2b.cart.select')}</div>
                <div className="all_shopping_carts__status">{t('niki.b2b.cart.activatecartbelow')}</div>
                <div className="all_shopping_carts__carts">
                    {shoppingCartsData.length > 0 && shoppingCartsData.map((e,i) =>
                        <button
                            className="all_shopping_carts__cart"
                            key={`shoppingcart${i}`}
                            onClick={() => openShoppingCart(e)}
                        >
                            <span>{e.id} {e.cartname}</span>
                            <span>{e.itemscount} {t('niki.b2b.debts.skus')}</span>
                            <span>{PriceFormat(e.totalnet)}</span>
                            <span>{e.date}</span>
                            <PlayGrey />
                        </button>
                    )}
                </div>
                <div className="all_shopping_carts__footer">
                    <button className="btn btn-primary" onClick={() => setIsOpenCreateNewCartModal(true)}>{t('niki.b2b.cartflow.createnewcart')}</button>
                    <NavLink to="/shoppingcarts/" className={["all_shopping_carts__footer__button", "custom_button_hover"].join(' ')} onClick={closeAllShoppingCarts}>
                    {t('niki.b2b.menu.allshoppingcarts')}
                    <AllShoppingCarts />
                    </NavLink>
                </div>
                <button className="close_popup" onClick={closeAllShoppingCarts}></button>
            </div>
            {isOpenCreateNewCartModal && <CreateNewCartModal close={() => setIsOpenCreateNewCartModal(false)} />}
        </>
    )
}