import { useSelector } from "react-redux"

export const ProductLoadingPlaceholder = () => {

    const productsView = useSelector(state => state.allProductsContent.productsView)

    return (
        <> {productsView === 'grid'
            ? <div className="productlist_placeholder placeholder_grid">
                {Array.from('informazioon').map((e,i)=>
                    <div className="productlist_placeholder__unit" key={`prodplaceholder${i}`}>
                        <div className="productlist_placeholder__unit__image content_placeholder"></div>
                        <div className="productlist_placeholder__unit__title content_placeholder"></div>
                        <div className="productlist_placeholder__unit__columns">{Array.from('monolith').map((ee,ii)=><div key={`monolith${ii}`} className="content_placeholder"></div>)}</div>
                    </div>
                )}
            </div>
            : <div className="productlist_placeholder__horizontal placeholder_list">
                {Array.from('informazioon').map((e,i) =>
                    <div className="productlist_placeholder__horizontal__unit" key={`prodplaceholder${i}`}>
                        <div>
                            <div className="productlist_placeholder__horizontal__unit__image content_placeholder"></div>
                            <div className="productlist_placeholder__horizontal__unit__rating content_placeholder"></div>
                        </div>
                        <div>
                            <div className="productlist_placeholder__horizontal__unit__prodname content_placeholder"></div>
                            <div className="productlist_placeholder__horizontal__unit__data content_placeholder"></div>
                            <div className="productlist_placeholder__horizontal__unit__data content_placeholder"></div>
                            <div className="productlist_placeholder__horizontal__unit__data content_placeholder"></div>
                            <div className="productlist_placeholder__horizontal__unit__data content_placeholder"></div>
                        </div>
                        <div>
                            <div className="productlist_placeholder__horizontal__unit__table">
                                <div className="content_placeholder"></div>
                                <div className="content_placeholder"></div>
                                <div className="content_placeholder"></div>
                                <div className="content_placeholder"></div>
                                <div className="content_placeholder"></div>
                                <div className="content_placeholder"></div>
                                <div className="content_placeholder"></div>
                                <div className="content_placeholder"></div>
                                <div className="content_placeholder"></div>
                            </div>
                            <div className="productlist_placeholder__horizontal__unit__pricecart">
                                <div className="content_placeholder"></div>
                                <div className="content_placeholder"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        }</>
    )
}