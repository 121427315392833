import { useEffect, useState } from "react"
import { t } from "i18next"
import { useSelector } from "react-redux"

import { FixCompanyName, GetSimpleBannerData, ShowLoader, HideLoader } from "../functions"
import { getRequestOptions } from "../helpers/apiHelper"

import { ContactsAside } from "../components/contactsaside"
import LoadableImage from "../components/LoadableImage/LoadableImage"

import "./../assets/css/staticpages.css"
import { companyName } from "../config"

const GetContactsData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/entity-accounts/${localStorage.getItem('account.entity.id')}/company-contacts`, getRequestOptions())
    const json = await response.json()
    return json
}

export const Contact = () => {
    const bannersData = useSelector(state => state.universalContent.bannersData)

    const [contacts, setContacts] = useState({
        address: '',
        email: '',
        telephone: '',
        fax: '',
        locality: '',
        postalCode: '',
    })

    useEffect(() => {
        ShowLoader()
        GetContactsData()
            .then(json => {
                json.data && setContacts({
                    address: json.data.address_1,
                    email: json.data.email,
                    telephone: json.data.telephone,
                    fax: json.data.fax,
                    locality: json.data.locality,
                    postalCode: json.data.postal_code,
                })
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }, [])

    const [pageImage, setPageImage] = useState(null)

    useEffect(() => {
        ShowLoader()
        const data = bannersData || JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            // e.matchcode === 'B2B - Contacts - Image'
            if (e.content_subtype_id === '21P0praYMaJGEYLx' && e.content_type_id === "yPrdNR7m4nbgEjmq" && e?.attachments?.data[0]?.links) {
                e.attachments.data[0].links.forEach(el => {
                    if (el.template === 'original') {
                        setPageImage(GetSimpleBannerData(el))
                    }
                })
            }
        })
        HideLoader()
    }, [bannersData])

    return (
        <div className="wrapper">
            <h1>{t('niki.b2b.contact.h1')} {FixCompanyName(companyName)}</h1>
            <div className="contacts__office_support">
                <div>
                    <div className="contacts__support__header">{t('niki.b2b.contact.officewarehouse')}</div>
                    <div className="contacts__office_warehouse">
                        <div className="contacts__three_columns__table">
                            <div className="strong">{t('niki.b2b.contact.tel')}:</div><a href={`tel:${contacts.telephone}`}>{contacts.telephone}</a>
                            <div className="strong">{t('niki.b2b.contact.fax')}:</div><a href={`tel:${contacts.fax}`}>{contacts.fax}</a>
                            <div className="strong">{t('niki.b2b.contact.email')}:</div><a className="contacts__email" href={`mailto:${contacts.email}`}>{contacts.email}</a>
                        </div>
                        <hr />
                        <div className="contacts__office_warehouse__address_header">{t('niki.b2b.contact.address')}</div>
                        <div className="contacts__office_warehouse__address">{contacts.address}</div>
                        <div className="contacts__office_warehouse__address">{contacts.postalCode}</div>
                        <div className="contacts__office_warehouse__address">{contacts.locality}</div>
                        <a href="https://goo.gl/maps/cRcMEpBExRqsUvmXA" target="_blank" rel="noopener noreferrer nofollow" className="btn btn-block" style={{ marginTop: 20 }}>{t('niki.b2b.contact.addressmap')}</a>
                    </div>
                </div>
                <div className="contacts__warehouse">
                    {pageImage && <LoadableImage src={pageImage.webp ? pageImage.webp : pageImage.simple} alt={t('niki.b2b.contact.monolithwarehouse')} height={'450px'} />}
                </div>
                <div className="contacts__support">
                    <div className="contacts__support__header">{t('niki.b2b.account.supportteam')}</div>
                    <ContactsAside />
                </div>
            </div>
        </div>
    )
}