import { FormatDate } from "../../functions"
import { defaultCompanyBooklet } from "../../config"

export const prepareMarketingData = (data) => {
    
    const marketingDataArray = []

    data.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
    })
    
    data.length > 0 && data.forEach((e, i) => {
        let pdfPath = '' || defaultCompanyBooklet
        let thumbPath = '' || defaultCompanyBooklet
        if (e.attachments && e.attachments.data && e.attachments.data.length > 0) {
            e.attachments.data.forEach(el => {
                if (el.extension === 'pdf') {
                    pdfPath = el.awsFileUrl
                } else {
                    thumbPath = el.awsFileUrl
                }
            })
        }
       
        const tempObj = {
            id: e.id,
            title: e.matchcode,
            pdf: pdfPath || defaultCompanyBooklet,
            thumb: thumbPath || defaultCompanyBooklet,
            productsIdArray: e.related_items?.length ? e.related_items : [],
            from: FormatDate(e.start_at),
            to: FormatDate(e.finish_at),
        }
        marketingDataArray.push(tempObj)
    })

    return marketingDataArray
}