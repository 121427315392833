import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { t } from "i18next"

import { ShowLoader, HideLoader } from '../functions'
import { applyAccount } from './loginComponents/loginFunctions'
import { getRequestOptions } from '../helpers/apiHelper'

import './../assets/css/style.css'
import './../assets/css/loginform.css'


export const ChangeEntityAccount = ({ closeModal }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [entityID, setEntityID] = useState('')
    const [accountName, setAccountName] = useState('')
    const [entityData, setEntityData] = useState([])
    const [initialData, setInitialData] = useState([])
    const [disableButton, setDisableButton] = useState(false)
    
    const inputRef = useRef(null);
    const clearButtonRef = useRef(null);
    const changeAccountRef = useRef(null)

    useEffect(() => {
        changeAccountRef.current.classList.add('active')

        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/user/entity-accounts`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                if (json?.data?.length) {
                    let selectedAccount = ''
                    let Id = 0
                    const accounts = json.data.map(account => {
                        if (account.matchcode === localStorage.getItem('account.entity.name')) {
                            selectedAccount = account.external_id + ' - ' + account.matchcode
                            Id = account.id
                        } 
                        return { ...account, account_name: account.external_id + ' - ' + account.matchcode }
                    })
                    setEntityData(accounts)
                    setInitialData(accounts)                
                    setEntityID(Id ? Id : json.data[0].id)
                    setAccountName(selectedAccount ? selectedAccount : json.data[0].external_id + ' - ' + json.data[0].matchcode)
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }, [])

    const SelectAccountListSelected = () => {
        document.querySelector('.custom_select__wrapper').classList.add('active')
        const currentElement = document.querySelector('li.current');
       
        if (currentElement) {
            currentElement.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            })
        }
    }

    const onChangeInput = (event) => {
        setAccountName(event.target.value)
        if (!event.target.value) {
            setEntityData(initialData)
            return
        } else {
            const accountsFilter = initialData.filter(ac => ac.account_name.toLowerCase().includes(event.target.value.toLowerCase()))
            setEntityData(accountsFilter)
        }
    }

    const SelectAccountList = e => {
        const thisElement = e.target
        document.querySelector('.custom_select__wrapper').classList.remove('active')
        document.querySelector('.custom_select__selected').dataset.id = thisElement.dataset.id
        document.querySelector('.custom_select__selected').dataset.entityname = thisElement.dataset.accountname
        document.querySelector('.custom_select__list li.current') && document.querySelector('.custom_select__list li.current').classList.remove('current')

        setEntityID(thisElement.dataset.id)
        setAccountName(thisElement.dataset.accountname)
        setEntityData(initialData)
        document.querySelector('.custom_select__wrapper').classList.remove('active')
    }

    const onApplyAccount = () => {
        setDisableButton(true)
        localStorage.removeItem('favorites')
        localStorage.removeItem('history.ids')
        localStorage.removeItem('overdue.summ')
        localStorage.removeItem('cart.id')
        localStorage.removeItem('cart.uuid')
        applyAccount(entityID, dispatch)
        navigate('/all-products/')
    }

    const handleClearClick = () => {
        setAccountName('');
        SelectAccountListSelected();
        setEntityData(initialData);
        inputRef.current.focus();
    };

    const hideModal = () => {
        closeModal(false)
        changeAccountRef.current.classList.remove('active')
    }

    return (
        <div className="change_account__wrapper" ref={changeAccountRef}>
            <div className="change_account__content">
                <div className="change_account__select">
                    <div className="change_account__header">{t('niki.b2b.login.selectaccount')}</div>

                    {entityData && (
                        <>
                            <div className="custom_select__wrapper marginbottom20">
                                <input className="custom_select__selected"
                                    type="text"
                                    value={accountName}
                                    placeholder={t('niki.b2b.login.selectaccount')}
                                    onClick={SelectAccountListSelected}
                                    onChange={(e) => onChangeInput(e)}
                                    data-id={entityID}                                  
                                    data-accountname={accountName}
                                    ref={inputRef}
                                />
                                 <span
                                    ref={clearButtonRef}
                                    className="clear-button"
                                    onClick={handleClearClick}
                                    style={{ display: accountName ? 'inline-block' : 'none' }}
                                >
                                    ×
                                </span>
                                <ul className="custom_select__list">
                                    {entityData.map((e,i)=><li
                                        key={`accountlist${i}`}
                                        onClick={SelectAccountList}
                                        data-id={e.id}
                                        data-accountname={e.account_name}
                                        className={e.account_name === accountName ? 'current' : null}
                                    >{e.account_name}</li>)}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
                <div className="change_account__footer">
                        <button className={["header_cart__popup__footer__quick_order", "custom_button_hover"].join(' ')} onClick={() => {onApplyAccount()}} disabled={disableButton || `${entityID}` === localStorage.getItem('account.entity.id.real')}>
                            {t('niki.b2b.change_account')}
                        </button>
                        <button className={["header_cart__popup__footer__all_shopping_carts", "custom_button_hover"].join(' ')} onClick={hideModal} disabled={disableButton}>
                            {t('niki.b2b.cartflow.cancel')}
                        </button>
                    </div>
            </div>
            <div className="change_account__overlay" onClick={hideModal}></div>
        </div>
    )
}