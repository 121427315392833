import { useCallback, useEffect, useRef, useState } from "react"
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { InstantSearch, SearchBox, Configure, SortBy, Stats } from 'react-instantsearch-dom'
import { CurrentRefinements, ClearRefinements, connectRefinementList } from 'react-instantsearch-dom'
import { t } from "i18next"

import { LangFix, translationsForSearchBox, ShowLoader, HideLoader, scrollTopProducts } from "../functions"
import { xCompanyID, searchClient, priceLineID, pricelineIDRecommended, bookletsTypeID } from "../config"
import { getRequestOptions, getMeilisearchIndexName, startFacetFilters } from "../helpers/apiHelper"
import { setIsClickOnAllProductsInHeader, setIsRefreshMarketingCampaigns, setIsReadyFavorites } from '../reduxToolkit/universalSlice'
import { setIsClickOnBrand, setIsClickFavorites, setProductsView, setIsOnBannerFromDetails, setIsFavoritesFromAccountPopup } from '../reduxToolkit/allproduct'

import { Breadcrumbs } from "../breadcrumbs"
import { CustomInfiniteHits } from '../components/CustomInfiniteHits'
import { Banner200 } from '../banner200'

import { ReactComponent as HeartDarkGrey } from '../assets/svg/heartdarkgrey.svg'
import { ReactComponent as BookDarkGrey } from '../assets/svg/bookdarkgrey.svg'
import { ReactComponent as TagDarkGrey } from '../assets/svg/tagdarkgrey.svg'
import { ReactComponent as Percent } from '../assets/svg/percent.svg'
import { ReactComponent as RefreshDarkGrey } from '../assets/svg/refreshdarkgrey.svg'
import { ReactComponent as RaquoWhite } from '../assets/svg/raquowhite.svg'
import { ReactComponent as GridView } from '../assets/svg/gridview.svg'
import { ReactComponent as ListView } from '../assets/svg/listview.svg'

import '../assets/css/filter.css'

// const filterNames = ['Brands', 'Categories', 'Countries', 'Families', 'Kinds', 'Company', 'ItemPrices', 'itemStocks']
const filterNames = [ 'Families','Kinds', 'Categories', 'Brands', 'Countries']
const filterTranslations = {
    'Brands': t('niki.b2b.menu.brands'),
    'Categories': t('niki.b2b.allproducts.categories'),
    'Countries': t('niki.b2b.allproducts.countries'),
    'Families': t('niki.b2b.allproducts.families'),
    'Kinds': t('niki.b2b.allproducts.kinds')
}
const returnBrandValueForCompany = () =>  xCompanyID === '4' ? 'brand' : 'trademark' 
const filterRefinement = {
    'Brands': `${returnBrandValueForCompany()}.name`,
    'Categories': `categories.${LangFix(localStorage.getItem('locale'))}`,
    'Countries': `country.name_translated.${LangFix(localStorage.getItem('locale'))}`,
    'Families': 'family',
    'Kinds': `kinds.${LangFix(localStorage.getItem('locale'))}`,
    'Company': 'company_id',
    'ItemPrices': 'itemPrices',
    'itemStocks': 'itemStocks',
}
let defaultFilterRefinement = {}

const returnDefaultShowFilters = () => {
    return {
        'allitems': '',
        'favorites': `id IN [${localStorage.getItem('favorites')}]`,
        'booklet': localStorage.getItem('marketingCampaignIds')
            ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.booklet}]`
            : `is_booklet=true`,
        'new': localStorage.getItem('marketingCampaignIds')
            ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.new}]`
            : `is_new=true`,
        'discount': localStorage.getItem('marketingCampaignIds')
            ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.promo}]`
            : `is_discount=true`,
        'purchased': `id IN [${localStorage.getItem('history.ids')}]`
    }
}

!localStorage.getItem('product.view') && localStorage.setItem('product.view','grid')
localStorage.setItem('allproducts.show','')
localStorage.setItem('allproducts.search','')
localStorage.setItem('allproducts.brands', '')

const ButtonAction = ({ title, show, content, ClickTopFilterButton, showFilters, currentShow }) => {
    return (
        <button
            data-title={t(title)}
            className={`top_filter_button ${currentShow === show ? 'active' : ''}`}
            onClick={ClickTopFilterButton}
            data-filter={showFilters[show]}
            data-show={show}
        >{content}</button>
    )
}

/* banner */
const now = new Date()
const todayIs = `${String(now.getDate()).padStart(2, '0')}.${String(now.getMonth() + 1).padStart(2, '0')}.${now.getFullYear()}`
let showBanner = true
if (localStorage.getItem('banner200.closed.when')) {
    if (localStorage.getItem('banner200.closed.when') === todayIs) {
        showBanner = false
        localStorage.setItem('banner200.closed','true')
    } else {
        localStorage.setItem('banner200.closed','false')
        showBanner = true
    }
}
!showBanner && document.body.classList.add('banner200closed')
/* banner */

const AlphabeticalRefinementList = connectRefinementList(({ items, refine, refinementList, attribute }) => {
    const sortedItems = items.sort((a, b) =>
        a.label.localeCompare(b.label)
    )
    const filteredItems = sortedItems.filter((e) => refinementList && !!refinementList[attribute] ? e.label.toLowerCase().includes(refinementList[attribute]) : e.label)
    
    return (
        <div className="ais-RefinementList">
            <ul className="ais-RefinementList-list">
            {filteredItems.map(item => (
                <li key={item.value} className="ais-RefinementList-item">
                    <label className="ais-RefinementList-label">
                        <input
                            type="checkbox"
                            className="ais-RefinementList-checkbox"
                            value={item.value}
                            checked={item.isRefined}
                            onChange={event => {
                            refine(item.value)
                            }}
                        />
                        <span className="ais-RefinementList-labelText">{item.label}</span>
                        <span className="ais-RefinementList-count">{item.count}</span>
                    </label>
                </li>
            ))}
            </ul>
        </div>
  )
})

const UpdateBrowserURL = () => {
    const currentURL = new URL(window.location.href)

    const searchValue = localStorage.getItem('allproducts.search')
    if (searchValue && searchValue.length > 0) {
        currentURL.searchParams.set('search', searchValue)
    } else {
        currentURL.searchParams.delete('search')
    }
    
    const showValue = localStorage.getItem('allproducts.show')
    if (showValue === 'allitems' || showValue === '') {
        currentURL.searchParams.delete('show')
    } else {
        currentURL.searchParams.set('show',showValue)
    }
    
    const brandsValue = localStorage.getItem('allproducts.brands')
    if (brandsValue === '') {
        currentURL.searchParams.delete('brand')
    } else {
        currentURL.searchParams.set('brand',brandsValue)
    }

    window.history.replaceState(null, '', currentURL)
    localStorage.setItem('url.back',`/all-products/${currentURL.search}`)
}

export const AllProducts = () => {

    // check config company
    useEffect(() => {
        if (xCompanyID === '4' && !filterNames.includes('GroupResponsible')) {
            filterNames.push('GroupResponsible')
            filterTranslations.GroupResponsible = t('niki.b2b.group_responsible')
            filterRefinement.GroupResponsible = 'group_responsible'
        }
    }, [])

    const defaultShowFilters = returnDefaultShowFilters()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const booklet = searchParams.get("booklet") || null
    const banner = searchParams.get("banner") || null
    const brand = searchParams.get("brand") || null
    const IDs = searchParams.get("ids") || null
    const search = searchParams.get("search") || null
    const show = searchParams.get("show") || null
    const queryURL = searchParams.get("?search=") || ''

    const defaultSearchQuery = search ? search : queryURL.length > 8 ? queryURL : ''
    const isUpdatePageFromHeader = useSelector(state => state.universalContent.isClickOnAllProductsInHeader)
    const isRefreshMarketingCampaigns = useSelector(state => state.universalContent.isRefreshMarketingCampaigns)
    const isReadyFavorites = useSelector(state => state.universalContent.isReadyFavorites)
    const isClickOnBrand = useSelector(state => state.allProductsContent.isClickOnBrand)
    const isClickFavorites = useSelector(state => state.allProductsContent.isClickFavorites)
    const productsView = useSelector(state => state.allProductsContent.productsView)
    const isOnBannerFromDetails = useSelector(state => state.allProductsContent.isOnBannerFromDetails)
    const brandName = useSelector(state => state.allProductsContent.brandName)
    const isFavoritesClickAccountPopup = useSelector(state => state.allProductsContent.isFavoritesFromAccountPopup)

    const [showFilters, setShowFilters] = useState(defaultShowFilters)
    const currentShowValue = show ? show : 'allitems'
    const defaultFacet = showFilters[currentShowValue]

    const leftFilterEl = useRef(null)
    const showHideFilterArrowEl = useRef(null)
    const mainWrapperEl = useRef(null)
    const buttonRefAllProducts = useRef(null)

    const [currentShow, setCurrentShow] = useState(currentShowValue)
    
    const [selectedFilters, setSelectedFilters] = useState(0)
    const [allProducts, setAllProducts] = useState(0)
    const [showHideInnerText, setShowHideInnerText] = useState(t('niki.b2b.allproducts.showfilters'))

    const [topFilters, setTopFilters] = useState('')
    const [inStockFilters, setInStockFilters] = useState('')
    const [purchasedFilters, setPurchasedFilters] = useState('')
    const [isResetProductsPerPage, setResetProductsPerPage] = useState(false)
    const [isSearchProduct, setSearchProduct] = useState(false)
    
    const [facetFilters, setFacetFilters] = useState([...startFacetFilters, defaultFacet])
    const [filters, setFilters] = useState('')
    const [isScrollTopProducts, setIsScrollTopProducts] = useState(false)
    const [refinementList, setRefinementList] = useState(null)

    const clearLocalStorageDependency = () => {
        localStorage.setItem('booklet.data', JSON.stringify({}))
        localStorage.setItem('banner.data', JSON.stringify({}))
    }

    useEffect(() => {
        dispatch(setProductsView(localStorage.getItem('product.view'))) 

        if (localStorage.getItem('history.ids')) {
            setShowFilters(prev => ({ ...prev, purchased: `id IN [${localStorage.getItem('history.ids')}]` }))
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (filterNames && !refinementList) {
            const refin = {}
            filterNames.forEach(el => refin[filterRefinement[el]] = '')
            setRefinementList(refin)
        }
        // eslint-disable-next-line
    }, [filterNames])
    
    useEffect(() => {
        let timer;
        clearTimeout(timer);
        if (isScrollTopProducts) {
            timer = setTimeout(() => {
                scrollTopProducts()
                setIsScrollTopProducts(false)
            }, 500);
        }
        return () => clearTimeout(timer);
    }, [isScrollTopProducts])

    useEffect(() => {
        if (isRefreshMarketingCampaigns) {
            setShowFilters(returnDefaultShowFilters())
            dispatch(setIsRefreshMarketingCampaigns(false))
        }
        if (localStorage.getItem('history.ids') && showFilters?.purchased?.includes('null')) {
            setShowFilters(prev => ({ ...prev, purchased: `id IN [${localStorage.getItem('history.ids')}]` }))
            isRefreshMarketingCampaigns && dispatch(setIsRefreshMarketingCampaigns(false))
        }
        // eslint-disable-next-line
    }, [isRefreshMarketingCampaigns])

    useEffect(() => {
        if (isOnBannerFromDetails) {   
            scrollTopProducts()
            clearBrandsFromFilterRefinement()
            setFilters('')
            clearLocalStorageDependency()
            setResetProductsPerPage(prev => !prev)
            dispatch(setIsOnBannerFromDetails(false))
        }
        // eslint-disable-next-line
    }, [isOnBannerFromDetails])
    
    useEffect(() => {
        if (isClickFavorites || isReadyFavorites) {
            const refer = localStorage.getItem('favorites')
            if (isReadyFavorites) {
                showFilters.favorites.slice(5).split(',').length !== refer.split(',').length && setShowFilters(prev => ({ ...prev, favorites: `id IN [${refer}]` }))
                dispatch(setIsReadyFavorites(false))
            }
            if (isClickFavorites) {
                setShowFilters(prev => ({ ...prev, favorites: `id IN [${refer}]` }))
                dispatch(setIsClickFavorites(false))
            }
        }
        // eslint-disable-next-line
    }, [isClickFavorites, isReadyFavorites])
    
    const clearSearchParameters = () => {
        setFilters('')
        setResetProductsPerPage(prev => !prev)
    }

    useEffect(() => {
        if (booklet) {
            localStorage.setItem('allproducts.brands', '')
            const bookletData = JSON.parse(localStorage.getItem('booklet.data'))
            if (Object.keys(bookletData).length > 0) {
                bookletData?.productsIdArray?.length && setFilters(`id IN [${bookletData.productsIdArray}]`)
            } else if (Object.keys(bookletData).length === 0) {
                ShowLoader()
                fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/marketing-campaigns?search=typeableType.id:${bookletsTypeID}&get_related_items_id=1`, getRequestOptions())
                    .then(response => response.json())
                    .then(json => {
                        if (json.data) {
                            const data = json.data
                            data.length > 0 && data.forEach((e) => {
                                if (e.id === booklet && e.related_items?.length) {
                                    setFilters(`id IN ${e.related_items}]`)
                                }
                            })
                        }
                    })
                    .catch(error => console.error(error))
                    .finally(() => HideLoader())
            }
            setIsScrollTopProducts(true)
        } else if (banner) {  
            clearSearchParameters()
            localStorage.setItem('allproducts.brands', '')
            const bannerData = JSON.parse(localStorage.getItem('banner.data'))
            clearBrandsFromFilterRefinement()
            if (bannerData && Object.keys(bannerData).length > 0 && bannerData?.productsIdArray?.length) {
                if (Number(bannerData?.productsIdArray[0]) > 0) {
                    setFilters(`real_id IN [${bannerData.productsIdArray}]`)
                } else {
                    setFilters(`id IN [${bannerData.productsIdArray}]`)
                }
            } else if (!bannerData || Object.keys(bannerData).length === 0) {
                const data = JSON.parse(localStorage.getItem('banners_data')) || []
                if (data.length > 0) {
                    ShowLoader()
                    const searchBanner = data.filter(el => el.json && el.json.includes(banner))
                    const arrayBannersFormJson = searchBanner.length === 1 && JSON.parse(searchBanner[0].json).images
                    const bannerData = arrayBannersFormJson.filter(el => el[banner])
                    bannerData.length === 1 && setFilters(`id IN [${bannerData[0][banner].slice(4).split(',')}]`)
                    HideLoader()
                }
            }
        } else if (brand) {
            const currentUrl = window.location
            if (isClickOnBrand) {
                clearSearchParameters()
                defaultFilterRefinement['Brands'] = brandName
                dispatch(setIsClickOnBrand(false))
                setIsScrollTopProducts(true)
                localStorage.setItem('allproducts.brands', encodeURI(brandName))
            } else {
                clearSearchParameters()
                const wichBrand = currentUrl?.search?.slice(currentUrl?.search?.indexOf('=') + 1)
                defaultFilterRefinement = {}
                if (!Number(wichBrand) > 0) {
                    defaultFilterRefinement['Brands'] = `${brandName ? brandName : localStorage.getItem('brand_name')}`
                }
                localStorage.setItem('allproducts.brands', encodeURI(brandName))
                localStorage.setItem('brand_name', brandName)
            }
        }
        // eslint-disable-next-line
    }, [booklet, banner, brand])
    
    // logic for parsing IDs from banners or an array of product IDs in url format "all-products/?ids=LwPz878BrwyoAOlY,..."
    useEffect(() => {
        if (IDs && IDs?.length > 16) { setFilters(`id IN [${IDs}]`) }
    }, [IDs])

    useEffect(() => {
        if (allProducts) {
            const selectedRefinement = document.querySelectorAll('.grid_filter__selected .ais-CurrentRefinements-category') || []
            setSelectedFilters(selectedRefinement.length)
        }
    }, [allProducts])

    const resetSearchProducts = () => {
        setSelectedFilters(0)
        setSearchProduct(prev => !prev)
        setResetProductsPerPage(prev => !prev) 
    }

    const ClickTopFilterButton = e => {
        resetSearchProducts()
        const thisValue = e.target.dataset.filter
        setCurrentShow(e.target.dataset.show)
        localStorage.setItem('allproducts.show', e.target.dataset.show)
        setTopFilters(thisValue)
        setFacetFilters([...startFacetFilters, thisValue, inStockFilters, purchasedFilters])
        UpdateBrowserURL()
    }

    useEffect(() => {
        if (isFavoritesClickAccountPopup) {
            ClickTopFilterButton({
                target: {
                    dataset: {
                        filter: `id IN [${localStorage.getItem('favorites')}]`,
                        show: "favorites",
                        title: "Favorites"
                    }
                }
            })
            dispatch(setIsFavoritesFromAccountPopup(false))
            scrollTopProducts()
        }
        // eslint-disable-next-line
    }, [isFavoritesClickAccountPopup])

    const ChangeInStock = e => {
        setIsScrollTopProducts(true)
        resetSearchProducts()
        const thisValue = e.target.checked ? 'is_in_stock=true' : ''
        setInStockFilters(thisValue)
        setFacetFilters([...startFacetFilters, thisValue, topFilters, purchasedFilters])
    }

    const ChangePurchased = e => {
        setIsScrollTopProducts(true)
        resetSearchProducts()
        const thisValue = e.target.checked ? `id IN [${localStorage.getItem('history.ids')}]` : ''
        setPurchasedFilters(thisValue)
        setFacetFilters([...startFacetFilters, thisValue, topFilters, inStockFilters])
    }

    const clearBrandsFromFilterRefinement = () => {
        if (defaultFilterRefinement.hasOwnProperty('Brands')) {
            Object.keys(defaultFilterRefinement).forEach(key => delete defaultFilterRefinement[key]);
        }
    }
    const onClearCurrentSelecion = () => {
        clearBrandsFromFilterRefinement()
        navigate('/all-products/')
        setFilters('')
        clearLocalStorageDependency()
        setResetProductsPerPage(prev => !prev)
    }

    useEffect(() => {
        mainWrapperEl.current.className = `wrapper ${productsView}`
        document.querySelector(`.products_listing`).className = `products_listing ${productsView}`
        const brandGrid = document.querySelectorAll('.wrapper_product_brand_grid')
        if (productsView === 'grid') {
            if (brandGrid?.length) brandGrid.forEach(el => el.style.display = 'flex');
        } else {
            if (brandGrid?.length) brandGrid.forEach(el => el.style.display = 'none');
        }
    }, [productsView])

    const ShowHideLeftFilter = () => {
        if (leftFilterEl.current.classList.contains('active')) {
            leftFilterEl.current.classList.remove('active')
            setShowHideInnerText(t('niki.b2b.allproducts.showfilters'))
            showHideFilterArrowEl.current.classList.remove('turned')
        } else {
            leftFilterEl.current.classList.add('active')
            setShowHideInnerText(t('niki.b2b.allproducts.hidefilters'))
            showHideFilterArrowEl.current.classList.add('turned')
        }
    }
    
    const FilterUnitSearch = e => {
        const thisValue = e.target.value.trim().toLowerCase()
        const filter_unit = e.target.parentNode.parentNode

        setRefinementList(prev => ({ ...prev, [e.target.dataset.refinement]: thisValue }))
        if (thisValue.length > 0) {
            filter_unit.querySelectorAll('.ais-RefinementList-list li').forEach(el => el.style.display = 'none')
            filter_unit.querySelectorAll('.ais-RefinementList-list li').forEach(el => {
                const innerLiText = el.querySelector('.ais-RefinementList-labelText').innerText.trim()
                if (innerLiText.toLowerCase().includes(thisValue)) el.style.display = 'block'
            })
        } else {
            filter_unit.querySelectorAll('.ais-RefinementList-list li').forEach(el => el.style.display = 'block')
        }
    }

    const ExpandCollapseFilterUnit = e => {
        const FilterUnit = e.target.parentNode
        e.target.classList.toggle('turned')
        FilterUnit.classList.toggle('collapsed')
    }

    const gridListSwitcher = value => {
        dispatch(setProductsView(value)) 
        localStorage.setItem('product.view', value)
    }

    const ResetResults = useCallback((e) => {
        const refinements = e.refinementList
        for (let key in refinements) {
            if (key === `${returnBrandValueForCompany()}.name`) {
                if (refinements[key].length === 1) {
                    localStorage.setItem('allproducts.brands', refinements[key][0])
                } else {
                    localStorage.setItem('allproducts.brands', '')
                }
            }
        }
        UpdateBrowserURL()
    }, [])

    const searchInputChange = e => {
        if (!e.target.value && defaultSearchQuery && searchParams?.has('search')) {
            searchParams.delete('search')
            localStorage.setItem('allproducts.search', '')
        }
        localStorage.setItem('allproducts.search', e.target.value)
        UpdateBrowserURL()
    }

    useEffect(() => {
        if (isUpdatePageFromHeader) {
            dispatch(setIsClickOnAllProductsInHeader(false))
            setInStockFilters('')
            setPurchasedFilters('')
            clearLocalStorageDependency()
            localStorage.setItem('allproducts.show', '')
            localStorage.setItem('allproducts.search', '')
            localStorage.setItem('allproducts.brands', '')
            defaultFilterRefinement['Brands'] = ''
            setSelectedFilters(0)
            // setBrand('')
            buttonRefAllProducts.current.click()
            onClearCurrentSelecion()
        }
        // eslint-disable-next-line
    }, [isUpdatePageFromHeader])

    return (
        <>
            <Banner200 />
            <InstantSearch
                indexName={getMeilisearchIndexName("items")}
                searchClient={searchClient}
                onSearchStateChange={ResetResults}
                searchParameters={filters}
                key={isResetProductsPerPage}
            >
                <div className="breadcrumbs_search wrapper">
                    <Breadcrumbs itemlist={[{ 'url': '/all-products/', 'title': t('niki.b2b.menu.allproducts') }]} noclasswrapper={true} />
                    <div>
                        {isSearchProduct
                            ? <SearchBox
                                onChange={searchInputChange}
                                defaultRefinement={defaultSearchQuery}
                                translations={translationsForSearchBox()}
                                ignoreEmptyQuery={true}
                                autoFocus={true}
                            />
                            : <div class="ais-SearchBox">
                                <form novalidate="" class="ais-SearchBox-form" action="" role="search">
                                    <input type="search" placeholder={t('niki.b2b.header.search')} autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" required="" maxlength="512" class="ais-SearchBox-input" value="" onClick={() => setSearchProduct(true)} />
                                    <button type="submit" title={t('niki.b2b.header.search')} class="ais-SearchBox-submit">
                                        <svg class="ais-SearchBox-submitIcon" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 40 40" aria-hidden="true">
                                            <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path>
                                        </svg>
                                    </button>
                                    <button type="reset" title="Clear the search query." class="ais-SearchBox-reset" hidden="">
                                        <svg class="ais-SearchBox-resetIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="10" height="10" aria-hidden="true"><path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path>
                                        </svg>
                                    </button>
                                </form>
                            </div>
                        }
                    </div>
                </div>
                {(booklet || banner || IDs?.length > 16) && <div className="top_filter_buttons wrapper">
                    <button
                        className="btn text_transform_capitalize"
                        onClick={onClearCurrentSelecion}
                    >{t('niki.common.clearButtonText')} {t('niki.b2b.promotions.current')}</button>
                </div>}
                <div className="top_filter_buttons wrapper">
                    <button className={`top_filter_button ${currentShow === 'allitems' ? 'active' : ''}`} data-filter="" onClick={ClickTopFilterButton} data-show="allitems" ref={buttonRefAllProducts}>{t('niki.b2b.allproducts.allitems')}</button>
                    <ButtonAction title={'niki.b2b.menu.favorites'} show={"favorites"} content={<HeartDarkGrey />} ClickTopFilterButton={ClickTopFilterButton} showFilters={showFilters} currentShow={currentShow} />
                    <ButtonAction title={'niki.b2b.allproducts.marketingcampaigns'} show={"booklet"} content={<BookDarkGrey />} ClickTopFilterButton={ClickTopFilterButton} showFilters={showFilters} currentShow={currentShow}/>
                    <ButtonAction title={'niki.b2b.allproducts.newitems'} show={"new"} content={<TagDarkGrey />} ClickTopFilterButton={ClickTopFilterButton} showFilters={showFilters} currentShow={currentShow}/>
                    <ButtonAction title={'niki.b2b.allproducts.discounts'} show={"discount"} content={<Percent />} ClickTopFilterButton={ClickTopFilterButton} showFilters={showFilters} currentShow={currentShow}/>
                    <ButtonAction title={'niki.b2b.allproducts.alreadypurchased'} show={"purchased"} content={<RefreshDarkGrey />} ClickTopFilterButton={ClickTopFilterButton} showFilters={showFilters} currentShow={currentShow}/>
                </div>

                <div className="sticky_filter wrapper">
                    <div className="grid_filter__selected" onClick={clearBrandsFromFilterRefinement}>
                        <ClearRefinements translations={{ reset: t('niki.b2b.allproducts.clear_all_filters') }} />
                        <CurrentRefinements />
                    </div>
                    <div className="grid_filter__status">
                        <div style={{display:'flex', gap: '3px'}}>
                            <strong>1</strong>
                            {t('niki.b2b.to')}
                            <strong className="products_on_page">{0}</strong>
                            {t('niki.b2b.header.of')}
                            <strong><Stats translations={{ stats(nbHits) { setAllProducts(nbHits); return nbHits } }} /></strong>
                            {t('niki.b2b.cart.items')}
                        </div>
                        <div className="center">
                            <label className="grid_filter__status__label stock_input"><input type="checkbox" onChange={ChangeInStock} checked={!!inStockFilters} />{t('niki.b2b.allproducts.instock')}</label>
                            <label className="grid_filter__status__label purchased_input"><input type="checkbox" onChange={ChangePurchased} checked={!!purchasedFilters} />{t('niki.b2b.allproducts.alreadypurchased')}</label>
                        </div>
                        <div>
                            {t('niki.b2b.product.sortby')}:
                            <SortBy
                                defaultRefinement={`${getMeilisearchIndexName("items")}:source_item_id:asc`}
                                items={[
                                    {
                                        value: `${getMeilisearchIndexName("items")}:source_item_id:asc`,
                                        label: t('niki.b2b.allproducts.ref_product')
                                    },
                                    {
                                        value: `${getMeilisearchIndexName("items")}:matchcode:asc`,
                                        label: t('niki.b2b.allproducts.family') + " ↗"
                                    },
                                    {
                                        value: `${getMeilisearchIndexName("items")}:matchcode:desc`,
                                        label: t('niki.b2b.allproducts.family') + " ↘"
                                    },
                                    {
                                        value: `${getMeilisearchIndexName("items")}:itemPrices.${priceLineID}.unit_price:asc`,
                                        label: t('niki.b2b.allproducts.price') + " ↗"
                                    },
                                    {
                                        value: `${getMeilisearchIndexName("items")}:itemPrices.${priceLineID}.unit_price:desc`,
                                        label: t('niki.b2b.allproducts.price') + " ↘"
                                    }
                                ]}
                            />
                        </div>
                        <div>
                            <button className={`grid_filter__status__view ${productsView !== 'grid' && 'active'}`} onClick={() => gridListSwitcher("list")}><ListView /></button>
                            <button className={`grid_filter__status__view ${productsView !== 'list' && 'active'}`} onClick={() => gridListSwitcher("grid")}><GridView /></button>
                        </div>
                    </div>
                </div>
    
                <div className="wrapper" ref={mainWrapperEl}>
                    <Configure
                        facetFilters={facetFilters}
                        hitsPerPage={12}
                        filters={filters}
                        attributesToHighlight={[]}
                        attributesToRetrieve={['id', 'ItemExtrafieldsAnswers', 'sale_unit_code_translations', 'company_id', 'status_id', 'attachment_id', 'source_item_id', 'source_item_type_id', 'sale_unit_code', 'barcode', 'matchcode', 'capacity', 'capacity_unit_code', 'width', 'height', 'length', 'itemStocks', `itemPrices.${priceLineID}`, `itemDiscounts.${priceLineID}`, 'is_booklet', 'is_new', 'itemUnitBarcodeByUnitCodeBox', 'ratings', 'attachment', 'is_favorite', 'attachments', 'itemDescriptions', `${xCompanyID === '4' ? 'brand' : 'trademark' }`, 'country', 'group_responsible', `itemRecommendedPrices.${pricelineIDRecommended}`]}
                    />
                    <CustomInfiniteHits allProducts={allProducts} />
                        
                    <div className="left_filter" ref={leftFilterEl}>
                        <div className="left_filter__inner">
                            <div className="grid_filter__left" onClick={clearBrandsFromFilterRefinement}>
                                <CurrentRefinements />
                                <ClearRefinements translations={{ reset: t('niki.b2b.allproducts.clear_all_filters') }} />
                            </div>
                            {filterNames.map((e, i) =>
                                <div className="filter_unit" key={`filterunit${i}`}>
                                    <div className="filter_unit__header">{filterTranslations[e]}</div>
                                    <button className="filter_unit__expand_collapse" onClick={ExpandCollapseFilterUnit}></button>
                                    <div className="filter_unit__search_wrapper">
                                        <input
                                            type="search"
                                            className="filter_unit__search"
                                            placeholder={t('niki.b2b.header.search')}
                                            onChange={FilterUnitSearch}
                                            data-refinement={filterRefinement[e]}
                                        />
                                    </div>
                                    <AlphabeticalRefinementList
                                        attribute={filterRefinement[e]}
                                        limit={1000}
                                        refinementList={refinementList}
                                        defaultRefinement={defaultFilterRefinement[e] ? [defaultFilterRefinement[e]] : null}
                                    />
                                </div>
                            )}
                        </div>
                        <button className="show_hide__left_filter" onClick={ShowHideLeftFilter}>
                            <span className="show_hide__left_filter__count">{selectedFilters}</span>
                            <span className="show_hide__left_filter__title">{showHideInnerText}</span>
                            <RaquoWhite ref={showHideFilterArrowEl} />
                        </button>
                    </div>
                </div>
                
            </InstantSearch>
        </>
    )
}